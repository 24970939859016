import { primary } from '@next-components/common-styles';
import { LoadingSpinner } from '@next-components/loading-spinner';
import {
  IBosApiVehicleMarginCalculationActuals,
  IBosApiVehicleMarginCalculationSales,
  IOverviewInfoDetails,
} from 'next-common';
import React, { useEffect, useState } from 'react';
import { IActualsDataParams, IBudgetDataParams, useActualsData, useBudgetData } from '../../../../../common/hooks';
import BuyTable from './BuyTable';
import { DetailsTableStyled } from './DetailsTableStyled.styled';
import SalesTable from './SalesTable';

export interface IDetailsTableProps {
  companyId: string;
  dealerId: string;
  departmentNo?: number;
  filterValuesEndDate: string;
  filterValuesStartDate: string;
  infoDetail: IOverviewInfoDetails;
  orderNumber: string;
  serialNumber: string;
}

export const DetailsTable = (props: IDetailsTableProps) => {
  const [budgetDetails, setBudgetDetails] = useState<IBosApiVehicleMarginCalculationSales>();
  const [actualsDetails, setActualsDetails] = useState<IBosApiVehicleMarginCalculationActuals[]>();
  const overviewInfoDetails: IOverviewInfoDetails = props.infoDetail;
  const budgetParams: IBudgetDataParams = {
    serialNo: props.serialNumber,
    orderNo: props.orderNumber,
    dealerId: props.dealerId,
  };
  const { data: dataBudget, loading: loadingBudgetDetails } = useBudgetData(budgetParams);

  useEffect(() => {
    if (dataBudget?.getVehicleMarginCalculationSales) {
      setBudgetDetails(dataBudget.getVehicleMarginCalculationSales); // set budget section data
    }
  }, [dataBudget]);

  const actualsParams: IActualsDataParams = {
    serialNo: props?.serialNumber,
    fromVoucherDate: props?.filterValuesStartDate,
    toVoucherDate: props?.filterValuesEndDate,
    departmentNumber: props?.departmentNo?.toString(),
    companyId: props?.companyId,
  };
  const { data: dataActuals, loading: loadingDataActuals } = useActualsData(actualsParams);

  useEffect(() => {
    if (dataActuals?.getVehicleMarginCalculationActuals) {
      setActualsDetails(dataActuals.getVehicleMarginCalculationActuals); // set actuals section data
    }
  }, [dataActuals]);

  return (
    <DetailsTableStyled>
      <div className="detail-table-bg">
        <div className="container">
          <div className="row-container">
            {loadingBudgetDetails && loadingDataActuals ? (
              <div className={'loading centered'}>
                <LoadingSpinner size={48} color={primary} />
              </div>
            ) : (
              <div className="full-column accordion-table">
                <SalesTable budgetDetails={budgetDetails} actualsDetails={actualsDetails} />
                <BuyTable
                  budgetDetails={budgetDetails}
                  actualsDetails={actualsDetails}
                  overviewInfoDetails={overviewInfoDetails}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </DetailsTableStyled>
  );
};
export default DetailsTable;
