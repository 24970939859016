import { useEffect, useState } from 'react';
import { eventEmitter, Events } from '../../utils';

export const useUnselectSkus = () => {
  const [unselectedSku, setUnselectedSku] = useState(new Map());

  const eventCall = (skus) => {
    setUnselectedSku(skus);
  };

  const getUnselectSkuFlag = (equipmentSku: string): boolean => {
    return unselectedSku?.has(equipmentSku);
  };

  useEffect(() => {
    eventEmitter.subscribe(Events.UNSELECT_SKU, eventCall);
    return () => {
      eventEmitter.unsubscribe(Events.UNSELECT_SKU);
    };
  }, []);

  return { unselectedSku, getUnselectSkuFlag };
};
