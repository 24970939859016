import React from 'react';
import font from '../../../../assets/fonts/muli/Muli-Regular.ttf';
import fontLight from '../../../../assets/fonts/muli/Muli-Light.ttf';
import fontExtraLight from '../../../../assets/fonts/muli/Muli-ExtraLight.ttf';
import fontSemiBold from '../../../../assets/fonts/muli/Muli-SemiBold.ttf';
import fontBold from '../../../../assets/fonts/muli/Muli-Bold.ttf';
import fontExtraBold from '../../../../assets/fonts/muli/Muli-ExtraBold.ttf';
import { Font, Document, Page, View } from '@react-pdf/renderer';
import { SourceObject } from '@react-pdf/types';
import {
  Header,
  Footer,
  CarInfo,
  Accessories,
  FinanceOptions,
  EquipmentList,
  TradeInVehicles,
  PdfMessagebar,
} from './parts';
import {
  IOrder,
  IBosApiDealerInfoData,
  IVehicle,
  IVehicleConfiguration,
  FinancingType,
  IPackageEquipmentItems,
  MAKE,
  isStockSaleOrderToBeProcessed,
  getCalculationByType,
  isElectricCar,
  MBApiCalculationFlag,
  INextArticle,
  IVatCalculation,
} from 'next-common';
import { IAccessoryConfig, IOrderOffers } from './SalesOfferPdf';
import { PriceChangeInfo } from './parts/PriceChangeInfo';
import { ICartProps } from '@next-components/cart';
import { isOldOfferExpire } from '../../../../utils/sales/order';
import smartFont from '../../../../assets/fonts/smart/SmartNext-Regular.otf';
import smartBold from '../../../../assets/fonts/smart/SmartNext-Bold.otf';
import smartSansFont from '../../../../assets/fonts/smart/SmartSans-Regular.otf';
import smartSansFontBold from '../../../../assets/fonts/smart/SmartSans-Bold.otf';
import { ICommonSalesOfferPdfProps } from '../../sales-order-view/OrderPane';
import { ICentralisedProperties } from '../../../../common';
import { findSelectedServiceContract, isValidStockSaleOrder } from '../../../../utils';
import { defaultTo } from 'lodash';

Font.register({
  family: 'Muli',
  format: 'truetype',
  fonts: [
    { src: font, fontWeight: 'normal' },
    { src: fontLight, fontWeight: 'light' },
    { src: fontExtraLight, fontWeight: 'extralight' },
    { src: fontSemiBold, fontWeight: 'semibold' },
    { src: fontBold, fontWeight: 'bold' },
    { src: fontExtraBold, fontWeight: 'extrabold' },
  ],
});

Font.register({
  family: 'Smart',
  format: 'truetype',
  fonts: [
    { src: smartFont, fontWeight: 'normal' },
    { src: smartBold, fontWeight: 'bold' },
    { src: smartSansFont, fontWeight: 'normal' },
    { src: smartSansFontBold, fontWeight: 'bold' },
  ],
});

export interface IOfferDocumentProps extends ICommonSalesOfferPdfProps, ICentralisedProperties {
  order: IOrder;
  vehicle: IVehicle;
  vehicleImage: SourceObject;
  vehicleConfig: IVehicleConfiguration;
  dealer: IBosApiDealerInfoData;
  accessories: IAccessoryConfig[];
  isSerialNo: boolean;
  disablePdfLogo: boolean;
  orderOffers: IOrderOffers;
  cartData?: ICartProps;
  standredPackageEquipmentList?: IPackageEquipmentItems[];
  modelPackagesEquipmentList?: IPackageEquipmentItems[];
  infoAgent: string;
  articles?: INextArticle[];
  carType?: string;
}

export interface IServiceContract {
  name: string;
  price: number;
  mva: number;
}

const getTotalPrice = (vatCalculation: IVatCalculation, serviceContract: IServiceContract) => {
  if (!serviceContract) return defaultTo(vatCalculation?.salesTotalIncludingVatAndTax, 0);
  return defaultTo(vatCalculation?.salesTotalIncludingVatAndTax, 0) + defaultTo(serviceContract?.price, 0);
};

export const OfferDocument = (props: IOfferDocumentProps) => {
  const isOfferExpire = isOldOfferExpire(
    props?.order?.status,
    props?.order?.vatCalculation?.salesTotalExcludingVatAndTax,
  );
  const calculation = getCalculationByType(props?.order, FinancingType.CASH);
  const serviceContract = calculation?.serviceContract?.isLoanPaymentIntervalSelected
    ? findSelectedServiceContract(calculation?.serviceContract)
    : null;
  const totalPrice = getTotalPrice(props?.order?.vatCalculation, serviceContract);
  const totalMVA =
    defaultTo(props?.order?.vatCalculation?.calculatedVatOnDeductableItems, 0) +
    defaultTo(props?.order?.vatCalculation?.calculatedVatOnNonDeductableItems, 0) +
    defaultTo(serviceContract?.mva, 0);

  const isElectric = isElectricCar(props?.order?.pimSnapshot, props?.vehicleConfig);
  const isStockSaleOrder = isValidStockSaleOrder(props?.order, props?.configuration)

  const showCarInfo = () => {
    const propsObject = {
      vehicleImage: props?.vehicleImage,
      vehicle: props?.vehicle,
      config: props?.vehicleConfig,
      totalPrice,
      discount: props?.order?.discount,
      accessories: props?.accessories,
      isSerialNo: props?.isSerialNo,
      order: props?.order,
      cartData: props?.cartData,
      standredPackageEquipmentList: props?.standredPackageEquipmentList ?? [],
      modelPackagesEquipmentList: props?.modelPackagesEquipmentList ?? [],
      isOfferExpire,
      vatPrices: props?.vatPrices,
      serviceContract,
      totalMVA,
      isElectric,
      articles: props?.articles,
      carType: props?.carType,
      isStockSaleOrder
    };
    if (isStockSaleOrder || props?.vehicleConfig) {
      return <CarInfo {...propsObject} />;
    } else {
      return null;
    }
  };
  const financeOptionsProps = {
    ...props?.orderOffers,
    isOfferExpire,
    totalMVA,
    totalPrice,
    isElectric,
    ...props?.order,
  };
  return (
    <Document>
      <Page style={{ fontFamily: props?.order?.lead?.make === MAKE.Smart ? 'Smart' : 'Muli', paddingBottom: 40 }}>
        <Header order={props?.order} disablePdfLogo={props?.disablePdfLogo} />
        <View>
          {showCarInfo()}
          {isOfferExpire ? <Accessories accessories={props?.accessories} order={props?.order} /> : null}
          <EquipmentList
            config={props?.vehicleConfig}
            accessories={props?.accessories}
            standredPackageEquipmentList={props?.standredPackageEquipmentList ?? []}
            modelPackagesEquipmentList={props?.modelPackagesEquipmentList ?? []}
            isStockSaleOrder={isStockSaleOrder}
          />
          <TradeInVehicles tradeIns={props?.order?.tradeInVehicles} />
          <FinanceOptions
            {...{
              ...financeOptionsProps,
              make: props?.order?.lead?.make,
              pageWrap: props?.standredPackageEquipmentList?.length > 0 || props?.order?.tradeInVehicles?.length > 0,
              pageBreak: props?.order?.tradeInVehicles?.length === 0,              
              salesTotalIncludingVatAndTax: props?.order?.vatCalculation?.salesTotalIncludingVatAndTax || 0,
            }}
          />
        </View>
        {props?.order?.externalTaxCalculation === MBApiCalculationFlag?.FETCH_FAILED && (
          <View
            break={
              props?.standredPackageEquipmentList?.length === 0 && props?.order?.tradeInVehicles?.length === 0
                ? true
                : false
            }>
            {props?.order?.externalTaxCalculation === MBApiCalculationFlag?.FETCH_FAILED && (
              <PdfMessagebar text="Engangsavgiften i dette tilbudet er kun et estimat. En ny kalkulering må gjøres før kontrakt kan signeres.Vi tar derfor forbehold om at totalprisen kan endres når engangsavgiften kalkuleres på nytt" />
            )}
          </View>
        )}
        <PriceChangeInfo
          pageBreak={
            (props?.vehicleConfig?.model?.make === 'KIA' &&
            props?.orderOffers?.loan?.length === 0 &&
            props?.orderOffers?.leasing?.length === 0 &&
              (props?.standredPackageEquipmentList?.length === 0  || props?.standredPackageEquipmentList?.length <= 27 ) &&
              (props?.order?.tradeInVehicles?.length === 0 || props?.order?.tradeInVehicles === null)) ||
              props?.standredPackageEquipmentList?.length <= 20 
          }
        />
        <Footer
          dealer={props?.dealer}
          user={props?.order?.user}
          createdAt={props?.order?.createdAt}
          expiresAt={props?.order?.expiresAt}
          prerequisiteComment={props?.order?.prerequisite ?? ''}
          make={props?.order?.lead?.make}
          infoAgent={props?.infoAgent}
        />
      </Page>
    </Document>
  );
};
