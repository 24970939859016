import React from 'react';
import { IVehicleStockResult, useVehicleStock, IVehicleStockParams } from '../../../../common/hooks';
import { getGroupName, ttCodeFormatter } from '../../../../utils';
import { ColLoading } from './ColLoading';

export const TrackAndTrace = (props: IVehicleStockParams) => {
  const group = getGroupName(props?.make, props?.series);
  const { data, loading }: IVehicleStockResult = useVehicleStock({
    group,
    serialNo: props?.serialNo,
    fetchPolicy: props?.fetchPolicy,
  });
  if (loading) return <ColLoading />;
  const traceCode = data?.[0]?.trackTraceCode || null;
  return <label>{ttCodeFormatter(traceCode)}</label>;
};
