import React, { useEffect, useState } from 'react';
import { FinancingType } from 'next-common';
import { FinancingForm } from './FinancingForm';
import { ServiceAgreementForm } from './ServiceAgreementForm';
import { LeasingLoanAndServiceFormContainer, LeasingLoanMainContainer, RightFormContainer, ServiceFormContainer } from './FinancingDialog.styled';
import { IconButton } from '@next-components/icon-button';
import { IconType } from '@next-components/bos-icon';
import { bosColor } from '@next-components/common-styles';
import { SmallLink } from '@next-components/link';
import { CustomModal } from '@next-components/custom-modal';
import { AgreementsComparisonList } from './AgreementsComparisonList';
import { ModalWrapper } from '../../../common-components/ModalWrapper';
import { useDisableScroll } from '../../../../common';
import { formatNumber } from '@next-components/common-types';

export const FinancingTabSection = (props) => {
  const [showServiceContract, setShowServiceContract] = useState(!!props.serviceContractDb);
  const [active, setActive] = useState<boolean>(false);

  useDisableScroll(active);

  useEffect(() => {
    setShowServiceContract(!!props?.serviceContractDb)
  }, [props?.serviceContractDb])

  return (
    <LeasingLoanMainContainer>
      {props.type !== FinancingType.CASH || props.isSigningStep ? (
        <LeasingLoanAndServiceFormContainer className="loan-form-container">
          <FinancingForm
            loading={props?.loading}
            setAddNew={props?.setAddNew}
            offerId={props?.offerId}
            dialogTitle={props?.dialogTitle}
            onUpdateCalculation={props.onUpdateCalculation}
            calculation={props.calculation}
            vehicleConfig={props.vehicleConfig}
            type={props.financingType || props.type}
            customerType={props.customerType}
            isSaving={props.isSaving}
            isEditable={props.isEditable}
            isModal={false}
            hideComments={props.isCash}
            onAddComment={props.handleAddComment}
            order={props.order}
            addNew={props?.addNew}
            onAddCalculation={props?.onAddCalculation}
            isDeleted={props.isDeleted}
            configuration={props?.configuration}
            onDeleteCalculation={props?.onDeleteCalculation}
            isStockSaleOrder={props.isStockSaleOrder}
          />
        </LeasingLoanAndServiceFormContainer>
      ) : null}

      {props.type === FinancingType.CASH && (
        <ServiceFormContainer className="cashType">
          <div className="title">Kontant</div>
          <div className="financeListItemContainer">
            <div className="financeListItem ">
              <span>Totalpris </span>
              <span className="sigingPrice">
                {typeof props.calculation?.financing?.totalCost === 'number'
                  ? `${formatNumber(props.calculation?.financing?.totalCost)} kr`
                  : 'ukjent'}
              </span>
            </div>
          </div>
        </ServiceFormContainer>
      )}

      {!showServiceContract && !props?.serviceContractDb?.type ? (
        <RightFormContainer className={FinancingType[props.type]?.toLowerCase()}>
          <div
            onClick={() => !(props.calculation ? false : props.addNew) && setShowServiceContract(!showServiceContract)}
            className="additional-button">
            <IconButton
              className={'addItem'}
              ariaLabel={'plus-knapp'}
              icon={IconType.NewNextPlus}
              fill={bosColor.turquoise}
              disabled={props.isUnavailablePimData ? true : props.calculation ? false : props?.addNew}
              onClick={() => setShowServiceContract(!showServiceContract)}
            />
            <span className={`buttonLabel ${!props?.calculation || props.isUnavailablePimData ? 'disabled' : ''}`}>
              Legg til serviceavtale
            </span>
          </div>
          <div className="serviceavtalerModalLink">
            <SmallLink
              onLinkClick={() => setActive(!active)}
              text={'Sammenlign serviceavtaler'}
              color={bosColor.turquoise}
            />
          </div>
        </RightFormContainer>
      ) : (
        <ServiceFormContainer>
          {!props.serviceContract ? (
            <p>Servicekontrakter er ikke tilgjengelig for denne modellen</p>
          ) : (
            <ServiceAgreementForm
              setShowServiceContractComparisionModal={setActive}
              setShowServiceContract={setShowServiceContract}
              offerId={props?.offerId ?? null}
              onUpdateServiceContract={props.onUpdateServiceContract}
              calculation={props.calculation}
              serviceContractDb={props.serviceContractDb}
              serviceContract={props.serviceContract}
              vehicleConfig={props.vehicleConfig}
              type={props.financingType}
              customerType={props.customerType}
              isSaving={props.isSaving}
              isEditable={props.isEditable}
              isModal={false}
              hideComments={props.isCash}
              order={props.order}
              isUnavailablePimData={props.isUnavailablePimData}
            />
          )}
        </ServiceFormContainer>
      )}
      <div className="serviceContractComparisionModal">
        <ModalWrapper isActive={active}>
          <CustomModal active={active} onCloseClick={() => setActive(!active)} isModelCloseAllowedOutside={false}>
            <header>
              <h2>Serviceavtaler</h2>
            </header>
            <AgreementsComparisonList serviceContract={props?.serviceContract} />
          </CustomModal>
        </ModalWrapper>
      </div>
    </LeasingLoanMainContainer>
  );
};
