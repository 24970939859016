import React from 'react';
import { Navigate } from 'react-router-dom';
import { IVehiclePageProps, orderPlaceholder, PATHS, useOrder } from '../common';
import { isOrder } from '../components/sales/sales-order-view/OrderPane';
import { getAppendingNumericIdFromUrl, isOrderEditable, isOrderInvoiced } from '../utils';
import SalesWizardMaster from './sales/sales-wizard/SalesWizardMaster';
import { IConfiguration } from 'next-common';
import { IVehicleUpdate } from './sales/sales-wizard';

interface IProtectedRouteProps {
  configuration?: IConfiguration;
  manageSocket?(group?: string, vehicleUpdate?: (update: IVehicleUpdate) => void): void;
}

const ProtectedRoute = (props: IProtectedRouteProps) => {
  const orderId = getAppendingNumericIdFromUrl('');
  const orderProps: IVehiclePageProps = {
    orderId: orderId,
  };
  const order = useOrder(orderProps);
  if ((isOrder(order?.status) && !isOrderEditable(order, props?.configuration)) || (isOrderEditable(order, props?.configuration) && isOrderInvoiced(order))) {
    const redirectPath = PATHS.view.replace(orderPlaceholder, orderId);
    return <Navigate to={redirectPath} />;
  } else {
    return <SalesWizardMaster configuration={props.configuration} manageSocket={props.manageSocket} />;
  }
};

export default ProtectedRoute;
