import { get } from 'lodash';
import { IVarnish } from 'next-common';
import React, { useEffect, useState } from 'react';
import { useDisableSkus, useEnableSkus, useSkusPriceUpdate, useUnselectSkus } from '../../../../../common';
import { calculateRetailPrice, createSkuListFromValue, eventEmitter, Events, priceFormatter, VehicleConfig } from '../../../../../utils';
import { IModelPackages } from '../../VehiclePickerPage';
import { ICartDataProps } from '../ResultGrid';
import { SwatchGrid } from '../ResultGrid.styled';
import { Card } from '@next-components/card';
import { CardType } from '@next-components/common-types';
import { VehicleSelectableDescription } from './VehicleSelectableDescription';
import { MessagebarSeverityLevel } from '@next-components/messagebar';

export interface IVehicleColor {
  data: IModelPackages;
  selectedColorId: string;
  disabled: boolean;
  onColorChange: (data: ICartDataProps) => void;
  updatePrices: () => void;
}

export const VehicleColor = (props: IVehicleColor) => {
  const { data, selectedColorId, onColorChange, disabled } = props;
  const { isDisableSKUonChange, isDisableSKUonLoad, disableSku, onloadDisableSku } = useDisableSkus();
  const { isEnableSKUonChange } = useEnableSkus();
  const { getUnselectSkuFlag } = useUnselectSkus();
  const priceUpdate = useSkusPriceUpdate();
  const [isPrepared, setIsPrepared] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [activeDesc, setActiveDesc] = useState<IVarnish>(null);

  useEffect(() => {
    eventEmitter.subscribe(Events.SKU_RULES_PREPARED, setIsPrepared);
    return () => {
      eventEmitter.unsubscribe(Events.SKU_RULES_PREPARED);
    };
  }, []);

  const getPrice = (color: IVarnish) => {
    return priceUpdate?.has(color?.varnishCode)
      ? Number(priceUpdate?.get(color?.varnishCode))
      : color?.additionalPriceExcludingVat;
  };

  useEffect(() => {
    //Update prices in cart and Vat calculation
    if (selectedColorId) {
      props.updatePrices();
    }
  }, [selectedColorId, priceUpdate]);

  const onChangeColor = (color: IVarnish) =>
    onColorChange({
      code: selectedColorId === color?.varnishCode ? null : color?.varnishCode,
      name: color?.name,
      price: getPrice(color),
      vatCode: color?.vatCode,
      isRecommended: color?.isRecommended,
      isOptimisticUpdate: false,
      configType: VehicleConfig.COLOR,
      dealerPriceExclVat: calculateRetailPrice(getPrice(color), color?.marginPercentage),
    });

  const isDisabled = (varnishCode: string) => {
    const isDisable = isDisableSKUonChange(varnishCode)
      ? true
      : isEnableSKUonChange(varnishCode)
        ? false
        : isDisableSKUonLoad(varnishCode);
    return isDisable;
  };

  const isDisableRuleApply = (color: IVarnish) => {
    const isDisable = isDisabled(color?.varnishCode);
    const isUnselectedSku = getUnselectSkuFlag(color?.varnishCode);

    if ((isDisable || isUnselectedSku) && isPrepared && selectedColorId === color?.varnishCode) {
      onColorChange({
        code: null,
        name: color?.name,
        price: getPrice(color),
        vatCode: color?.vatCode,
        isRecommended: color?.isRecommended,
        isOptimisticUpdate: true,
        configType: VehicleConfig.COLOR,
        dealerPriceExclVat: calculateRetailPrice(getPrice(color), color?.marginPercentage),
      });
    }
    return isDisable;
  };

  const getSkuNote = (sku: string) => {
    return createSkuListFromValue(disableSku, onloadDisableSku, sku);
  }

  useEffect(() => {
    const isDisable = isDisabled(selectedColorId);
    if (!isDisable && isPrepared && selectedColorId) {
      const color = data?.colors?.filter((color) => selectedColorId === color?.varnishCode)?.[0];
      if (color) {
        onColorChange({
          code: selectedColorId,
          name: color?.name,
          price: getPrice(color),
          vatCode: color?.vatCode,
          isRecommended: color?.isRecommended,
          isOptimisticUpdate: true,
          configType: VehicleConfig.COLOR,
          dealerPriceExclVat: calculateRetailPrice(getPrice(color), color?.marginPercentage),
        });
      }
    }
  }, [selectedColorId, isDisabled(selectedColorId), data?.colors]);

  const isDataAvailable = (color: IVarnish) => {
    return get(color, 'description');
  };

  const setCurrentData = (color: IVarnish) => {
    setActiveDesc(color);
    setActive(!active);
  };

  return (
    <>
      <span className="vehicleColor label">Farge</span>
      {data?.colors?.length <= 0 || !data ? (
        <p> Ingen farge tilgjengelig </p>
      ) : (
        <SwatchGrid className="vehicleColor">
          {data?.colors?.map((color) => {
            const isSelected = selectedColorId === color?.varnishCode;
            const customPriceTag = color?.isRecommended
              ? 'Inkludert'
              : `${getPrice(color) >= 0 ? '+' : ''} ${priceFormatter(getPrice(color), color)}`;

            return (
              <Card
                key={color?.id}
                id={color?.id}
                title={color?.name}
                bosSwatch={color?.image && color?.image?.[0]}
                customPriceTag={customPriceTag}
                type={CardType.BosCard}
                selected={isSelected}
                isDisabled={disabled || isDisableRuleApply(color)}
                displayTag
                disableTag={!isDataAvailable(color)}
                alertMessage={{
                  message: isDisabled(color?.varnishCode) ? getSkuNote(color?.varnishCode) : '',
                  typeOfAlert: MessagebarSeverityLevel.WARNING
                }}
                tagValue={color?.varnishCode}
                onTagClick={() => setCurrentData(color)}
                updateSelected={() => onChangeColor(color)}
              />
            );
          })}
          <VehicleSelectableDescription
            data={activeDesc}
            active={active}
            onClose={() => {
              setActive(!active);
            }}
          />
        </SwatchGrid>
      )}
    </>
  );
};
