import { ICartPrices } from '@next-components/cart';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { ValidationErrorCode, getErrorMessageForError } from '../../components/notifications';
import { calculateVat } from '../../utils';
import { usePrevious } from './usePrevious';
import { INextArticle } from 'next-common';
import { isEmpty } from 'lodash';
export interface IVatCalculationProps {
  articles: INextArticle[];
  carType?: string;
}

export const useVatStateCalculationHandler = (props: IVatCalculationProps) => {
  const [finalPrice, setFinalPrice] = useState<ICartPrices>(null);
  const prevArticles = usePrevious(props.articles);

  useEffect(() => {
    if (props.articles?.length && !isEmpty(props?.carType)) {
      if (!isEqual(prevArticles, props.articles)) {
        const vatPrices = calculateVat(props.articles, props?.carType);

        if (!vatPrices) {
          console.warn(getErrorMessageForError(ValidationErrorCode.VAT_VALIDATION));
        } else {
          setFinalPrice(vatPrices);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.articles]);

  return { finalPrice };
};
