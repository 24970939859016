import { calculateRetailPrice, createSkuListFromValue, eventEmitter, Events, prepareImageObject, priceFormatter, VehicleConfig } from '../../../../../utils';
import React, { useEffect, useMemo, useState } from 'react';
import { IModelPackages } from '../../VehiclePickerPage';
import { ICartDataProps } from '../ResultGrid';
import { SwatchGrid } from '../ResultGrid.styled';
import { IInterior } from 'next-common';
import { useDisableSkus, useEnableSkus, useSkusPriceUpdate, useUnselectSkus } from '../../../../../common';
import { VehicleSelectableDescription } from './VehicleSelectableDescription';
import { get } from 'lodash';
import { CardType } from '@next-components/common-types';
import { Card } from '@next-components/card';
import { MessagebarSeverityLevel } from '@next-components/messagebar';
export interface IVehicleInterior {
  data: IModelPackages;
  selectedInteriorId: string;
  disabled: boolean;
  onInteriorChange: (data: ICartDataProps) => void;
  updatePrices: () => void;
}

export const VehicleInterior = (props: IVehicleInterior) => {
  const { data, selectedInteriorId, onInteriorChange, disabled } = props;
  const priceUpdate = useSkusPriceUpdate();
  const { disableSku, onloadDisableSku } = useDisableSkus();
  const { enableSku } = useEnableSkus();
  const { unselectedSku } = useUnselectSkus();
  const [isPrepared, setIsPrepared] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [activeDesc, setActiveDesc] = useState<IInterior>(null);

  useEffect(() => {
    eventEmitter.subscribe(Events.SKU_RULES_PREPARED, setIsPrepared);
    return () => {
      eventEmitter.unsubscribe(Events.SKU_RULES_PREPARED);
    };
  }, []);

  useEffect(() => {
    //Update prices in cart & VAT Calculation
    if (selectedInteriorId) {
      props.updatePrices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInteriorId, priceUpdate]);

  return (
    <>
      {data?.interiors?.length <= 0 ? (
        <p> Ingen interiør tilgjengelig </p>
      ) : (
        <SwatchGrid className="vehicleInterior">
          {data?.interiors?.map((interior, index) => {
            return (
              <InteriorItem
                key={`${interior?.interiorCode}-${index}`}
                interior={interior}
                selectedInteriorId={selectedInteriorId}
                onInteriorChange={onInteriorChange}
                disabled={disabled}
                isPrepared={isPrepared}
                unselectedSku={unselectedSku}
                priceUpdate={priceUpdate}
                disableSku={disableSku}
                enableSku={enableSku}
                onloadDisableSku={onloadDisableSku}
                active={active}
                setActive={setActive}
                setActiveDesc={setActiveDesc}
              />
            );
          })}
          <VehicleSelectableDescription
            data={activeDesc}
            active={active}
            onClose={() => {
              setActive(!active);
            }}></VehicleSelectableDescription>
        </SwatchGrid>
      )}
    </>
  );
};

interface IIteriorItem {
  interior: IInterior;
  selectedInteriorId: string;
  onInteriorChange: (data: ICartDataProps) => void;
  disabled: boolean;
  isPrepared: boolean;
  unselectedSku: Map<string, number>;
  priceUpdate: Map<string, number>;
  disableSku: Map<string, number>;
  enableSku: Map<string, number>;
  onloadDisableSku: Map<string, number>;
  active: boolean;
  setActive: (data: boolean) => void;
  setActiveDesc: (data: IInterior) => void;
}

const InteriorItem = ({
  interior,
  selectedInteriorId,
  disabled,
  onInteriorChange,
  isPrepared,
  unselectedSku,
  priceUpdate,
  disableSku,
  enableSku,
  onloadDisableSku,
  active,
  setActive,
  setActiveDesc,
}: IIteriorItem) => {
  const isSelected = selectedInteriorId ? selectedInteriorId === interior?.interiorCode : false;
  const isDataAvailable = (interior: IInterior) => {
    return get(interior, 'description');
  };

  const finalPrice = useMemo(() => {
    return priceUpdate?.has(interior?.interiorCode)
      ? Number(priceUpdate?.get(interior?.interiorCode))
      : interior?.additionalPriceExcludingVat;
  }, [interior, priceUpdate]);

  const onChangeInterior = (interior: IInterior) =>
    onInteriorChange({
      code: selectedInteriorId === interior?.interiorCode ? null : interior?.interiorCode,
      name: interior?.name,
      price: finalPrice,
      vatCode: interior?.vatCode,
      isRecommended: interior?.isRecommended,
      isOptimisticUpdate: false,
      configType: VehicleConfig.INTERIOR,
      dealerPriceExclVat: calculateRetailPrice(finalPrice, interior?.marginPercentage),
    });

  const isDisable = useMemo(() => {
    if (disableSku.has(interior?.interiorCode)) {
      return true;
    }
    return enableSku.has(interior?.interiorCode) ? false : onloadDisableSku.has(interior?.interiorCode);
  }, [disableSku, enableSku, onloadDisableSku, interior, isPrepared]);

  const getSkuNote = useMemo(() => {
    return createSkuListFromValue(disableSku, onloadDisableSku, interior?.interiorCode);
  }, [disableSku, enableSku, onloadDisableSku, interior, isPrepared]);

  // incase of selected interior which is disable by rule needs to be removed.
  useEffect(() => {
    const isUnselectedSku = unselectedSku.has(interior?.interiorCode);
    if ((isDisable || isUnselectedSku) && isPrepared && selectedInteriorId === interior?.interiorCode) {
      onInteriorChange({
        code: null,
        name: interior?.name,
        price: finalPrice,
        vatCode: interior?.vatCode,
        isRecommended: interior?.isRecommended,
        isOptimisticUpdate: true,
        configType: VehicleConfig.INTERIOR,
        dealerPriceExclVat: calculateRetailPrice(finalPrice, interior?.marginPercentage),
      });
    }
  }, [isDisable, unselectedSku, isPrepared, selectedInteriorId, interior]);

  // incase of re-selection based on previous disabled interior
  useEffect(() => {
    if (!isDisable && isPrepared && selectedInteriorId === interior?.interiorCode) {
      onInteriorChange({
        code: selectedInteriorId,
        name: interior?.name,
        price: finalPrice,
        vatCode: interior?.vatCode,
        isRecommended: interior?.isRecommended,
        isOptimisticUpdate: true,
        configType: VehicleConfig.INTERIOR,
        dealerPriceExclVat: calculateRetailPrice(finalPrice, interior?.marginPercentage),
      });
    }
  }, [isDisable, selectedInteriorId, interior, finalPrice]);

  let subTitleText = 'Inkludert';

  if (!interior?.isRecommended) {
    subTitleText = `${finalPrice >= 0 ? '+' : ''} ${priceFormatter(finalPrice, interior)}`;
  }

  const setCurrentData = (interior: IInterior) => {
    setActiveDesc(interior);
    setActive(!active);
  };

  return (
    <Card
      key={interior?.id}
      id={interior?.id}
      title={interior?.type}
      bosSwatch={prepareImageObject(interior?.images?.[0])}
      customPriceTag={subTitleText}
      type={CardType.BosCard}
      selected={isSelected}
      isDisabled={disabled || isDisable}
      alertMessage={{
        message: isDisable ? getSkuNote : '',
        typeOfAlert: MessagebarSeverityLevel.WARNING
      }}
      displayTag
      disableTag={!isDataAvailable(interior)}
      tagValue={interior?.interiorCode}
      updateSelected={() => onChangeInterior(interior)}
      onTagClick={() => setCurrentData(interior)}
    />
  );
};
