import { useLazyQuery, useMutation } from '@apollo/client';
import { ICartPrices, ICartProps, ICartSummaryData } from '@next-components/cart';
import { bosColor } from '@next-components/common-styles';
import { SmallLink } from '@next-components/link';
import { Document, pdf } from '@react-pdf/renderer';
import { SourceObject } from '@react-pdf/types';
import { saveAs } from 'file-saver';

import {
  AccessoryType,
  FinancingType,
  getLocationIDByMake,
  IBosApiDealerInfoData,
  ICalculation,
  ICustomer,
  IOrder,
  IPackageEquipmentItems,
  IPackageEquipments,
  IVehicle,
  IVehicleAccessory,
  IVehicleConfiguration,
  QuoteStatus,
  isStockSaleOrderToBeProcessed,
  INextArticle,
} from 'next-common';
import React, { useEffect, useState } from 'react';
import { ERROR_POLICY, ICentralisedProperties, updateOrderDraftMutationParsed, useDealer } from '../../../../common';
import { useRestrictAccess } from '../../../../common/hooks/useRestrictAccess';
import { ServiceContractPDF, previewServiceContractClick } from '../ServiceContractPDF';
import { OfferDocument } from './OfferDocument';
import { findConfigValues, getImageSource } from './salesOfferPdf.utils';
import { VehicleConfig, getStockSaleParams } from '../../../../utils';
import {
  getMultiEquipmentToRemove,
  getMultiIncludedEquipmentToAdd,
  IEquipmentIdAndSKU,
} from 'sales-crystallize-common';
import { trim, filter, some, concat } from 'lodash';
import { ICommonSalesOfferPdfProps } from '../../sales-order-view/OrderPane';
import { queryEquipmentList } from '../../../../graphql';
import { Button } from '@next-components/cta';
import { Icon, IconType } from '@next-components/bos-icon';
import { SalesOfferPdfContainer } from './SalesOfferPdf.styled';

interface ISalesOfferPdfProps extends ICommonSalesOfferPdfProps, ICentralisedProperties {
  order: IOrder;
  vehicleConfig: IVehicleConfiguration;
  title?: string;
  updatingExpiryDate?: boolean;
  updatingPrerequisite?: boolean;
  cartData?: ICartProps;
  mappedEquipment: IEquipmentIdAndSKU[];
  className?: string;
  articles?: INextArticle[];
  carType?: string;
}

export interface IOrderOffers {
  loan: ICalculation[];
  leasing: ICalculation[];
  cash: ICalculation[];
}

interface ISalesOfferPdfLinkProps extends ICommonSalesOfferPdfProps, ICentralisedProperties {
  order: IOrder;
  dealer: IBosApiDealerInfoData;
  vehicle: IVehicle;
  vehicleImage: SourceObject;
  vehicleConfig: IVehicleConfiguration;
  title?: string;
  accessories: IAccessoryConfig[];
  isLoading?: boolean;
  isSerialNo: boolean;
  disablePdfLogo: boolean;
  orderOffers: IOrderOffers;
  cartData?: ICartProps;
  standredPackageEquipmentList?: IPackageEquipmentItems[];
  modelPackagesEquipmentList?: IPackageEquipmentItems[];
  infoAgent: string;
  vatPrices: ICartPrices;
  className?: string;
  articles?: INextArticle[];
  carType?: string;
}

interface IServiceContractPdfLinkProps {
  order: IOrder;
}

export interface IAccessoryConfig extends Partial<IVehicleAccessory> {
  id: string;
  name: string;
  price: number;
  priceExcludingVat: number;
  description: string;
  image?: SourceObject;
  type?: number;
  priceIncludingVatIce?: number;
  priceIncludingVatEl?: number;
  priceExcludingVatIce?: number;
  priceExcludingVatEl?: number;
  accessoryType?: AccessoryType;
  isCustomIncludeMVA?: boolean;
}

enum PackageEquipments {
  STANDARD_EQUIPMENT = 'standard-equipment',
  MODAL_EQUIPMENT = 'package-equipment',
}

export const pdfFileNaming = (customer: ICustomer): string => {
  const customerInfo =
    customer?.bosCustomer?.customerType?.toString() === 'BUSINESS'
      ? customer?.bosCustomer?.organizationName || ''
      : (customer?.bosCustomer?.firstName ? customer?.bosCustomer?.firstName + '_' : '') +
        (customer?.bosCustomer?.lastName || '');
  return customerInfo;
};

const checkForModelVariantAvailablity = (cartData: ICartSummaryData[]) => {
  if (!cartData) return;
  const modelVariantKeyList: string[] = [VehicleConfig?.MODEL, VehicleConfig?.VARIANT];
  return cartData?.findIndex((item) => modelVariantKeyList?.includes(item?.key)) >= 0;
};

const PdfButton = (props: ISalesOfferPdfLinkProps) => {
  const newFileName = `Tilbud ${props?.order?.customer ? pdfFileNaming(props?.order?.customer) : ''}`; // Old filename was 'tilbud.pdf'
  const [generating, setGenerating] = useState(false);
  const [updateOrderDraftMutation] = useMutation(updateOrderDraftMutationParsed);
  const isModelVariantAvailable = checkForModelVariantAvailablity(props?.cartData?.data);
  const updateVehicleOrderDraft = async () => {
    if (!props?.order?.lead) {
      return;
    }
    const variables = {
      orderId: props?.order?.id,
      input: {
        quoteStatus: QuoteStatus.OFFER_CREATED,
      },
    };
    await updateOrderDraftMutation({ variables });
  };

  const downloadPDF = async () => {
    if (props?.cartData) {
      if (isModelVariantAvailable && !(props?.isLoading || generating)) {
        setGenerating(true);
        const doc = <OfferDocument {...props} infoAgent={props?.infoAgent} />;
        const asPdf = pdf(<Document />); // dummy
        asPdf.updateContainer(doc);
        asPdf
          .toBlob()
          .then((blob) => {
            saveAs(blob, `${newFileName}.pdf`);
          })
          .finally(() => {
            setGenerating(false);
          });
        await updateVehicleOrderDraft().catch((error) => {
          // tslint:disable-next-line:no-console
          console.log(error);
        });
      }
    }
  };
  return (
    <>
      {!props?.className?.includes('downloadOffers') ? (
        <Button
          isLoading={props?.isLoading || !props?.cartData?.data?.length || generating}
          onClick={() => downloadPDF()}
          colorVariant="secondary">
          <Icon icon={IconType.NewNextDownload} />
          <span>{props?.isLoading ? '' : props?.title}</span>
        </Button>
      ) : (
        <div className={props?.className ?? ''}>
          <ServiceContractPDF
            order={props?.order}
            signingJob={props?.order?.signingJob}
            linkText={'Last ned tilbud'}
            handleOnClick={() => downloadPDF()}
            isLoading={props?.isLoading || !props?.cartData || !props?.cartData?.data?.length || generating}
          />
        </div>
      )}
    </>
  );
};

const ServiceContractButton = (props: IServiceContractPdfLinkProps) => {
  const [serviceContractPdfProcessing, setServiceContractPdfProcessing] = useState(false);

  return (
    <>
      {(!!props?.order?.serviceContractBosId || !!props?.order?.salesContractPdfId) && (
        <SmallLink
          isLoading={serviceContractPdfProcessing}
          iconLeft="document"
          iconColor={bosColor?.white}
          onLinkClick={() =>
            previewServiceContractClick(
              props?.order?.id,
              props?.order?.serviceContractBosId || props?.order?.salesContractPdfId,
              setServiceContractPdfProcessing,
            )
          }
          text={!serviceContractPdfProcessing && 'Forhåndsvise Servicekontrakt'}
        />
      )}
    </>
  );
};

export const SalesOfferPdf = (props: ISalesOfferPdfProps) => {
  const vehicle = props?.order?.vehicles && props?.order?.vehicles?.length > 0 ? props?.order?.vehicles?.[0] : null;
  const { dealer } = useDealer({
    dealerId:
      props?.order?.isAgent && props?.order?.lead?.make
        ? getLocationIDByMake(props?.order?.lead?.make)
        : props?.order?.user?.departmentId || vehicle?.inventory?.location || '764',
  });
  const infoAgent = useDealer(
    { dealerId: props?.order?.user?.departmentId || vehicle?.inventory?.location || '764' },
    true,
  );
  const isSerialNo = !!vehicle?.serialNo?.length;
  const [orderUpdated, setOrderUpdated] = useState(false);
  const [accessories, setAccessories] = useState<IAccessoryConfig[]>(null);
  const [vehicleImage, setVehicleImage] = useState<SourceObject>(null);
  const { allRestrictAccess } = useRestrictAccess();
  const [orderOffers, setOrderOffers] = useState<IOrderOffers>({ loan: [], leasing: [], cash: [] });

  const [standredPackageEquipmentList, setStandredPackageEquipmentList] = useState<IPackageEquipmentItems[]>(null);
  const [modelPackagesEquipmentList, setModelPackagesEquipmentList] = useState<IPackageEquipmentItems[]>(null);

  const [selectableRulePayload, setSelectableRulePayload] = useState<string[]>([]);
  const params = getStockSaleParams(props?.order);
  const isStockSaleOrder = isStockSaleOrderToBeProcessed(
    props?.configuration,
    params?.source,
    params?.serialNo,
    params?.make,
  );
  const disablePdfLogo =
    allRestrictAccess?.filter((item) => {
      return item?.dealer?.dealerId === props?.order?.soldByDealerId && item?.resources?.includes('OFFER_PDF_LOGO');
    })?.length === 0
      ? true
      : false;

  const [getEquipmentList, { loading }] = useLazyQuery(queryEquipmentList, {
    variables: {
      serialNo: props.order?.vehicles?.[0]?.serialNo,
    },
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setStandredPackageEquipmentList(data?.getEquipmentListForStockSaleOrder);
    },
    onError: (error) => {
      console.error(error);
      setStandredPackageEquipmentList(null);
    },
  });

  useEffect(() => {
    setOrderUpdated(true);
    if (!props?.vehicleConfig || accessories || standredPackageEquipmentList || modelPackagesEquipmentList) {
      setOrderUpdated(false);
      return;
    }
    const accessoriesPromises = (props?.order?.accessories || [])?.map((accessory) =>
      findConfigValues(props?.vehicleConfig, accessory),
    );
    Promise.all(accessoriesPromises).then((mappedAccesories) => {
      setAccessories(mappedAccesories);
      setOrderUpdated(false);
    });

    const standredEquipList: IPackageEquipments = props?.vehicleConfig?.packageEquipments?.find(
      (equipment) => equipment?.id === PackageEquipments.STANDARD_EQUIPMENT,
    );
    const standredPackageEquipments: IPackageEquipmentItems[] = standredEquipList?.items?.length
      ? standredEquipList?.items?.map((packages) => {
          return {
            name: packages?.name,
            sku: packages?.sku,
          };
        })
      : [];

    const modelEquipList: IPackageEquipments = props?.vehicleConfig?.packageEquipments?.find(
      (equipment) => equipment?.id === PackageEquipments.MODAL_EQUIPMENT,
    );
    const modelPackagesEquipments: IPackageEquipmentItems[] = modelEquipList?.items?.length
      ? modelEquipList?.items?.map((packages) => {
          return {
            name: packages?.name,
            sku: packages?.sku,
          };
        })
      : [];

    setStandredPackageEquipmentList(standredPackageEquipments);
    setModelPackagesEquipmentList(modelPackagesEquipments);
  }, [props?.order, props?.vehicleConfig]);

  useEffect(() => {
    let offers = props?.order?.offers;
    let loanData = [];
    let leasingData = [];
    let cashData = [];

    //Perform sorting on Financity data. It will reflact to offer pdf.

    offers?.forEach((offer) => {
      if (offer?.financingType === FinancingType.LOAN && offer?.financing !== null) loanData?.push(offer);
      if (offer?.financingType === FinancingType.LEASING && offer?.financing !== null) leasingData?.push(offer);
      if (offer?.financingType === FinancingType.CASH && offer?.financing !== null) cashData?.push(offer);
    });

    if (loanData?.length > 0) {
      loanData?.sort((currentItem, nextItem) => {
        if (currentItem?.financing?.request?.durationMonths === nextItem?.financing?.request?.durationMonths)
          return currentItem?.financing?.request?.downPayment < nextItem?.financing?.request?.downPayment ? -1 : 1;
        else
          return currentItem?.financing?.request?.durationMonths < nextItem?.financing?.request?.durationMonths
            ? -1
            : 1;
      });

      let sortedLoanData = loanData;
      loanData?.sort((currentItem, nextItem) => {
        if (currentItem?.financing?.request?.downPayment === nextItem?.financing?.request?.downPayment) {
          if (
            sortedLoanData[sortedLoanData?.indexOf(currentItem)]?.financing?.request?.durationMonths ===
            sortedLoanData[sortedLoanData?.indexOf(nextItem)]?.financing?.request?.durationMonths
          ) {
            return currentItem?.financing?.nominalInterest > nextItem?.financing?.nominalInterest ? -1 : 1;
          } else {
            return 1;
          }
        } else return 1;
      });
    }

    if (leasingData?.length > 0) {
      leasingData?.sort((currentItem, nextItem) => {
        if (currentItem?.financing?.request?.durationMonths === nextItem?.financing?.request?.durationMonths)
          return currentItem?.financing?.request?.mileage < nextItem?.financing?.request?.mileage ? -1 : 1;
        else
          return currentItem?.financing?.request?.durationMonths < nextItem?.financing?.request?.durationMonths
            ? -1
            : 1;
      });

      let sortedLeasingData = leasingData;

      leasingData?.sort((currentItem, nextItem) => {
        if (currentItem?.financing?.request?.mileage === nextItem?.financing?.request?.mileage) {
          if (
            sortedLeasingData[sortedLeasingData?.indexOf(currentItem)]?.financing?.request?.durationMonths ===
            sortedLeasingData[sortedLeasingData?.indexOf(nextItem)]?.financing?.request?.durationMonths
          ) {
            return currentItem?.financing?.request?.downPayment < nextItem?.financing?.request?.downPayment ? -1 : 1;
          } else return 1;
        } else return 1;
      });

      sortedLeasingData = leasingData;

      leasingData?.sort((currentItem, nextItem) => {
        if (currentItem?.financing?.request?.downPayment === nextItem?.financing?.request?.downPayment) {
          if (
            sortedLeasingData[sortedLeasingData?.indexOf(currentItem)]?.financing?.request?.durationMonths ===
              sortedLeasingData[sortedLeasingData?.indexOf(nextItem)]?.financing?.request?.durationMonths &&
            sortedLeasingData[sortedLeasingData?.indexOf(currentItem)]?.financing?.request?.mileage ===
              sortedLeasingData[sortedLeasingData?.indexOf(nextItem)]?.financing?.request?.mileage
          )
            return currentItem?.financing?.nominalInterest > nextItem?.financing?.nominalInterest ? -1 : 1;
          else return 1;
        } else return 1;
      });
    }

    setOrderOffers({ loan: loanData, leasing: leasingData, cash: cashData });

    //Prepare selectables array
    let selectables: string[] = [];
    if (props?.order?.options?.length) {
      selectables?.push(props?.order?.options?.[0]?.colorId);
      selectables?.push(props?.order?.options?.[0]?.interiorId);
    }
    if (props?.order?.equipments?.length) {
      props?.order?.equipments?.map((equipment) => selectables?.push(equipment?.sku));
    }
    setSelectableRulePayload(selectables);
  }, [props?.order]);

  useEffect(() => {
    if (selectableRulePayload?.length) {
      const rulesPayload: string = `${selectableRulePayload?.join(';') || ''};`;
      try {
        let removedEquipListFromModelPackage: any[] = [];
        let removedEquipListFromStandredPackage: IPackageEquipmentItems[] = [];
        let addEquipListToModelPackage: IPackageEquipmentItems[] = [];

        //sales-function call for remove
        const equipmentsToRemove: IEquipmentIdAndSKU[] = getMultiEquipmentToRemove(
          rulesPayload,
          props?.mappedEquipment,
        );
        if (equipmentsToRemove?.length) {
          //Remove the equipments from modelPackageEquipments
          removedEquipListFromModelPackage = filter(modelPackagesEquipmentList, (salesPackage) => {
            if (some(equipmentsToRemove, (removeItem) => removeItem?.sku === trim(salesPackage?.sku)) === false) {
              return salesPackage;
            }
          });
          //Remove the equipments from standredPackage
          removedEquipListFromStandredPackage = standredPackageEquipmentList.filter((modelPackage) => {
            if (equipmentsToRemove.some((removeItem) => removeItem?.sku === trim(modelPackage?.sku)) === false) {
              return modelPackage;
            }
          });
        }
        //sales-function call for add
        const equipmentsToAdd: IEquipmentIdAndSKU[] = getMultiIncludedEquipmentToAdd(
          rulesPayload,
          props?.mappedEquipment,
        );

        if (equipmentsToAdd?.length) {
          equipmentsToAdd?.map((addEquipment) => {
            if (
              props?.vehicleConfig?.varnishes?.find((varnish) => varnish?.varnishCode === addEquipment?.sku) ||
              props?.vehicleConfig?.interiors?.find((interior) => interior?.interiorCode === addEquipment?.sku) ||
              props?.vehicleConfig?.model?.rims?.find((rim) => rim?.sku === addEquipment?.sku) ||
              props?.vehicleConfig?.model?.selectableEquipment?.find(
                (equipment) => equipment?.sku === addEquipment?.sku,
              ) ||
              props?.vehicleConfig?.combinationEquipment?.find((combEquip) => combEquip?.sku === addEquipment?.sku)
            ) {
              addEquipListToModelPackage.push({
                name: addEquipment?.name,
                sku: addEquipment?.sku,
              });
            }
          });
        }

        if (removedEquipListFromStandredPackage?.length) {
          setStandredPackageEquipmentList(removedEquipListFromStandredPackage);
        }
        if (removedEquipListFromModelPackage?.length || addEquipListToModelPackage?.length) {
          let equipList = modelPackagesEquipmentList;
          if (removedEquipListFromModelPackage?.length) {
            equipList = removedEquipListFromModelPackage;
          }
          if (addEquipListToModelPackage?.length) {
            equipList = concat(equipList, addEquipListToModelPackage);
          }
          setModelPackagesEquipmentList(equipList);
        }
      } catch (error) {
        console.warn(`Fetch addition rule error - ${error}`);
      }
    }
  }, [selectableRulePayload, props?.mappedEquipment]);

  useEffect(() => {
    setOrderUpdated(true);
    if ((!isStockSaleOrder && !props?.vehicleConfig) || vehicleImage) {
      setOrderUpdated(false);
      return;
    }
    getImageSource(props?.vehicleConfig?.model?.images, 'vehicle').then((image) => {
      setVehicleImage(image);
      setOrderUpdated(false);
    });
  }, [props?.vehicleConfig]);

  useEffect(() => {
    if (isStockSaleOrder) {
      getEquipmentList();
    }
  }, [props.order]);

  if (!props?.order || !dealer || (!isStockSaleOrder && !props?.vehicleConfig)) return null;

  return (
    <SalesOfferPdfContainer>
      <div className="icon-container">
        <PdfButton
          isLoading={orderUpdated || props?.updatingExpiryDate || props?.updatingPrerequisite || loading}
          vehicleConfig={props?.vehicleConfig}
          vehicle={vehicle}
          vehicleImage={vehicleImage}
          dealer={dealer}
          order={props?.order}
          title={props?.title}
          accessories={accessories}
          isSerialNo={isSerialNo}
          disablePdfLogo={disablePdfLogo}
          orderOffers={orderOffers}
          cartData={props?.cartData}
          standredPackageEquipmentList={standredPackageEquipmentList}
          modelPackagesEquipmentList={modelPackagesEquipmentList}
          infoAgent={props?.order?.isAgent && infoAgent?.dealer?.name}
          vatPrices={props?.vatPrices}
          configuration={props?.configuration}
          className={props?.className}
          articles={props?.articles}
          carType={props?.carType}
        />
      </div>
    </SalesOfferPdfContainer>
  );
};

SalesOfferPdf.defaultProps = {
  title: 'Last ned tilbud',
};
