import { Text, View, Image } from '@react-pdf/renderer';
import { pdfMessageBarWrapper, pdfMessageBarText, pdfMessageIconStyle, pdfMessageBarContent } from './common-styles';
import React from 'react';
import info from './imgs/info.png';

interface MessageBar {
  text: string;
}

export const PdfMessagebar = (props: MessageBar) => {
  return (
    <View style={pdfMessageBarWrapper}>
      <View style={pdfMessageBarContent}>
        <View style={pdfMessageIconStyle}>
          <Image source={info} style={{ height: 12, width: 12 }} />
        </View>
        <View style={pdfMessageBarText}>
          <Text>{props?.text}</Text>
        </View>
      </View>
    </View>
  );
};
