import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, ServerError, split } from '@apollo/client';
import { PossibleTypesMap } from '@apollo/client/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { RetryLink } from '@apollo/client/link/retry';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { getMainDefinition } from 'apollo-utilities';
import { GraphQLError, OperationDefinitionNode } from 'graphql';
import { cleanTypenameLink, handleRefreshToken } from './utils';
import introspectionQueryResultData from './fragmentTypes.json';
import { redirect } from 'next-common';
import { Routes, apimEndpoint } from './common/constants';
import { getCachedToken } from './config/authentication';
import { getAppendingNumericIdFromUrl } from "./utils";
// @ts-ignore
const defaultGraphQLEndpoint = window._env_.REACT_APP_GRAPHQL_ENDPOINT;
// @ts-ignore
const salesCommonGraphqlURL = window._env_.REACT_APP_SALES_COMMON_GRAPHQL_URL;

const origin = window.location.origin.replace(/\/$/, '');
// @ts-ignore
export let SERVER_GRAPHQL_URL = origin.includes('https://') ? `${origin}/graphql` : defaultGraphQLEndpoint;
if (apimEndpoint) {
  SERVER_GRAPHQL_URL = `${apimEndpoint}/graphql`
}
// tslint:disable-next-line: no-console
console.log('GraphQL endpoint = ', SERVER_GRAPHQL_URL);

const introspectionToPossibleTypes = (data): PossibleTypesMap => {
  const possibleTypes = {};

  data.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype) => subtype.name);
    }
  });

  return possibleTypes;
};

const cache = new InMemoryCache({
  possibleTypes: introspectionToPossibleTypes(introspectionQueryResultData),
  typePolicies: {
    VehicleModel: {
      // Disable cache normalization
      keyFields: false,
    },
  },
});

export const accessForbiddenType = 'accessForbidden';

const persistorOptions = {
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
};

export const persistor = new CachePersistor(persistorOptions);

export const setUpCache = async () => {
  await persistor.restore();
};

// tslint:disable-next-line: no-console
setUpCache().catch((error) => console.error(error));

const httpLink = new HttpLink({ uri: SERVER_GRAPHQL_URL });

const salesCommonLink = new HttpLink({ uri: salesCommonGraphqlURL });

const wsOptions = () => {
  return {
    uri: SERVER_GRAPHQL_URL.replace('https', 'wss'),
    options: {
      lazy: true,
      reconnect: true,
      timeout: 10000,
      // connectionParams: {
      //   Authorization: authToken ? `Bearer ${authToken}` : '',
      // },
    },
  };
};

export const getExtractedData = (error: GraphQLError) => {
  const extractedData = error?.message?.match(/"([^"]+)"/g);
  const data = {
    ...(extractedData?.length > 0 ? { email: extractedData[0].replace(/"/g, '') } : {}),
    ...(extractedData?.length > 1 ? { dealerId: extractedData[1].replace(/"/g, '') } : {}),
    ...(extractedData?.length > 2 ? { dealerName: extractedData[2].replace(/"/g, '') } : {}),
  };
  return Object.keys(data).reduce((mapped, key) => {
    return {
      ...mapped,
      [key]: data?.[key] === 'undefined' ? null : data?.[key],
    };
  }, {});
};

const wsLink = new WebSocketLink(wsOptions());

// ******* NETWORK LINKS *******
const errorLink = onError(({ graphQLErrors, networkError, operation }: ErrorResponse) => {
  const isUnauthenticated =
    Array.isArray(graphQLErrors) && graphQLErrors.some((e) => e.extensions && e.extensions.code === 'UNAUTHENTICATED');
  const isForbidden =
    Array.isArray(graphQLErrors) && graphQLErrors.some((e) => e.extensions && e.extensions.code === 'FORBIDDEN');
  const statusCode =
    (networkError?.hasOwnProperty('statusCode') && (networkError as ServerError).statusCode) || null;
  operation.setContext({
    allowedUser: !isForbidden,
  });

  function hasTokenExpired() {
    return !isForbidden && isUnauthenticated;
  }
  // If JWT token is expire refresh it with new token
  if (isUnauthenticated) {
    handleRefreshToken();
  }

  if (statusCode === 400) {
    if (hasTokenExpired()) {
      window.location.reload();
    }
    if (isForbidden) {
      const forbidenRoute = Routes.getForbiddenPage();
      if (window.location.pathname.includes(forbidenRoute)) return;
      redirect.href = forbidenRoute;
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      // tslint:disable-next-line:no-console
      console.error(`GraphQL Error: ${message}`);
    });
  }
  if (networkError) {
    // tslint:disable-next-line:no-console
    console.error(`Network Error: ${networkError.message}`);
  }
});

const retryIf = (error) => {
  const doNotRetryCodes = [500, 400];
  return !!error && !doNotRetryCodes.includes(error.statusCode);
};

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf,
  },
});

const backendLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

// AUTH-LINK
const authLink = new ApolloLink((operation, forward) => {
  const token = getCachedToken();
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      ...operation.getContext().headers,
      Authorization: `Bearer ${token}`,
      'X-Order-Id': `${getAppendingNumericIdFromUrl()}`
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const link = ApolloLink.from([cleanTypenameLink, authLink, retryLink, errorLink, backendLink]);
const salesCommonApolloLink = ApolloLink.from([cleanTypenameLink, authLink, retryLink, errorLink, salesCommonLink]);
const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'sales-common',
    salesCommonApolloLink,
    link,
  ),
  cache,
  resolvers: {},
  typeDefs: '',
});

export const onApolloSignOut = async () => {
  try {
    persistor.pause();
    await persistor.purge();
    await client.resetStore();
    await persistor.resume();
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};

export default client;
