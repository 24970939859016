import { bosColor, primary } from '@next-components/common-styles';
import { DynamicList, IDynamicListColumn, IDynamicListConfig, IDynamicListSort } from '@next-components/dynamic-list';
import { IconType } from '@next-components/bos-icon';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { IMenuItem } from '@next-components/menu';
import { partial } from 'lodash';
import { ICalculation, ICustomer, ILead, IOrderFiltersInput, IOrderQueryResult, IPagination, OrderStatus } from 'next-common';
import React, { ReactNode, useEffect, useState } from 'react';
import { maxExpiryLength, useOrderExpiryLength } from '../../../../common';
import { findExpiresAt } from '../../../../utils';
import { TrackAndTrace, VehicleOrder } from '../../../sales/sales-common/DynamicCols';
import { OrderCategory } from '../orderQuery.utils';
import {
  customerFormatter,
  dateFormatter,
  expiryDateFormatter,
  makeIcon,
  menuButtonsFormatter,
  offerStatusFormatter,
  reservationStatusFormatter,
  salesPersonFormatter,
  CANCEL_RESERVATION_TEXT,
  DELETE_OFFER_TEXT,
  RESUME_ORDER_TEXT,
  SHOW_OFFER_TEXT,
} from './MyOrders.formatters';
import { MyOrdersStyled } from './MyOrders.styled';
import { IconButton } from '@next-components/icon-button';

const myOfferSort: IDynamicListSort[] = [{ key: 'createdAt', reverse: true }];
const myOrderSort: IDynamicListSort[] = [{ key: 'signedAt', reverse: true }];

const getMenuConfig = (props: IMyOrdersProps): IDynamicListConfig => {
  let colConfig: IDynamicListColumn[];
  let { onContinue, onCancelReservation, onDelete } = props;
  const { onSelect } = props;

  switch (props.category) {
    case OrderCategory.ORDER:
    case OrderCategory.STOCK:
    case OrderCategory.DEMO:
    case OrderCategory.CANCEL_ORDER:
      colConfig = myOrdersConfig;
      onContinue = null;
      onCancelReservation = null;
      onDelete = null;
      break;
    case OrderCategory.DELIVERED:
      colConfig = myDeliveredConfig;
      onContinue = null;
      onCancelReservation = null;
      onDelete = null;
      break;
    default:
      colConfig = myOffersConfig;
  }

  return {
    cols: [
      ...colConfig,
      {
        header: '',
        key: 'id',
        colWidth: '4rem',
        noSort: true,
        formatter: partial(
          menuButtonsFormatter,
          getMenuItems(props.category, onSelect, onContinue, onCancelReservation, onDelete),
          null,
        ),
      },
    ],
    sortingIcon: {
      iconDropdownDown: IconType.NewNextArrowDown,
      iconDropdownUp: IconType.NewNextArrowUp,
      iconHeight: 18,
      iconWidth: 18,
      iconColor: bosColor.black,
    },
  };
};

const getMenuItems = (type: OrderCategory, onSelect, onContinue, onCancelReservation, onDelete): IMenuItem[] => {
  switch (type) {
    case OrderCategory.ORDER:
    case OrderCategory.STOCK:
    case OrderCategory.DEMO:
    case OrderCategory.CANCEL_ORDER:
    case OrderCategory.DELIVERED:
      return [
        {
          icon: IconType.NewNextDocumentLines,
          text: 'Vis',
          onSelect: onSelect,
        },
      ];
    default:
      return [
        {
          icon: IconType.NewNextDocumentLines,
          text: SHOW_OFFER_TEXT,
          onSelect: onSelect,
        },
        {
          icon: IconType.NewNextHistory,
          text: RESUME_ORDER_TEXT,
          onSelect: onContinue,
        },
        {
          icon: IconType.NewNextCross,
          text: CANCEL_RESERVATION_TEXT,
          onSelect: onCancelReservation,
        },
        {
          icon: IconType.NewNextTrash,
          text: DELETE_OFFER_TEXT,
          onSelect: onDelete,
        },
      ];
    // default:
    //   return [];
  }
};

const myOffersConfig: IDynamicListColumn[] = [
  { header: 'Status', key: 'status', colWidth: '7rem', formatter: offerStatusFormatter },
  { header: 'Reservasjon', key: 'reservationStatus', colWidth: '1fr', formatter: reservationStatusFormatter },
  { header: 'Kunde', key: 'customer', colWidth: '1.5fr', formatter: customerFormatter },
  { header: 'Merke', key: 'vehicleMake', colWidth: '4.75rem', formatter: makeIcon },
  { header: 'Modell', key: 'vehicleModelSeries', colWidth: '0.75fr' },
  { header: 'T&T', key: 'trackAndTrace', colWidth: '4rem' },
  { header: 'Serienr.', key: 'serialNumber', colWidth: '1fr' },
  { header: 'Selger', key: 'user', colWidth: '1.5fr', formatter: salesPersonFormatter },
  { header: 'Opprettet', key: 'createdAt', colWidth: '7rem', formatter: dateFormatter },
  { header: 'Utløper', key: 'actualExpiresAt', colWidth: '7rem', formatter: expiryDateFormatter },
];

const myOrdersConfig: IDynamicListColumn[] = [
  { header: 'Status', key: 'status', colWidth: '6rem', formatter: offerStatusFormatter },
  { header: 'Kunde', key: 'customer', colWidth: '1.5fr', formatter: customerFormatter },
  { header: 'Merke', key: 'vehicleMake', colWidth: '4.75rem', formatter: makeIcon },
  { header: 'Modell', key: 'vehicleModelSeries', colWidth: '0.75fr' },
  { header: 'T&T', key: 'trackAndTrace', colWidth: '4rem' },
  { header: 'IFS Ordre', key: 'vehicleOrder', colWidth: '7rem' },
  { header: 'Serienr.', key: 'serialNumber', colWidth: '1fr' },
  { header: 'Selger', key: 'user', colWidth: '1.5fr', formatter: salesPersonFormatter },
  { header: 'Opprettet', key: 'createdAt', colWidth: '7rem', formatter: dateFormatter },
  { header: 'Signert', key: 'signedAt', colWidth: '7rem', formatter: dateFormatter },
];

const myDeliveredConfig: IDynamicListColumn[] = [
  { header: 'Kunde', key: 'customer', colWidth: '1.5fr', formatter: customerFormatter },
  { header: 'Merke', key: 'vehicleMake', colWidth: '4.75rem', formatter: makeIcon },
  { header: 'Modell', key: 'vehicleModelSeries', colWidth: '0.75fr' },
  { header: 'Serienr.', key: 'serialNumber', colWidth: '0.5fr' },
  { header: 'Selger', key: 'user', colWidth: '1.5fr', formatter: salesPersonFormatter },
  { header: 'Utlevert', key: 'deliveredAt', colWidth: '0.5fr', formatter: dateFormatter },
];

interface IMyOrdersProps {
  data: IOrderQueryResult[];
  filter: IOrderFiltersInput;
  category: OrderCategory;
  loading: boolean;
  pagination: IPagination;

  onLoadMore(): void;

  onSelect(element): void;
  onContinue(element): void;
  onCancelReservation(element): void;
  onDelete(element): void;
}

export interface IMyOrdersItem {
  id: string;
  status: OrderStatus;
  ifsId: string;
  serialNumber: string | ReactNode;
  trackAndTrace: ReactNode;
  vehicleMake: string;
  vehicleModelSeries: string;
  vehicleOrder: ReactNode;
  actualExpiresAt: Date;
  vehicles: object[];
  customer: Partial<ICustomer>;
  chosenOffer: Partial<ICalculation>;
  lead?: ILead;
}

const validCategories = [OrderCategory.ORDER, OrderCategory.STOCK, OrderCategory.DEMO, OrderCategory.CANCEL_ORDER];

export const MyOrders = (props: IMyOrdersProps) => {
  const brandExpiryLength = useOrderExpiryLength();
  const [config, setConfig] = useState<IDynamicListConfig>();
  const [orders, setOrders] = useState<IMyOrdersItem[]>([]);
  const [sort, setSort] = useState<IDynamicListSort[]>(myOfferSort);

  const getBrandExpiryLength = (brandCode: string) => {
    let expiryObj = brandExpiryLength?.find((item) => item.brandCode === brandCode);
    return expiryObj?.length;
  };

  const getVehiclOrderType = (order: IOrderQueryResult) => {
    const vehicle = order.vehicles?.[0];
    if (vehicle) {
      if (vehicle.serialNo) return vehicle.serialNo;
      else return 'Fabrikkbestilt';
    } else return '-';
  };

  useEffect(() => {
    setSort([OrderCategory.ORDER, OrderCategory.STOCK, OrderCategory.DEMO, OrderCategory.CANCEL_ORDER].includes(props.category) ? myOrderSort : myOfferSort);
    setConfig(getMenuConfig(props));
  }, [props.category]);

  useEffect(() => {
    return setOrders(
      props.data?.map((order) => {
        const orderVahicalBrand = order.vehicles?.[0]?.make;
        const expiryLength = orderVahicalBrand ? getBrandExpiryLength(orderVahicalBrand) : maxExpiryLength;
        const vehicle = order.vehicles?.[0];
        const ifsId = order.orderReference?.ifsId;

        const serialNumber =
          [OrderCategory.ORDER, OrderCategory.STOCK, OrderCategory.DEMO, OrderCategory.CANCEL_ORDER].includes(props.category) && !vehicle?.serialNo.length ? (
            <VehicleOrder id={order.id} showVehicleId />
          ) : (
            getVehiclOrderType(order)
          );
        const trackAndTrace = (
          <TrackAndTrace
            serialNo={vehicle?.serialNo}
            make={order.config?.model?.make}
            series={order.config?.model?.series}
            fetchPolicy="cache-and-network"
          />
        );
        const vehicleMake = (vehicle?.make || order.config?.model?.make) ?? null;
        const vehicleModelSeries = (order.config?.model?.series || vehicle?.model?.name) ?? '-';
        const vehicleOrder = validCategories.includes(props.category) && <VehicleOrder id={order.id} />;
        const reservationStatus = order?.reserveDetails?.vehicleReserveStatus;
        const actualExpiresAt = findExpiresAt(order?.actualExpiresAt, order?.createdAt, expiryLength);

        return {
          ...order,
          actualExpiresAt,
          ifsId,
          serialNumber,
          trackAndTrace,
          vehicleMake,
          vehicleModelSeries,
          vehicleOrder,
          reservationStatus,
        };
      }),
    );
  }, [props.data, setOrders]);

  const emptyFormatter = () => {
    return (
      <div className={'loading'}>
        <LoadingSpinner size={48} color={primary} />
      </div>
    );
  };

  const loadMoreFormatter = () => {
    return (
      <div className={'loadMoreRow'}>
        <IconButton
          ariaLabel={'add-item'}
          className={'addItem'}
          fill={bosColor.turquoise}
          icon={IconType.NewNextPlus}
        />
        <span>Vis flere</span>
      </div>
    );
  };

  return (
    <MyOrdersStyled className={props?.category.toLowerCase()}>
      <DynamicList
        pagination={props.pagination}
        onLoadMore={
          orders?.length > 0 && props.pagination?.totalItems > props.pagination?.itemsPerPage * props.pagination?.page
            ? props.onLoadMore
            : null
        }
        data={orders}
        sort={sort}
        config={config}
        onElementClick={props.onSelect}
        emptyFormatter={props.loading && emptyFormatter}
        loading={props.loading}
        loadMoreFormatter={loadMoreFormatter()}
      />
    </MyOrdersStyled>
  );
};
