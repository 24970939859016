import { get } from 'lodash';
import React, { useState } from 'react';
import {
  IDescriptionDialogContent,
  ISkuVehicleCount,
  VehicleConfig,
  getModelCode,
  priceFormatter,
} from '../../../../../utils';
import { IVehicleModel, IModelPackages } from '../../VehiclePickerPage';
import { ICartDataProps } from '../ResultGrid';
import { CarConfigureGroupStyled } from '../ResultGrid.styled';
import { OrderAccordion } from '../../../sales-common/OrderAccordion';
import { VehicleDescription } from './VehicleDescription';
import { Card } from '@next-components/card';
import { CardType } from '@next-components/common-types';
import { v4 as uuidv4 } from 'uuid';
import { HashMap, MAKE } from 'next-common';
export interface IVehicleVariant {
  data: IVehicleModel;
  selectedModelId: string;
  selectedVariantId: string;
  selectedVariantName: string
  allSkuVehicleCount: ISkuVehicleCount;
  disabled: boolean;
  vehicleBrand?: MAKE;
  modelOptions?: HashMap;
  onVariantChange: (data: ICartDataProps) => void;
}

export const VehicleVariant = (props: IVehicleVariant) => {
  const { data, selectedModelId, selectedVariantId, allSkuVehicleCount, onVariantChange, disabled, selectedVariantName,vehicleBrand } = props;
  const [active, setActive] = useState<boolean>(false);
  const [activeDesc, setActiveDesc] = useState<IDescriptionDialogContent>(null);

  const onChangeVariant = (variant: IModelPackages) => {
      onVariantChange({
        id: variant?.id === selectedVariantId ? "" : variant?.id,
        name: variant?.name === selectedVariantName ? "" :variant?.name  ,
        price:variant?.id === selectedVariantId ? null: variant?.price,
        isRecommended: variant?.isRecommended,
        sku: variant?.sku,
        modelCode: variant?.modelCode,
        vatCode: variant?.id === selectedVariantId ? null: variant?.vatCode,
        configType: VehicleConfig.VARIANT,
        dealerPriceExclVat: variant?.id === selectedVariantId ? null: variant?.pricing?.packageCostPriceExclVat,
        path: variant?.id === selectedVariantId ? null : variant?.path
      });
  }
  const isDataAvailable = (variant: IModelPackages) => {
    return (
      get(variant, 'packageDescriptionContent.includedDesc') &&
      get(variant, 'packageDescriptionContent.included.length')
    );
  };
  const setCurrentData = (variant: IModelPackages) => {
    setActiveDesc(variant?.packageDescriptionContent);
    setActive(!active);
  };

  return (
    <OrderAccordion
      label="Utstyrspakke"
      isOpen={!!(selectedModelId || selectedVariantId)}
      isDisabled={!(selectedModelId || selectedVariantId)}>
      {data?.salesPackages?.length <= 0 || !data ? (
        <p> Ingen utstyrspakke tilgjengelig</p>
      ) : (
        <CarConfigureGroupStyled className="vehicleVariant">
          {data?.salesPackages?.map((variant: IModelPackages) => {
            const stockCount = allSkuVehicleCount?.[variant?.sku] !== undefined ? `(${allSkuVehicleCount?.[variant?.sku]})` : '';
            const label = vehicleBrand === MAKE.Kia ? `${variant?.name || ''} ${stockCount}` : `${variant?.name || ''} (${allSkuVehicleCount?.[variant?.sku] ?? '0'})`;
            const customPriceTag = variant?.isRecommended
              ? 'Inkludert'
              : `${variant?.price >= 0 ? '+' : '-'} ${priceFormatter(variant?.price, variant) || ''}`;
            const modelCode = getModelCode(variant?.sku, props.modelOptions);
            const tagValue = modelCode === variant?.sku ?  '000' : variant?.sku?.split(" ").pop();
            return (
              <Card
                key={uuidv4()}
                id={variant?.id}
                title={label}
                customPriceTag={customPriceTag}
                type={CardType.BosCard}
                selected={selectedVariantId === variant?.id}
                isDisabled={disabled}
                displayTag
                updateSelected={() => onChangeVariant(variant)}
                disableTag={!isDataAvailable(variant)}
                tagValue={tagValue}
                onTagClick={() => setCurrentData(variant)}
              />
            );
          })}

          <VehicleDescription
            data={activeDesc}
            active={active}
            onClose={() => {
              setActive(!active);
            }}
          />
        </CarConfigureGroupStyled>
      )}
    </OrderAccordion>
  );
};
