import { ICartPrices } from '@next-components/cart';
import {
  getInteriorCodeFromFactory,
  getInteriorCodeFromOptions,
  getVarnishCodeFromFactory,
  getVarnishCodeFromOptions,
  INextArticle,
  IOrder,
  IVatCalculation,
  IVehicleOptionInput,
  VehicleEquimentTypeEnum,
  VehicleEquipmentCategoryEnum,
} from 'next-common';
import { removeDuplicateObjectFromArray, VehicleConfig } from './vehicleOrder';
import { remove, cloneDeep, defaultTo } from 'lodash';
import {
  calculatePricesBasedOnArticleList,
  ICalculatePricesBasedOnArticleListResult,
  mapVehicleApplicationCodeStringToEnum,
} from 'sales-common-calculations';
import { IVehicleModel } from '../../components/sales/sales-vehicle-picker-page';
import { ArticleType } from 'sales-common';

export const prepareArticles = (
  key = '',
  price = 0,
  vatCode = null,
  articleType = null,
  dealerPriceExclVat = 0,
  isRecommended = false,
  articles: INextArticle[] = [],
  isRemove = false,
  id: string = '',
) => {
  const existingArticles = cloneDeep(articles);
  const keyIndex = existingArticles?.findIndex((article) => article?.key === key);
  const priceExclVat = isRecommended ? 0 : price;
  if (keyIndex >= 0) {
    if (isRemove) {
      existingArticles?.splice(keyIndex, 1);
    } else if (key === VehicleConfig.VARIANT) {
      remove(existingArticles, (existData) => {
        return existData?.key !== VehicleConfig.MODEL && existData?.key !== VehicleConfig.VARIANT;
      });
      existingArticles[keyIndex] = { key, priceExclVat, vatCode, id, articleType, dealerPriceExclVat };
    } else {
      existingArticles[keyIndex] = { key, priceExclVat, vatCode, id, articleType, dealerPriceExclVat };
    }
  } else {
    existingArticles?.push({ key, priceExclVat, vatCode, id, articleType, dealerPriceExclVat });
  }
  return existingArticles;
};

export const mapVatPricesForNext = (vatCalculation: ICalculatePricesBasedOnArticleListResult) => {
  return {
    salesTotalExcludingVatAndTax: defaultTo(vatCalculation?.indicativePriceExclVatExclTax, 0),
    electrifiedCarVatCalculationBasis: defaultTo(vatCalculation?.vatLiablePortion, 0),
    calculatedVatOnDeductableItems: defaultTo(vatCalculation?.vatAfterDiscount, 0),
    calculatedVatOnNonDeductableItems: defaultTo(vatCalculation?.vatOnArticlesWithNonDeductibleVat, 0),
    oneOffTaxAndWreckage: defaultTo(vatCalculation?.totalTaxAmount, 0),
    salesTotalIncludingVatAndTax: defaultTo(vatCalculation?.totalPriceInclVatInclTaxInclDiscount, 0),
    calculatedSalesTotalBeforeDiscount: defaultTo(vatCalculation?.indicativePriceInclVatInclTax, 0),
    calculatedVatOnSalesTotal: defaultTo(vatCalculation?.vatTotal, 0),
    discount: Math.abs(defaultTo(vatCalculation?.discount, 0)),
    basisForMarginCalculation: defaultTo(vatCalculation?.basisForMarginCalculation, 0),
    sumPriceExclVatOfArticlesWithDeductibleVat: defaultTo(vatCalculation?.sumPriceExclVatOfArticlesWithDeductibleVat, 0),
    sumPriceExclVatOfArticlesWithNonDeductibleVat: defaultTo(vatCalculation?.sumPriceExclVatOfArticlesWithNonDeductibleVat, 0),
    oneOffTax:  defaultTo(vatCalculation?.oneOffTax, 0),
    sumPriceExclVatOfCarAndPackage:  defaultTo(vatCalculation?.sumPriceExclVatOfCarAndPackage, 0),
    sumPriceInclVatOfCarAndPackage:  defaultTo(vatCalculation?.sumPriceInclVatOfCarAndPackage, 0),
    sumPriceExclVatOfEquipmentAndAccessories:  defaultTo(vatCalculation?.sumPriceExclVatOfEquipmentAndAccessories, 0),
    sumPriceInclVatOfEquipmentAndAccessories:  defaultTo(vatCalculation?.sumPriceInclVatOfEquipmentAndAccessories, 0),
    scrapDeposit:  defaultTo(vatCalculation?.scrapDeposit, 0),
    indicativePriceExclVatInclTax:  defaultTo(vatCalculation?.indicativePriceExclVatInclTax, 0),
    totalPriceInclVatInclTaxInclDiscountInclServiceContract:  defaultTo(vatCalculation?.totalPriceInclVatInclTaxInclDiscountInclServiceContract, 0),
    marketingDiscount:  defaultTo(vatCalculation?.marketingDiscount, 0),
    customerAdvantage:  defaultTo(vatCalculation?.customerAdvantage, 0),
    customerAdvantagePercentage:  defaultTo(vatCalculation?.customerAdvantagePercentage, 0),
    vatBeforeDiscount:  defaultTo(vatCalculation?.vatBeforeDiscount, 0),
    vatLiablePortionPercentage:  defaultTo(vatCalculation?.vatLiablePortionPercentage, 0),
    vatFactor:  defaultTo(vatCalculation?.vatFactor, 0),
    costPrice:  defaultTo(vatCalculation?.costPrice, 0),
    calculatedImportContribution:  defaultTo(vatCalculation?.calculatedImportContribution, 0),
    marginAmount:  defaultTo(vatCalculation?.marginAmount, 0),
    marginPercentage: defaultTo(vatCalculation?.marginPercentage, 0),
  };
};

export const getVatCalculationInput = (prices: ICartPrices) => {
  const vatCalculation: IVatCalculation = {
    ...prices,
  };
  delete vatCalculation['discount'];
  return vatCalculation;
};

export const calculateVatAndCreateInput = (articles: INextArticle[], carType: string) => {
  const calculatedVat = calculateVatForElCars(articles, carType);
  if (!calculatedVat) return null;
  const mappedVatPrices = mapVatPricesForNext(calculatedVat);
  return getVatCalculationInput(mappedVatPrices);
};

export const calculateVatPriceFromVehicleList = async (
  options: IVehicleOptionInput | IVehicleOptionInput[],
  sanitizedEquipments,
  articles: INextArticle[],
  previousArticles: INextArticle[],
  isPicker = false,
  isVehicleChange = false,
  carType = '',
) => {
  let updatedArticles = prepareNextArticlesPayload(
    options,
    sanitizedEquipments,
    articles,
    previousArticles,
    isPicker,
    isVehicleChange,
  );

  updatedArticles = removeDuplicateObjectFromArray<INextArticle>([...updatedArticles], 'key');
  return calculateVatAndCreateInput(updatedArticles, carType);
};

export const prepareNextArticlesPayload = (
  options: IVehicleOptionInput | IVehicleOptionInput[],
  sanitizedEquipments,
  articles: INextArticle[],
  previousArticles: INextArticle[],
  isPicker = false,
  isVehicleChange = false,
) => {
  let sanitizedOptions: IVehicleOptionInput = null;
  let updatedArticles = [];
  const filteredArticles =
    articles?.filter(
      (article) =>
        article.key === VehicleConfig.MODEL ||
        article.key === VehicleConfig.VARIANT ||
        article.key === VehicleConfig.DELIVERY_CHARGE ||
        article.key === VehicleConfig.TYRE ||
        article?.key === VehicleConfig.DISCOUNT ||
        article?.key === VehicleConfig.CUSTOMERADVANTAGE ||
        article?.key === VehicleConfig.SCRAPDEPOSIT ||
        article?.key === VehicleConfig.TAREWEIGHT ||
        article?.key === VehicleConfig.CO2TAX ||
        article?.key === VehicleConfig.NOXTAX,
    ) ?? [];

  if (isPicker) {
    updatedArticles = [...filteredArticles];
  } else if (isVehicleChange) {
    updatedArticles = [...articles];
  } else {
    updatedArticles = [
      ...filteredArticles,
      ...(previousArticles?.filter(
        (article) =>
          article?.key === VehicleConfig.TYRE ||
          article?.key === VehicleConfig.DISCOUNT ||
          article?.key?.startsWith(VehicleConfig.ACCESSORY),
      ) ?? []),
    ];
  }
  if (Array.isArray(options)) {
    sanitizedOptions = options?.[0];
  } else {
    sanitizedOptions = options;
  }

  if (sanitizedOptions?.colorName) {
    if (isArticleContains(VehicleConfig.COLOR, updatedArticles)) {
      const priceUpdateArticles = updatePriceInArticle(
        VehicleConfig.COLOR,
        updatedArticles,
        sanitizedOptions?.colorPriceExcludingVat,
      );
      updatedArticles = priceUpdateArticles;
    } else {
      updatedArticles?.push(
        mapPayload(
          VehicleConfig.COLOR,
          sanitizedOptions?.colorPriceExcludingVat,
          sanitizedOptions?.colorVatCode,
          sanitizedOptions?.colorRetailPrice,
        ),
      );
    }
  }
  if (sanitizedOptions?.interiorName) {
    if (isArticleContains(VehicleConfig.INTERIOR, updatedArticles)) {
      const priceUpdateArticles = updatePriceInArticle(
        VehicleConfig.INTERIOR,
        updatedArticles,
        sanitizedOptions?.interiorPriceExcludingVat,
      );
      updatedArticles = priceUpdateArticles;
    } else {
      updatedArticles?.push(
        mapPayload(
          VehicleConfig.INTERIOR,
          sanitizedOptions?.interiorPriceExcludingVat,
          sanitizedOptions?.interiorVatCode,
          sanitizedOptions?.interiorRetailPrice,
        ),
      );
    }
  }
  if (sanitizedEquipments?.length) {
    remove(updatedArticles, function (article) {
      return (
        article?.key?.startsWith(`${VehicleConfig.SELECTABLE_EQUIPMENT}`) ||
        article?.key === VehicleConfig.RIM ||
        article?.key === VehicleEquipmentCategoryEnum.Listverk ||
        article?.key === VehicleEquipmentCategoryEnum.Taklakk
      );
    });
    sanitizedEquipments?.map(
      (equipment: {
        type: VehicleConfig;
        category: VehicleEquipmentCategoryEnum;
        pimId: any;
        priceExcludingVat: any;
        vatCode: any;
        retailPrice: number;
      }) => {
        let key = '';
        if (equipment?.type === (VehicleEquimentTypeEnum.Rims || VehicleConfig.RIM)) key = VehicleConfig.RIM;
        else if (equipment?.category === VehicleEquipmentCategoryEnum.Listverk)
          key = VehicleEquipmentCategoryEnum.Listverk;
        else if (equipment?.category === VehicleEquipmentCategoryEnum.Taklakk)
          key = VehicleEquipmentCategoryEnum.Taklakk;
        else key = `${VehicleConfig.SELECTABLE_EQUIPMENT}_${equipment?.pimId || ''}`;
        if (isArticleContains(key, updatedArticles)) {
          const priceUpdateArticles = updatePriceInArticle(key, updatedArticles, equipment?.priceExcludingVat);
          updatedArticles = priceUpdateArticles;
        } else {
          updatedArticles?.push(
            mapPayload(key, equipment?.priceExcludingVat, equipment?.vatCode, equipment?.retailPrice),
          );
        }
      },
    );
  }
  return updatedArticles;
};

const mapPayload = (key: string, priceExclVat: number, vatCode: string, dealerPriceExclVat: number) => ({
  key,
  priceExclVat,
  vatCode,
  articleType: ArticleType.EQUIPMENT,
  dealerPriceExclVat,
});

export const isArticleContains = (key: string, articles: INextArticle[], id: string = null) => {
  if (id) return articles?.findIndex((article) => article?.key === key && article?.id === id) >= 0;

  return articles?.findIndex((article) => article?.key === key) >= 0;
};

export const updatePriceInArticle = (key: string, articles: INextArticle[], price: number) => {
  return articles?.map((article) => {
    if (article?.key === key) article.priceExclVat = price || 0;
    return article;
  });
};

export const isVariantArticleExists = (articles) => {
  return articles?.findIndex((article) => article?.key === VehicleConfig.VARIANT) >= 0;
};

/* Only to start the article creation process if the specified fields are available */
export const isArticleBuild = (initialArticles, isAccessoryAvailable) => {
  const isVariantSetInArticle = isVariantArticleExists(initialArticles);
  if (isVariantSetInArticle) {
    if (isAccessoryAvailable) {
      const accessory = initialArticles?.find((article) => {
        const key = article?.key?.split('_')?.[0];
        return key === VehicleConfig.TYRE || key === VehicleConfig.ACCESSORY;
      });
      if (!accessory) return false;
    }
    return true;
  }
  return false;
};

export const getColorInteriorCode = (order: IOrder) => {
  let varnishCode = null;
  let interiorCode = null;
  if (order?.vehicles?.[0]?.serialNo === '') {
    const factoryDetail = order?.vehicles?.[0]?.orderFromFactoryDetails;
    varnishCode = getVarnishCodeFromFactory(factoryDetail);
    interiorCode = getInteriorCodeFromFactory(factoryDetail);
  } else {
    varnishCode = getVarnishCodeFromOptions(order?.options);
    interiorCode = order?.options?.[0]?.packageInteriorId || getInteriorCodeFromOptions(order?.options);
  }
  return {
    varnishCode: varnishCode || null,
    interiorCode: interiorCode || null,
  };
};

export const isColorAvailableInArticle = (order: IOrder, currentColorId: string, articles: INextArticle[]) => {
  const { varnishCode } = getColorInteriorCode(order);
  if (!varnishCode) return !isArticleContains(VehicleConfig.COLOR, articles);
  if (currentColorId === varnishCode) {
    if (varnishCode) return isArticleContains(VehicleConfig.COLOR, articles, varnishCode);
    return true;
  }
  return false;
};

export const isInteriorAvailableInArticle = (order: IOrder, currentInteriorId: string, articles: INextArticle[]) => {
  const { interiorCode } = getColorInteriorCode(order);
  if (!interiorCode) return !isArticleContains(VehicleConfig.INTERIOR, articles);
  if (currentInteriorId === interiorCode) {
    if (interiorCode) return isArticleContains(VehicleConfig.INTERIOR, articles, interiorCode);
    return true;
  }
  return false;
};

export const isVariantAvailableInArticle = (variantId: string, articles: INextArticle[]) => {
  if (!variantId) return false;
  return isArticleContains(VehicleConfig.VARIANT, articles, variantId);
};

export const calculateVatForElCars = (
  articles: INextArticle[],
  carType: string,
): ICalculatePricesBasedOnArticleListResult => {
  const vehicleApplicationCode = mapVehicleApplicationCodeStringToEnum(carType || '');
  try {
    const result = calculatePricesBasedOnArticleList({ articles, vehicleApplicationCode });
    // Logs for VAT Calculation input/output
    console.log('\u001b[35m NPM Package: sales-common-calculations');
    console.log('\u001b[35m Function: calculatePricesBasedOnArticleList');
    console.log(
      ' Articles payload (Request Object) : ',
      articles?.map((article) => {
        return {
          priceExclVat: article?.priceExclVat,
          vatCode: article.vatCode,
          articleType: article?.articleType,
          dealerPriceExclVat: article?.dealerPriceExclVat,
        };
      }),
    );
    console.log(' Response: ', result);

    return result;
  } catch (error) {
    console.error('calculateVatForElCars func error:', error);
    return null;
  }
};

export const calculateVat = (articles: INextArticle[], carType: string): ICartPrices => {
  try {
    const vatCalculation = calculateVatForElCars(articles, carType);
    if (!vatCalculation) return null;
    return mapVatPricesForNext(vatCalculation);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getManufactureModelId = (
  vehicleData: IVehicleModel[],
  selectedModelId: string,
  variantId: string,
): string => {
  const model = vehicleData?.find((model) => selectedModelId === model?.id) ?? null;
  const salesPackage = model?.salesPackages?.find((salesPackage) => variantId === salesPackage?.id) ?? null;

  return salesPackage?.manufacturerComponentIdModel ?? null;
};

export const getCarTypeOfSelectedModel = (
  modelData: IVehicleModel[],
  selectedModelId: string,
): string => {
  const model = modelData?.find((model) => selectedModelId === model?.id) ?? null;
  return model?.vehicleType || null;
};
