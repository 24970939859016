import React, { useEffect, useState } from 'react';
import {
  CustomerType,
  FinancingType,
  ICalculation,
  IVehicleConfiguration,
  IOrder,
  IServiceContract,
  INextArticle,
} from 'next-common';
import { StyledInlineDialog } from './FinancingDialog.styled';
import { CustomModal } from '@next-components/custom-modal';
import { useMutation } from '@apollo/client';
import { ICentralisedProperties, updateCalculationFinancingMutationParsed } from '../../../../common';
import { createOrUpdateServiceContract, updateCalculation, updateServiceContractForLeasing } from '../../../../utils';
import { fieldIds, FinancingForm } from './FinancingForm';
import { SuccessState } from './FinancingState';
import { FinancingTabSection } from './FinancingTabSection';
import {
  updateServiceContractMutation,
  createServiceContractMutation,
  removeServiceContractMutation,
} from '../../../../graphql/backend/mutations';
import { IGetServiceContract } from 'sales-common';
import { CustomModalWrapper } from '../../../layout/CustomModalWrapper.styled';

export interface IFinancingDialogProps extends ICentralisedProperties {
  loading?: boolean;
  offerId?: string;
  calculation: ICalculation;
  className?: string;
  customerType: CustomerType;
  financingType?: FinancingType;
  isEditable?: boolean;
  isModal?: boolean;
  order?: IOrder;
  orderId: string;
  success?: boolean;
  title?: string;
  unknownText?: string;
  vehicleConfig: IVehicleConfiguration;
  serviceContract?: IGetServiceContract;
  serviceContractDb?: IServiceContract;
  isSigningStep?: boolean;
  addNew?: boolean;
  isDeleted?: boolean;
  isUnavailablePimData?: boolean;
  onUpdateCalculation?(changes: any, calculation: ICalculation): void;
  onCalculationUpdated?(calculation: ICalculation): Promise<any>;
  onServiceContractUpdated?(serviceContract: IServiceContract): Promise<any>;
  setUpdatedOrder?(order: IOrder): void;
  setAddNew?(addNew: boolean): void;
  onAddCalculation?(financingType: FinancingType, changes?: any);
  onDeleteCalculation?(calculation: ICalculation): void;
  articles?: INextArticle[];
  isStockSaleOrder?: boolean;
}

const pascalCase = (s: string) => `${s[0].toUpperCase()}${s.substring(1)}`;

export const FinancingDialog = (props: IFinancingDialogProps) => {
  const [calculation, setCalculation] = useState<ICalculation>(props.calculation || null);
  const [currentServiceContract, setCurrentServiceContract] = useState<IServiceContract>(props.serviceContractDb);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [updateCalculationFinancing] = useMutation(updateCalculationFinancingMutationParsed);
  const [createServiceContract] = useMutation(createServiceContractMutation);
  const [updateServiceContract] = useMutation(updateServiceContractMutation);
  const [removeServiceContract] = useMutation(removeServiceContractMutation);
  const [financingType, setFinancingType] = useState<FinancingType>(props.financingType);

  useEffect(() => {
    setCalculation(props.calculation);
    setCurrentServiceContract(props.serviceContractDb);
  }, [props.calculation, props.serviceContractDb]);

  useEffect(() => {
    setFinancingType(props.financingType);
  }, [props.financingType]);

  const onUpdateCalculation = async (changes) => {
    setIsSaving(true);
    
    try {
      if (typeof props.onUpdateCalculation === 'function') {
        await props.onUpdateCalculation(changes, props.calculation);
      } else {
        const updatedCalculation = await updateCalculation(
          changes,
          props.calculation,
          updateCalculationFinancing,
          props.order.customer.customerType,
          props.orderId,
          financingType,
          props.articles
        );
        if (typeof props.onCalculationUpdated === 'function') {
          await props.onCalculationUpdated(updatedCalculation);
        }
      }

      if (typeof props.setAddNew === 'function') {
        props.setAddNew(false);
      }
      const isLeasing = financingType === FinancingType.LEASING;
      const isLeasingUpdate = changes.some(
        (change) => change.fieldName === fieldIds.durationMonths || change.fieldName === fieldIds.mileage,
      );
      if (isLeasingUpdate && !!currentServiceContract && !!props.serviceContract && isLeasing) {
        await serviceContractLeasingUpdate(changes);
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('Could not update calculation ID ', props.calculation?.id);
    }
    setIsSaving(false);
  };

  const handleAddComment = async (calculation: ICalculation) => {
    if (typeof props.onCalculationUpdated === 'function') {
      await props.onCalculationUpdated(calculation);
    }
  };

  const onUpdateServiceContract = async (changes) => {
    setIsSaving(true);
    try {
      const serviceContractUpdate = await createOrUpdateServiceContract(
        changes,
        calculation,
        props.serviceContract,
        createServiceContract,
        updateServiceContract,
        removeServiceContract,
        props.order,
        props.articles
      );

      if (typeof props.onServiceContractUpdated === 'function') {
        await props.onServiceContractUpdated(serviceContractUpdate);
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('Could not update service contract ID ', props.calculation.id);
    }
    setIsSaving(false);
  };

  const serviceContractLeasingUpdate = async (changes) => {
    const serviceContractUpdate = await updateServiceContractForLeasing(
      changes,
      calculation,
      props.serviceContract,
      currentServiceContract,
      updateServiceContract,
      removeServiceContract,
      props.order,
      props.articles
    );
    if (typeof props.onServiceContractUpdated === 'function') {
      await props.onServiceContractUpdated(serviceContractUpdate);
    }
  };

  const getLoanText = () => {
    if (isLoan) {
      return 'låne';
    } else {
      return 'leasing';
    }
  };

  const isLoan = financingType === FinancingType.LOAN;
  const isCash = financingType === FinancingType.CASH;
  const type = isCash ? 'egenfinansiering' : `${getLoanText()}forslag`;
  const dialogTitle = props.title ? props.title : pascalCase(type);
  if (props.isModal) {
    return (
      <CustomModalWrapper className="backdrop financing-modal smModal width46">
        <CustomModal onCloseClick={() => {}} active={props.isModal} isModelCloseAllowedOutside={false}>
          <header>
            <h2>{props.title}</h2>
          </header>
          <FinancingForm
            onUpdateCalculation={onUpdateCalculation}
            onAddComment={handleAddComment}
            calculation={props.calculation}
            type={financingType}
            customerType={props.customerType}
            vehicleConfig={props.vehicleConfig}
            isSaving={isSaving}
            isEditable={props.isEditable}
            isModal
            hideComments
            order={props.order}
            configuration={props?.configuration}
            isStockSaleOrder={props.isStockSaleOrder}
          />
        </CustomModal>
      </CustomModalWrapper>
    );
  }

  if (props.success) {
    return <SuccessState financingType={props.financingType} />;
  }
  return (
    <>
      <StyledInlineDialog
        className={props?.calculation?.financingType === FinancingType.LOAN && 'loan-form-container'}
        withBorder={props.isEditable}>
        <FinancingTabSection
          loading={props?.loading}
          offerId={props?.offerId}
          dialogTitle={dialogTitle}
          onUpdateCalculation={onUpdateCalculation}
          onUpdateServiceContract={onUpdateServiceContract}
          calculation={calculation}
          serviceContractDb={props?.serviceContractDb}
          serviceContract={props.serviceContract}
          vehicleConfig={props.vehicleConfig}
          type={financingType}
          customerType={props.customerType}
          isSaving={isSaving}
          isEditable={props.isEditable}
          isModal={false}
          hideComments={isCash}
          onAddComment={handleAddComment}
          order={props.order}
          isSigningStep={props.isSigningStep}
          addNew={props.addNew}
          setAddNew={props?.setAddNew}
          onAddCalculation={props.onAddCalculation}
          isDeleted={props.isDeleted}
          configuration={props?.configuration}
          onDeleteCalculation={props?.onDeleteCalculation}
          isUnavailablePimData={props.isUnavailablePimData}
          isStockSaleOrder={props.isStockSaleOrder}
        />
      </StyledInlineDialog>
      {/* {!isCash && <LatestCommentPane orderId={props.orderId} comments={calculation?.comments} type="finance" />} */}
    </>
  );
};

FinancingDialog.defaultProps = {
  isModal: true,
  isEditable: true,
  unknownText: 'ukjent',
};
