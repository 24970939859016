import { INextArticle, getUniqueItemsByKey } from 'next-common';
import { IDropdownOption } from '@next-components/common-types';
import { serviceContractDefault } from '../sales';
import { ArticleType } from 'sales-common';

export const getServiceContractOptions = (contractTypeList: any[], selected): IDropdownOption<number>[] => {
  return getUniqueItemsByKey(contractTypeList, 'name').map((item) => ({
    isSelected: item.id === selected,
    label: `${item.name}`,
    value: item.id,
  }));
};

export const getServiceContractDurationOptions = (
  yearList: any[],
  selectedValue: number,
): IDropdownOption<string>[] => {
  return yearList.map((item) => ({
    isSelected: item === selectedValue / 12,
    label: `${item} år`,
    value: item,
  }));
};

export const getServiceContractDistanceOptions = (
  kiloMeterList: any[],
  selectedValue: number,
): IDropdownOption<string>[] => {
  return kiloMeterList.map((item) => ({
    isSelected: item === selectedValue,
    label: `${item} km`,
    value: item,
  }));
};

export const getCarTypeOptions = (usageFactorDataList: any[], selected: string): IDropdownOption<string>[] => {
  const selectedType = selected !== '' ? selected : serviceContractDefault.usageType;
  return usageFactorDataList.map((item) => ({
    isSelected: item.name === selectedType,
    label: `${item.name}`,
    value: item.id,
  }));
};

export const getPaymentPeriodDataListOptions = (
  paymentPeriodDataList: any[],
  selected: string,
): IDropdownOption<string>[] => {
  return paymentPeriodDataList?.map((item) => ({
    isSelected: item.name === selected,
    label: `${item.name}`,
    value: item.id,
  }));
};
