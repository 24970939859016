import {
  CustomerType,
  FinancingType,
  ICalculation,
  IFinancingRequest,
  INextArticle,
  IOrder,
  IServiceContract,
} from 'next-common';
import { IContractTypeList, IGetServiceContract } from 'sales-common';
import { IFormChange } from '../../components/sales/sales-common/FinancingDialog/FinancingForm';
import { getUsageType } from '../../components/sales/sales-common/FinancingDialog/ServiceAgreementForm';
import { IServiceContractCalculation, IServiceContractCreationStatus, IServiceContractPrice } from '../serviceContract';

export const serviceContractDefault = {
  duration: 36,
  drivingDistance: 15000,
  usageType: 'Normal',
};

export const getPaymentPeriodId = (serviceContract: IGetServiceContract) =>
  serviceContract?.paymentPeriodDataList?.filter((payment) => payment?.month === 1)?.[0];

export const getServiceContractPrice = (
  serviceContract: IGetServiceContract,
  calculation: ICalculation,
  contractTypeId: number,
  duration: number,
  distance: number,
  usageTypeId: number,
  isBusiness: boolean,
) => {
  if (!serviceContract) return null;
  const usageTypeID = !usageTypeId ? getUsageType(serviceContract, calculation?.serviceContract)?.id : usageTypeId;
  const priceInfo = serviceContract?.priceTableForSalesList?.filter((item) => {
    if (isBusiness && !!usageTypeID) {
      if (
        item?.contractTypeId === contractTypeId &&
        item?.months === duration &&
        item?.km === distance &&
        item?.usageFactorId === usageTypeID
      ) {
        return item;
      }
    } else if (item?.contractTypeId === contractTypeId && item?.months === duration && item?.km === distance) {
      return item;
    }
  });

  if (priceInfo?.length) {
    const price = { ...priceInfo?.[0] };
    delete price?.id;
    delete price?.payerShare;
    delete price?.subsidiaryPayersDetails;
    return price;
  }
  return null;
};

export const formateField = (fieldValue: number): number => Number(fieldValue?.toFixed(2));

export const getMonthlyPrice = (
  serviceContractPrice: IServiceContractPrice,
  dealerAmount: number,
  totalPrice: number,
) => {
  if (dealerAmount === 0) {
    return serviceContractPrice?.monthlyPriceIncVat;
  }
  return totalPrice / serviceContractPrice?.months;
};

export const getServiceContractCalculation = (
  serviceContractPrice: IServiceContractPrice,
  dealerAmount = 0,
): IServiceContractCalculation => {
  if (!serviceContractPrice) return null;
  const vatAmount = 0.25;
  const priceIncVat: number =
    (serviceContractPrice?.priceExcVat || 0) + (serviceContractPrice?.priceExcVat || 0) * vatAmount;
  const importerDiscount =
    (serviceContractPrice?.payerDiscount || 0) + (serviceContractPrice?.payerDiscount || 0) * vatAmount;
  const workshopDiscount = (priceIncVat * (serviceContractPrice?.rebatePercentage || 0)) / 100;
  const totalSavings = importerDiscount + workshopDiscount + dealerAmount;
  const totalPrice = priceIncVat - totalSavings;
  const importerDiscountPercentage = (importerDiscount / totalPrice) * 100;
  const workshopDiscountPercentage = (workshopDiscount / totalPrice) * 100;
  const rebatePercentage = (totalSavings / totalPrice) * 100;
  const monthlyPrice = getMonthlyPrice(serviceContractPrice, dealerAmount, totalPrice);
  return {
    dealerAmount: formateField(dealerAmount),
    totalPrice: formateField(totalPrice),
    importerDiscount: formateField(importerDiscount),
    workshopDiscount: formateField(workshopDiscount),
    totalSavings: formateField(totalSavings),
    importerDiscountPercentage: formateField(importerDiscountPercentage),
    workshopDiscountPercentage: formateField(workshopDiscountPercentage),
    rebatePercentage: formateField(rebatePercentage),
    monthlyPrice: formateField(monthlyPrice),
  };
};

export const serviceContractInputFieldMapper = (
  serviceContractType: IContractTypeList,
  calculation: ICalculation,
  serviceContractCalculation: Partial<IServiceContract>,
  serviceContractPrice: IServiceContractPrice,
  isBusiness: boolean,
  darsServiceContract: IGetServiceContract = null,
  { isCreate, isUpdate }: IServiceContractCreationStatus = { isCreate: false, isUpdate: false },
) => {
  const isLeasing = calculation?.financingType === FinancingType.LEASING;
  const usageType = isBusiness
    ? getUsageType(darsServiceContract, calculation?.serviceContract, serviceContractCalculation?.usageType)
    : null;
  const request = calculation?.financing?.request;
  if (isLeasing && isCreate && !isUpdate) {
    serviceContractPrice = getServiceContractPrice(
      darsServiceContract,
      calculation,
      serviceContractType?.id,
      request?.durationMonths,
      request?.mileage,
      usageType?.id,
      isBusiness,
    );
    serviceContractCalculation = getServiceContractCalculation(
      serviceContractPrice,
      serviceContractCalculation?.dealerAmount,
    );
  }

  if (!serviceContractPrice && isCreate) return null;

  serviceContractCalculation.usageType = usageType?.name;
  const paymentPeriodId = serviceContractCalculation?.paymentPeriodId ?? getPaymentPeriodId(darsServiceContract)?.id;
  const paymentPeriodMonth =
    serviceContractCalculation?.paymentPeriodMonth ?? getPaymentPeriodId(darsServiceContract)?.month;
  const isLoanPaymentIntervalSelected = serviceContractCalculation?.isLoanPaymentIntervalSelected ?? false;
  const paymentPeriod = darsServiceContract?.paymentPeriodDataList?.find((period) => period.month === 12) || null;

  return {
    type: serviceContractType.name,
    totalPrice: serviceContractCalculation?.totalPrice,
    totalSavings: serviceContractCalculation?.totalSavings,
    monthlyPrice: serviceContractCalculation?.monthlyPrice,
    drivingDistance: isCreate && isLeasing ? request?.mileage : serviceContractPrice?.km,
    duration: isCreate && isLeasing ? request?.durationMonths : serviceContractPrice?.months,
    rebatePercentage: serviceContractCalculation?.rebatePercentage,
    contractTypeId: serviceContractType.id,
    dealerAmount: serviceContractCalculation?.dealerAmount,
    importerDiscount: serviceContractCalculation?.importerDiscount,
    workshopDiscount: serviceContractCalculation?.workshopDiscount,
    paymentPeriodId: isLeasing ? paymentPeriod?.id : paymentPeriodId,
    paymentPeriodMonth: isLeasing ? paymentPeriod?.month : paymentPeriodMonth,
    isLoanPaymentIntervalSelected,
    usageType: serviceContractCalculation?.usageType,
    serviceContractPriceConfig: serviceContractPrice,
  };
};

export const mapServiceContractInput = (
  serviceContractType: IContractTypeList,
  offers: ICalculation[],
  serviceContractCalculation: IServiceContractCalculation | Partial<IServiceContract>,
  serviceContractPrice: IServiceContractPrice,
  darsServiceContract: IGetServiceContract,
  isBusiness: boolean,
) => {
  if (!serviceContractType || !offers || !serviceContractPrice) return null;
  return offers
    ?.map((calculation) => {
      if (calculation?.financing) {
        return {
          calculationId: calculation?.id,
          serviceContract: serviceContractInputFieldMapper(
            serviceContractType,
            calculation,
            serviceContractCalculation,
            serviceContractPrice,
            isBusiness,
            darsServiceContract,
            { isCreate: true, isUpdate: false },
          ),
        };
      }
    })
    ?.filter((input) => !!input?.serviceContract);
};

export const mapServiceContractUpdateInput = (
  offers: ICalculation[],
  isBusiness: boolean,
  darsServiceContract: IGetServiceContract,
  dealerAmount = 0,
) => {
  if (!offers) return null;
  return offers.map((calculation) => {
    const { serviceContract } = calculation;
    const contractTypeConfig = {
      id: serviceContract?.contractTypeId,
      name: serviceContract?.type,
    };
    const serviceContractCalculation = getServiceContractCalculation(
      serviceContract?.serviceContractPriceConfig,
      dealerAmount,
    );
    const customServiceContractCalculation = {
      isLoanPaymentIntervalSelected: calculation?.serviceContract?.isLoanPaymentIntervalSelected,
      paymentPeriodId: calculation?.serviceContract?.paymentPeriodId,
      paymentPeriodMonth: calculation?.serviceContract?.paymentPeriodMonth,
      ...serviceContractCalculation,
    };
    return {
      serviceContractId: calculation?.serviceContract?.id,
      serviceContract: serviceContractInputFieldMapper(
        contractTypeConfig,
        calculation,
        customServiceContractCalculation,
        null,
        isBusiness,
        darsServiceContract,
        { isCreate: false, isUpdate: true },
      ),
    };
  });
};

const prepareServiceContractInput = (
  darsServiceContract: IGetServiceContract,
  calculation: ICalculation,
  input: Partial<IServiceContract>,
): Partial<IServiceContract> => {
  if (input?.isLoanPaymentIntervalSelected) {
    const paymentPeriodData = darsServiceContract?.paymentPeriodDataList?.filter(
      (payment) => payment?.name === 'Full',
    )?.[0];
    input.paymentPeriodId = paymentPeriodData?.id;
    input.paymentPeriodMonth = paymentPeriodData?.month;
  }
  if (
    !input?.isLoanPaymentIntervalSelected &&
    (calculation?.financingType === FinancingType.LOAN || calculation?.financingType === FinancingType.CASH)
  ) {
    if (input?.paymentPeriodId) {
      const paymentPeriodData = darsServiceContract?.paymentPeriodDataList.filter(
        (payment) => payment?.id === input?.paymentPeriodId,
      )?.[0];
      input.paymentPeriodMonth = paymentPeriodData?.month;
      (calculation?.financingType === FinancingType.LOAN || calculation?.financingType === FinancingType.CASH) &&
      paymentPeriodData?.name === 'Full'
        ? (input.isLoanPaymentIntervalSelected = true)
        : (input.isLoanPaymentIntervalSelected = false);
    } else {
      const paymentPeriodData = darsServiceContract?.paymentPeriodDataList?.filter((payment) => payment?.id === 1)?.[0];
      input.paymentPeriodId = paymentPeriodData?.id;
      input.paymentPeriodMonth = paymentPeriodData?.month;
    }
  }
  return input;
};

export const createOrUpdateServiceContract = async (
  changes: IFormChange[],
  calculation: ICalculation,
  darsServiceContract: IGetServiceContract,
  createServiceContract,
  updateServiceContract,
  removeServiceContract,
  order: IOrder = null,
  articles?: INextArticle[],
): Promise<IServiceContract> => {
  let input: Partial<IServiceContract> = changes?.reduce(
    (payload, change) => ({
      ...payload,
      [change?.fieldName]: change?.value,
    }),
    {},
  );

  const isBusiness = order?.customer?.bosCustomer?.customerType === CustomerType[CustomerType.BUSINESS];
  /* logic for adding paymentPeriodMonth & Id with LOAN/CASH */
  input = prepareServiceContractInput(darsServiceContract, calculation, input);

  try {
    if (!!calculation?.serviceContract?.id && input?.contractTypeId !== 0) {
      let serviceContractInput = [];
      const offersWithServiceContract = order?.offers?.filter(
        (offer) =>
          !!offer?.serviceContract &&
          !!offer?.financing &&
          offer?.financingType === calculation?.financingType &&
          offer?.serviceContract?.id !== calculation?.serviceContract?.id,
      );
      if (input.dealerAmount >= 0) {
        serviceContractInput = mapServiceContractUpdateInput(
          offersWithServiceContract,
          isBusiness,
          darsServiceContract,
          input.dealerAmount,
        );
      }
      const currentCalculation = {
        serviceContractId: calculation?.serviceContract?.id,
        serviceContract: input,
      };
      serviceContractInput = [...serviceContractInput, currentCalculation];
      const variables = {
        orderId: order?.id,
        input: serviceContractInput,
        articles,
      };
      const { data } = await updateServiceContract({ variables });
      return data?.updateServiceContract;
    }
    if (!!calculation?.serviceContract?.id && input?.contractTypeId === 0) {
      const { data } = await removeServiceContract({
        variables: { orderId: order?.id, id: calculation?.serviceContract?.id, articles },
      });
      return data?.removeServiceContract;
    }
    const serviceContractType = {
      id: input?.contractTypeId,
      name: input?.type,
    };
    const serviceContractPrice = input?.serviceContractPriceConfig;
    const offersWithoutServiceContract = order?.offers?.filter(
      (offer) => !offer?.serviceContract && offer?.financingType === calculation?.financingType && !!offer?.financing,
    );
    if (!input?.dealerAmount) {
      input.dealerAmount = 0;
    }

    const variables = {
      orderId: order?.id,
      input: [
        ...mapServiceContractInput(
          serviceContractType,
          offersWithoutServiceContract,
          input,
          serviceContractPrice,
          darsServiceContract,
          isBusiness,
        ),
      ],
      articles,
    };
    const { data } = await createServiceContract({ variables });
    return data?.createServiceContract;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};

export const updateServiceContractForLeasing = async (
  changes: IFormChange[],
  calculation: ICalculation,
  serviceContract: IGetServiceContract,
  serviceContractDb: IServiceContract,
  updateServiceContract,
  removeServiceContract,
  order: IOrder,
  articles?: INextArticle[],
) => {
  const inputChange: Partial<IFinancingRequest> = changes?.reduce(
    (payload, change) => ({
      ...payload,
      [change?.fieldName]: change?.value,
    }),
    {},
  );

  const durationMonths = inputChange?.durationMonths
    ? inputChange?.durationMonths
    : calculation.financing?.request?.durationMonths;
  const mileage = inputChange?.mileage ? inputChange?.mileage : calculation.financing?.request?.mileage;
  const isBusiness = order?.customer?.bosCustomer?.customerType === CustomerType[CustomerType.BUSINESS];
  const serviceContractPrice = getServiceContractPrice(
    serviceContract,
    calculation,
    serviceContractDb?.contractTypeId,
    durationMonths,
    mileage,
    null,
    isBusiness,
  );

  if (!serviceContractPrice) {
    const { data } = await removeServiceContract({
      variables: { orderId: order.id, id: calculation?.serviceContract?.id, articles },
    });
    return data?.removeServiceContract;
  }
  const serviceContractCalculation = getServiceContractCalculation(
    serviceContractPrice,
    serviceContractDb?.dealerAmount,
  );
  const serviceContractConfig = {
    id: serviceContractDb?.contractTypeId,
    name: serviceContractDb?.type,
  };
  const input = serviceContractInputFieldMapper(
    serviceContractConfig,
    calculation,
    serviceContractCalculation,
    serviceContractPrice,
    isBusiness,
    serviceContract,
  );

  delete input.serviceContractPriceConfig.id;
  const serviceContractInput = {
    serviceContractId: calculation?.serviceContract?.id,
    serviceContract: input,
  };
  const variables = {
    orderId: order.id,
    input: [serviceContractInput],
    articles,
  };

  try {
    const { data } = await updateServiceContract({ variables });
    return data.updateServiceContract;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.log(error);
  }
};
export const isSCIncluded = (serviceContract: IServiceContract) =>
  serviceContract?.isLoanPaymentIntervalSelected === true;
