import { gql } from '@apollo/client';
import {
  addChosenOfferMutation,
  addNewCalculationToOrderMutation,
  addVehiclesToOrderMutation,
  cancelOrderMutation,
  createCustomerMutation,
  getNBFContractMutation,
  pauseOrderMutation,
  removeCalculationFromOrderMutation,
  removeTradeInMutation,
  removeVehiclesFromOrderMutation,
  resumeOrderMutation,
  saveInsuranceMutation,
  saveTradeInMutation,
  sendReserveVehicleOrderMutation,
  signOrderMutation,
  updateCalculationFinancingMutation,
  updateCalculationMutation,
  updateCustomerInfoMutation,
  saveCustomerMutation,
  updateOrderDiscountMutation,
  updateOrderMutation,
  updateReservedVehicleStockStatusBySerialNumberMutation,
  updateVehicleMutation,
  vehicleOrderDraftMutation,
  createExistingOrderMutation,
  updateOrderDraftMutation,
  sendSigningJobMutation,
  updatePrerequisiteToOrderMutation,
  saveServiceContractFlagToOrderMutation,
  updateModelMutation,
  updateOrderForEcomMutation,
  createExistingOrderWithVehicleMutation,
  createExistingOrderWithVehicleAndVehicleEquipmentMutation,
  addVehiclesToOrderAndVehicleEquipmentMutation,
  createMinimalOrderMutation,
  saveOfferExpiryDateToOrderMutation,
  nextErrorFragment,
  orderFragments,
  sendCancelConfirmOrderMutation,
  sendOrderMutation,
} from 'next-common';

export const createExistingOrderMutationParsed = gql`
  ${createExistingOrderMutation}
`;

export const createExistingOrderWithVehicleMutationParsed = gql`
  ${createExistingOrderWithVehicleMutation}
`;

export const createExistingOrderWithVehicleAndVehicleEquipmentMutationParsed = gql`
  ${createExistingOrderWithVehicleAndVehicleEquipmentMutation}
`;

export const updateOrderMutationParsed = gql`
  ${updateOrderMutation}
`;

export const updateOrderDraftMutationParsed = gql`
  ${updateOrderDraftMutation}
`;

export const updateOrderDiscountMutationParsed = gql`
  ${updateOrderDiscountMutation}
`;

export const updateCalculationMutationParsed = gql`
  ${updateCalculationMutation}
`;

export const updateCalculationFinancingMutationParsed = gql`
  ${updateCalculationFinancingMutation}
`;

export const addNewCalculationToOrderParsed = gql`
  ${addNewCalculationToOrderMutation}
`;

export const removeCalculationFromOrderMutationParsed = gql`
  ${removeCalculationFromOrderMutation}
`;

export const addVehiclesToOrderMutationParsed = gql`
  ${addVehiclesToOrderMutation}
`;

export const addVehiclesToOrderAndVehicleEquipmentMutationParsed = gql`
  ${addVehiclesToOrderAndVehicleEquipmentMutation}
`;

export const removeVehiclesFromOrderMutationParsed = gql`
  ${removeVehiclesFromOrderMutation}
`;

export const updateVehicleMutationParsed = gql`
  ${updateVehicleMutation}
`;

export const pauseOrderMutationParsed = gql`
  ${pauseOrderMutation}
`;

export const cancelOrderMutationParsed = gql`
  ${cancelOrderMutation}
`;

export const resumeOrderMutationParsed = gql`
  ${resumeOrderMutation}
`;

export const addChosenOfferMutationParsed = gql`
  ${addChosenOfferMutation}
`;
//Using this query from here as there are mismatch in next-common version in next-frontend and next-backend-db
//Will update this once all version are same
const toggleVehicleAccessoriesOnOrderMutation = `
  mutation toggleVehicleAccessoriesOnOrder(
    $orderId: ID!
    $addAccessories: [VehicleAccessoryInput!]
    $removeAccessories: [String!]
    $vatCalculation: VatCalculationInput
    $nextArticles: [NextArticleInput!]
  ) {
    toggleVehicleAccessoriesOnOrder(
      orderId: $orderId
      addAccessories: $addAccessories
      removeAccessories: $removeAccessories
      vatCalculation: $vatCalculation
      nextArticles: $nextArticles
    ) {
    ...order
    ...nextError
  }
}
  ${orderFragments}
  ${nextErrorFragment}
`;

export const toggleVehicleAccessoriesOnOrderMutationParsed = gql`
  ${toggleVehicleAccessoriesOnOrderMutation}
`;

export const createVehicleOrderDraftMutationParsed = gql`
  ${vehicleOrderDraftMutation}
`;

export const signOrderParsed = gql`
  ${signOrderMutation}
`;

export const sendSigningJobMutationParsed = gql`
  ${sendSigningJobMutation}
`;

export const saveInsuranceParsed = gql`
  ${saveInsuranceMutation}
`;

export const sendOrderMutationParsed = gql`
  ${sendOrderMutation}
`;

//Using this query from here as there are mismatch in next-common version in next-frontend and next-backend-db
//Will update this once all version are same
const createAndAddMarginToOrderMutation = `
  mutation createAndAddMarginToOrder($orderId: String!, $input: MarginInput!, $vatCalculation: VatCalculationInput, $nextArticles: [NextArticleInput!]) {
    createAndAddMarginToOrder(orderId: $orderId, input: $input, vatCalculation: $vatCalculation, nextArticles: $nextArticles) {
      ...order
    }
  }
  ${orderFragments}
`;

export const createAndAddMarginToOrderMutationParsed = gql`
  ${createAndAddMarginToOrderMutation}
`;

export const saveTradeInMutationParsed = gql`
  ${saveTradeInMutation}
`;

export const removeTradeInMutationParsed = gql`
  ${removeTradeInMutation}
`;

export const createCustomerMutationParsed = gql`
  ${createCustomerMutation}
`;

export const saveCustomerMutationParsed = gql`
  ${saveCustomerMutation}
`;

export const updateCustomerInfoParsed = gql`
  ${updateCustomerInfoMutation}
`;

export const getNBFContractMutationParsed = gql`
  ${getNBFContractMutation}
`;

export const saveOfferExpiryDateToOrderMutationParsed = gql`
  ${saveOfferExpiryDateToOrderMutation}
`;

export const sendReserveVehicleOrderMutationParsed = gql`
  ${sendReserveVehicleOrderMutation}
`;

export const sendCancelConfirmOrderMutationParsed = gql`
  ${sendCancelConfirmOrderMutation}
`;

export const updateReservedVehicleStockStatusBySerialNumberMutationParsed = gql`
  ${updateReservedVehicleStockStatusBySerialNumberMutation}
`;

export const updateOrderPrerequisiteParsed = gql`
  ${updatePrerequisiteToOrderMutation}
`;

export const saveServiceContractFlagToOrderMutationParsed = gql`
  ${saveServiceContractFlagToOrderMutation}
`;

export const updateModelMutationParsed = gql`
  ${updateModelMutation}
`;

export const updateOrderForEcomMutationParsed = gql`
  ${updateOrderForEcomMutation}
`;

export const createMinimalOrderMutationParsed = gql`
  ${createMinimalOrderMutation}
`;
