import React, { Component } from 'react';
import styles from './Error404.module.scss';
import { Routes } from '../../../common/constants';

export default class Error404 extends Component<{}, {}> {
  render(): JSX.Element {
    return (
      <div className={styles.errorPage}>
        <div className="menu-container no-border">&nbsp;</div>
        <div className="content-container">
          <div className={styles.header}>
            <h2 className={styles.underlined}>Oopsy daisy!</h2>
          </div>

          <div className="generic-container generic-container-centered">
            <p>Siden du forsøker å nå finnes ikke, men frykt ei, det finnes en knapp for alt.</p>
            <br />
            <a href={Routes.getHomePage()} className={styles.pillButton}>
              Kjør meg til forsiden
            </a>
          </div>
        </div>
      </div>
    );
  }
}
