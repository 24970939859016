import {
  getLatestMargin,
  IInterior,
  IOrder,
  IVarnish,
  IVehicleConfiguration,
  IConfiguration,
  isStockSaleOrderToBeProcessed,
  INextArticle,
  objectLogger,
} from 'next-common';
import { IEquipmentIdAndSKU } from 'sales-crystallize-common';
import { cloneDeep, defaultTo, isEqual, sortBy } from 'lodash';
import { IModelPackages, IVehicleModel } from '../../components/sales/sales-vehicle-picker-page';
import {
  addInitialAccessories,
  addInitialEquipment,
  addInitialVehicleTyre,
  calculateRetailPrice,
  findSelectedColor,
  findSelectedInterior,
  moveRecommendedOnTop,
  prepareDeliveryChargeInput,
  prepareVehicleConfig,
  prepareVehicleModel,
  prepareVehicleVariant,
  removeNullOrUndefindValuesFromArray,
  VehicleConfig,
} from './vehicleOrder';
import { ICartProps, ICartSummaryData } from '@next-components/cart';
import { prepareArticles } from './vat';
import { AccessoryTool } from '../../components/sales/sales-vehicle-accessories-page/AccessoryTool';
import { ArticleType, Environments, VatCode } from 'sales-common';
import { getStockSaleParams } from './vehicleStock';

export const getCurrentModelData = (modelId: string, vehicleData: IVehicleModel[]) => {
  const currentModelData: IVehicleModel[] = vehicleData?.filter((vehicle) => vehicle?.id === modelId);
  const reOrderPackages: IModelPackages[] = moveRecommendedOnTop<IModelPackages>(currentModelData?.[0]?.salesPackages);
  if (currentModelData?.[0]?.salesPackages) currentModelData[0].salesPackages = reOrderPackages;
  return currentModelData;
};

export const getCurrentVariantData = (variantId: string, modelData) => {
  const currentVariantData: IModelPackages[] = modelData?.salesPackages?.filter((model) => model?.id === variantId);
  const { colors, interiors, rims, selectableEquipment } = currentVariantData?.[0] || {};
  const colorSorted = sortBy(colors, 'additionalPriceExcludingVat').reverse();
  const rimsSorted = sortBy(rims, 'priceExcludingVat').reverse();
  const selectableEquipmentSorted = sortBy(selectableEquipment, 'priceExcludingVat').reverse();
  const interiorsSorted = sortBy(interiors, 'additionalPriceExcludingVat').reverse();
  const reOrderColors: IVarnish[] = moveRecommendedOnTop<IVarnish>(colorSorted);
  const reOrderInterior: IInterior[] = moveRecommendedOnTop<IInterior>(interiorsSorted);

  if (rims) currentVariantData[0].rims = rimsSorted;
  if (interiors) currentVariantData[0].selectableEquipment = selectableEquipmentSorted;
  if (colors) currentVariantData[0].colors = reOrderColors;
  if (interiors) currentVariantData[0].interiors = reOrderInterior;
  return currentVariantData;
};

export const getMappedEquipement = (
  modelId: string,
  variantId: string,
  vehicleData: IVehicleModel[],
): IEquipmentIdAndSKU[] => {
  const currentModelData: IVehicleModel[] = getCurrentModelData(modelId, vehicleData);
  const currentVariantData: IModelPackages[] = getCurrentVariantData(variantId, currentModelData?.[0]);
  return currentVariantData?.[0]?.mappedEquipment ?? [];
};

//Update dynamically changed prices of selectables in cart
export const updateCartDataPrices = (
  cartData: ICartProps,
  setCartData: (data: ICartProps) => void,
  disableCartSkus: string[],
  priceUpdate,
  selectablePrices: { string: number } = null,
) => {
  const existingCartData = cloneDeep(cartData);
  let salesTotalExcludingVatAndTax = existingCartData?.prices?.salesTotalExcludingVatAndTax;

  if (disableCartSkus?.length) {
    //remove disable skus from cart
    disableCartSkus?.forEach((sku) => {
      const keyIndex = existingCartData?.data?.findIndex((data) => data?.id === sku);
      if (keyIndex >= 0) {
        const priceTobeRemoved = existingCartData?.data?.[keyIndex]?.price ?? 0;
        existingCartData?.data?.splice(keyIndex, 1);
        salesTotalExcludingVatAndTax = (salesTotalExcludingVatAndTax || 0) - priceTobeRemoved;
      }
    });
  }

  //Update dynamic prices of selectables available in article.
  existingCartData?.data?.forEach((data) => {
    const updatedPrice = priceUpdate?.has(data?.id)
      ? Number(priceUpdate?.get(data?.id))
      : selectablePrices?.[data?.id] ?? data?.price;

    if (updatedPrice !== data?.price) {
      salesTotalExcludingVatAndTax = salesTotalExcludingVatAndTax - data?.price + updatedPrice;
      data.price = updatedPrice;
    }
  });

  if (!isEqual(existingCartData?.data, cartData?.data)) {
    setCartData({
      ...cartData,
      data: existingCartData?.data,
      prices: {
        ...cartData?.prices,
        salesTotalExcludingVatAndTax,
      },
    });
  }
};

//Update dynamically changed prices of selectables in article
export const updateArticlePrices = (
  articles: INextArticle[],
  setArticles: (data: INextArticle[]) => void,
  disableArticles: string[],
  priceUpdate,
  selectablePrices: { string: number } = null,
) => {
  const existingArticles = cloneDeep(articles);

  if (disableArticles?.length) {
    //remove disable articles from cart
    disableArticles?.forEach((sku) => {
      const keyIndex = existingArticles?.findIndex((article) => article?.id === sku);
      if (keyIndex >= 0) existingArticles?.splice(keyIndex, 1);
    });
  }

  //Update dynamic prices of selectables available in article.
  existingArticles?.forEach((article) => {
    const priceExclVat = priceUpdate?.has(article?.id)
      ? Number(priceUpdate?.get(article?.id))
      : selectablePrices?.[article?.id] ?? article.priceExclVat;
    article.priceExclVat = priceExclVat;
    if (article?.articleType === ArticleType.EQUIPMENT) {
      article.dealerPriceExclVat = calculateRetailPrice(priceExclVat, 7);
    }
  });
  if (!isEqual(existingArticles, articles)) {
    setArticles(existingArticles);
  }
};

export const prepareInitialEquipments = (order: IOrder, configuration: IConfiguration = null) => {
  let articles: INextArticle[] = [];
  const initialEquipmentCart = addInitialEquipment(order, order?.equipments ?? [], configuration);
  initialEquipmentCart?.map((equipment) => {
    articles = [
      ...articles,
      ...prepareArticles(
        equipment?.key,
        equipment?.price,
        equipment?.vatCode,
        ArticleType.EQUIPMENT,
        equipment?.retailPrice,
        false,
        [],
        false,
        equipment?.id,
      ),
    ];
  });
  return { articles, initialEquipmentCart };
};

export const prepareInitialMargins = (order: IOrder): INextArticle[] => {
  const activeMargin = getLatestMargin(order?.margins);
  return [
    ...prepareArticles(
      VehicleConfig.CUSTOMERADVANTAGE,
      activeMargin?.campaignDiscount ?? 0,
      VatCode.NOT_APPLICABLE,
      ArticleType.CUSTOMERADVANTAGE,
    ),
    ...prepareArticles(
      VehicleConfig.DISCOUNT,
      order?.pimSnapshot?.customerAdvantageExcludingVat ?? 0,
      VatCode.NOT_APPLICABLE,
      ArticleType.DISCOUNT_AMOUNT,
    ),
    ...prepareArticles(
      VehicleConfig.IMPORTCONTRIBUTION,
      activeMargin?.campaignImporter ?? order?.pimSnapshot?.importerSupportExcludingVat ?? 0,
      VatCode.NOT_APPLICABLE,
      ArticleType.IMPORTCONTRIBUTION,
    ),
  ];
};

// Prepare initial cartData and articles
export const prepareInitialArticlesAndCart = (
  order: IOrder,
  vehicle: IVehicleConfiguration,
  vehicleColorId: string,
  vehicleInteriorId: string,
  configuration: IConfiguration = null,
) => {
  const params = getStockSaleParams(order);
  const isStockSaleOrder = isStockSaleOrderToBeProcessed(configuration, params?.source, params?.serialNo, params?.make);
  if (!order || (!isStockSaleOrder && (!vehicle || order?.orderReference?.pimCode !== vehicle?.sku)))
    return { initialArticles: [], initialCart: [] };

  let initialCart: ICartSummaryData[] = [];
  let initialArticles: INextArticle[] = [];
  const { pimSnapshot } = order;

  const modelCart = prepareVehicleModel(vehicle, pimSnapshot, order, isStockSaleOrder);

  const modelArticle = prepareArticles(
    VehicleConfig.MODEL,
    pimSnapshot?.carPriceExclVatAndFees,
    pimSnapshot?.vatCode,
    ArticleType.VEHICLE,
    pimSnapshot?.baseCostPriceExclVat,
    false,
  );

  const variantCart = prepareVehicleVariant(vehicle, pimSnapshot, order, isStockSaleOrder);
  // @ts-ignore
  
  const varinatArticle = prepareArticles(
    VehicleConfig.VARIANT,
    pimSnapshot?.packagePriceExclVat,
    pimSnapshot?.vatCode,
    ArticleType.PACKAGE,
    pimSnapshot?.packageCostPriceExclVat,
    pimSnapshot?.packagePriceExclVat === 0,
    [],
    false,
    vehicle?.id,
  );

  const wreckageArticle = prepareArticles(
    VehicleConfig.SCRAPDEPOSIT,
    pimSnapshot?.extraWreckage,
    VatCode.NOT_APPLICABLE,
    ArticleType.SCRAPDEPOSIT,
  );

  const oneOffFeeArticle = prepareArticles(
    VehicleConfig.TAREWEIGHT,
    pimSnapshot?.oneOffTaxWeight,
    VatCode.NOT_APPLICABLE,
    ArticleType.TAREWEIGHT,
  );

  const co2taxPrice = isStockSaleOrder ? defaultTo(pimSnapshot?.co2Tax, 0) : 0;
  const noxtaxPrice = isStockSaleOrder ? defaultTo(pimSnapshot?.noxTax, 0) : 0;

  const co2TaxArticle = prepareArticles(VehicleConfig.CO2TAX, co2taxPrice, VatCode.NOT_APPLICABLE, ArticleType.CO2TAX);
  const noxTaxArticle = prepareArticles(VehicleConfig.NOXTAX, noxtaxPrice, VatCode.NOT_APPLICABLE, ArticleType.NOXTAX);

  initialCart = [modelCart, variantCart];
  initialArticles = [
    ...modelArticle,
    ...varinatArticle,
    ...wreckageArticle,
    ...oneOffFeeArticle,
    ...co2TaxArticle,
    ...noxTaxArticle,
  ];

  if (vehicleColorId) {
    const selectedColor = findSelectedColor(vehicle, vehicleColorId, isStockSaleOrder, order);
    if (selectedColor) {
      initialCart = [...initialCart, selectedColor];
      initialArticles = [
        ...initialArticles,
        ...prepareArticles(
          VehicleConfig.COLOR,
          selectedColor?.price,
          selectedColor?.vatCode,
          ArticleType.EQUIPMENT,
          selectedColor?.retailPrice,
          selectedColor?.isRecommended,
          [],
          false,
          selectedColor?.id,
        ),
      ];
    }
  }

  if (vehicleInteriorId) {
    const selectedInterior = findSelectedInterior(vehicle, vehicleInteriorId, isStockSaleOrder, order);
    if (selectedInterior) {
      initialCart = removeNullOrUndefindValuesFromArray(initialCart);
      initialCart = [...initialCart, selectedInterior];
      initialArticles = [
        ...initialArticles,
        ...prepareArticles(
          VehicleConfig.INTERIOR,
          selectedInterior?.price,
          selectedInterior?.vatCode,
          ArticleType.EQUIPMENT,
          selectedInterior?.retailPrice,
          selectedInterior?.isRecommended,
          [],
          false,
          selectedInterior?.id,
        ),
      ];
    }
  }

  const deliveryCharge = pimSnapshot?.extraDeliveryCharge || 0;
  const [cartInput, articleInput] = prepareDeliveryChargeInput(deliveryCharge, pimSnapshot?.vatCode);
  initialCart = [...initialCart, cartInput as ICartSummaryData];
  initialArticles = [...initialArticles, ...(articleInput as INextArticle[])];

  if (order?.equipments?.length) {
    initialCart = removeNullOrUndefindValuesFromArray(initialCart);
    const { articles, initialEquipmentCart } = prepareInitialEquipments(order, configuration);
    initialCart = [...initialCart, ...initialEquipmentCart];
    initialArticles = [...initialArticles, ...articles];
  }

  if (order?.margins?.length) {
    const articles = prepareInitialMargins(order);
    initialArticles = [...initialArticles, ...articles];
  }

  if (order?.accessories?.length) {
    initialCart = removeNullOrUndefindValuesFromArray(initialCart);
    const { initialCartAccessory, initialArticleAccessory } = addInitialAccessories(order, order?.accessories);
    initialCart = [...initialCart, ...initialCartAccessory];
    initialArticles = [...initialArticles, ...initialArticleAccessory];
  }

  return { initialArticles, initialCart };
};

export const prepareInitialCartData = (
  variant: IModelPackages,
  model: IVehicleModel,
  order: IOrder,
  accTool: AccessoryTool,
  vehicleConfig: IVehicleConfiguration,
  configuration: IConfiguration = null,
): ICartSummaryData[] => {
  let initialCartData: ICartSummaryData[] = [];
  if (!order || !variant || vehicleConfig?.sku !== variant?.sku || !accTool) {
    return [];
  }
  variant.isRecommended = variant?.price === model?.basePrice;
  variant.price = variant?.price > model?.basePrice ? (variant?.price || 0) - (model?.basePrice || 0) : 0;

  const currentModelData = prepareVehicleConfig(
    VehicleConfig.MODEL,
    model?.id,
    model?.name,
    model?.basePrice,
    model?.vatCode,
  );

  const variantData = prepareVehicleConfig(
    VehicleConfig.VARIANT,
    variant?.id,
    variant?.name,
    variant?.price,
    variant?.vatCode,
    variant?.isRecommended,
  );
  initialCartData = [currentModelData, variantData];

  if (order?.equipments?.length) {
    initialCartData = removeNullOrUndefindValuesFromArray(initialCartData);
    initialCartData = [...initialCartData, ...addInitialEquipment(order, order?.equipments, configuration)];
  }
  if (accTool?.tireSetData && accTool?.tireSetData?.length) {
    const { initialTyre: tireSetConfigData } = addInitialVehicleTyre(accTool?.tireSetData);
    tireSetConfigData?.id && initialCartData.push(tireSetConfigData);
  }
  if (accTool?.accessoryData && accTool?.accessoryData?.length) {
    initialCartData = removeNullOrUndefindValuesFromArray(initialCartData);
    const { initialCartAccessory } = addInitialAccessories(order, order?.accessories);
    initialCartData = [...initialCartData, ...initialCartAccessory];
  }

  return initialCartData;
};
