import React, { useEffect, useState } from 'react';
import { OverviewHeader } from './Header';
import { FilterBar } from './FilterBar';
import { IMyOrdersItem, MyOrders } from './MyOrders';
import {
  cancelOrderMutationParsed,
  ERROR_POLICY,
  findRouteForOrder,
  queryOrdersParsed,
  resumeOrderMutationParsed,
  Routes,
  selectedDealerId,
  sendReserveVehicleOrderMutationParsed,
  useEmployee,
} from '../../../common';
import {
  IConfiguration,
  IOrderFiltersInput,
  IOrderQueryResult,
  IPagination,
  ISortingDirection,
  OrderStatus,
  SortingDirection,
  VehicleReserveStatus,
} from 'next-common';
import { useLazyQuery, useMutation } from '@apollo/client';
import { StringParam, useQueryParam } from 'use-query-params';
import { getStati, OrderCategory } from './orderQuery.utils';
import { CancelReserveOrderModal } from '../../sales/sales-common/CancelReserveOrderModal';
import { CancelOrderModal } from '../../sales/sales-common/CancelOrderModal';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { useNavigate } from 'react-router-dom';
import CreateOrder from './CreateOrder';
import { IRootUser } from '../../../common/interfaces/root-user';
import { OverviewBodyWrapper } from './MyOverView.styled';

interface IMyOverviewProps extends IRootUser {
  configuration: IConfiguration
}

interface IOrderQueryInput {
  filters: IOrderFiltersInput;
  pagination: IPagination;
  sorting: ISortingDirection;
}
const defaultSort: ISortingDirection = {
  fieldName: 'createdAt',
  direction: SortingDirection.DESC,
};
const defaultPagination: IPagination = {
  page: 1,
  itemsPerPage: 10,
  totalItems: 0,
};
export const defaultOrderFilter: IOrderFiltersInput = {
  dealerIds: [],
  salesPersonIdent: null,
  searchQuery: '',
  stati: getStati(OrderCategory.OFFER),
};

export const MyOverview = ({ isAdmin, configuration }: IMyOverviewProps) => {
  const [loading, setLoading] = useState<NodeJS.Timeout>(null);
  const [orders, setOrders] = useState<IOrderQueryResult[]>([]);
  const [orderId, setOrderId] = useState<string>(null);
  const [filters, setFilters] = useState<IOrderFiltersInput>(defaultOrderFilter);
  const [category, setCategory] = useState<OrderCategory>(OrderCategory.OFFER);
  const sorting: ISortingDirection = defaultSort;
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [pageChange, setPageChange] = useState(false);
  const [loadingForCancelReserve, setLoadingForCancelReserve] = React.useState<boolean>(false);
  const [reserveCancelModalStatus, setReserveCancelModalStatus] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState(false);
  const [reserveCancelOrderData, setReserveCancelOrderData] = React.useState(null);
  const [resumeOrderMutation] = useMutation(resumeOrderMutationParsed);
  const [salesPersonQuery, setSalesPersonQuery] = useQueryParam('selger', StringParam);
  const [reserveCancelVehicleOrderMutation] = useMutation(sendReserveVehicleOrderMutationParsed);
  const [cancelOrderMutation] = useMutation(cancelOrderMutationParsed);
  const navigate = useNavigate();
  const [createOrder, setCreateOrder] = React.useState<boolean>(false);

  const queryOrdersInput = (): IOrderQueryInput => {
    return {
      filters,
      pagination: {
        ...pagination,
        page: pageChange ? pagination.page + 1 : 1,
      },
      sorting,
    };
  };

  const employee = useEmployee();

  const [getOrders] = useLazyQuery(queryOrdersParsed, {
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const newPage = data && Array.isArray(data?.orderQuery?.results) ? data.orderQuery.results : [];
      const isFirstPage = data.orderQuery.pagination.page === 1;
      const o = isFirstPage ? newPage : [...orders, ...newPage];

      setOrders(o);
      setPagination({
        ...data.orderQuery.pagination,
        totalItems: data.orderQuery?.pagination.totalItems ?? 0,
      });

      setPageChange(null);
      setLoading(null);
    },
    onError: (error) => {
      // tslint:disable-next-line:no-console
      console.log(error);
      setOrders([]);
      setPageChange(null);
      setLoading(null);
    },
  });

  const fetchOrders = () => {
    if (!filters.dealerIds || filters.dealerIds.length < 1) {
      setLoading(null);
      return;
    }

    if (loading) {
      clearTimeout(loading);
    }

    setLoading(
      setTimeout(() => {
        getOrders({
          variables: {
            input: queryOrdersInput(),
          },
        });
      }, 250),
    );
  };

  /**
   * Append the new page to the existing array instead of replacing it.
   */
  useEffect(() => {
    if (pageChange) {
      fetchOrders();
    }
  }, [pageChange]);

  /**
   * Refetch orders using the applied filter.
   */
  useEffect(() => {
    setOrders([]);
    fetchOrders();
  }, [filters]);

  useEffect(() => {
    filters.stati = getStati(category);
    setFilters({ ...filters });
  }, [category]);

  useEffect(() => {
    filters.salesPersonIdent = salesPersonQuery || null;
    filters.dealerIds = employee?.dealers?.map((dealer) => dealer?.dealerId) ?? [];
    setFilters({ ...filters });
  }, [employee, salesPersonQuery]);

  useEffect(() => {
    if (!employee) return;
    const currentDealerId = selectedDealerId();
    if (!currentDealerId) {
      const newDealerId = employee.departmentNumber;
      selectedDealerId(newDealerId);
    }
  }, [employee]);

  /**
   * Set default salesperson filter to the signed in user
   */
  useEffect(() => {
    if (employee?.employeeId && salesPersonQuery === undefined) {
      setSalesPersonQuery(employee.employeeId, 'replace');
    }
  }, [employee, salesPersonQuery, setSalesPersonQuery]);

  const resumeOrder = async (id: string) => {
    try {
      const { data } = await resumeOrderMutation({ variables: { orderId: id } });
      return data?.resumeOrder;
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };

  const confirmDeleteOrder = async (confirmed: boolean) => {
    setShowModal(false);
    if (!confirmed) return;

    try {
      const { data } = await cancelOrderMutation({ variables: { orderId } });
      setOrders(orders.filter((order) => order.id !== data?.cancelOrder?.id));
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };

  const handleSelect = (element: any) => {
    const route = Routes.getViewPage(element.id);
    navigate(route);
  };

  const handleContinue = async (element: IMyOrdersItem) => {
    let route: string;
    const s = OrderStatus[element.status.toString()];
    if (s === OrderStatus.PAUSED || s === OrderStatus.CANCELLED) {
      setOrderId(element.id);
      const order = await resumeOrder(element.id);
      if (!order) return;
      route = findRouteForOrder(order);
    } else {
      route = findRouteForOrder(element);
    }
    navigate(route);
  };

  const updateFilter = (field, value) => {
    filters[field] = value;
    setFilters({ ...filters });
  };

  const updateCategory = (value) => {
    setCategory(value);
  };

  const handleLoadMore = () => {
    setPageChange(true);
  };

  const handleCancelReservation = async (element: any) => {
    setReserveCancelModalStatus(true);
    setReserveCancelOrderData({
      id: element?.id,
      customerId: element?.customer?.bosCustomer.id,
    });
  };

  const handleDelete = async (element: any) => {
    setOrderId(element.id);
    setShowModal(true);
  };

  const confirmReserveCancelClick = async () => {
    setReserveCancelModalStatus(false);
    setLoadingForCancelReserve(true);
    try {
      await reserveCancelVehicleOrderMutation({
        variables: {
          id: reserveCancelOrderData?.id,
          status: VehicleReserveStatus[VehicleReserveStatus.WAITING_CANCELLATION],
          customerId: reserveCancelOrderData?.customerId,
        },
      });
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
    fetchOrders();
    setLoadingForCancelReserve(false);
  };

  return (
    <>
      <OverviewHeader
        isAdmin={isAdmin}
        onClick={() => setCreateOrder(true)}
        employee={employee}
        filter={filters}
        category={category}
        onChange={updateFilter}
        onCategoryChange={updateCategory}
      />
      {createOrder && (
        <CreateOrder onClose={() => setCreateOrder(false)} createOrder={createOrder} configuration={configuration} />
      )}
      <OverviewBodyWrapper>
        <FilterBar
          employee={employee}
          filter={filters}
          category={category}
          onChange={updateFilter}
          onCategoryChange={updateCategory}
          configuration={configuration}
        />
        <MyOrders
          category={category}
          filter={filters}
          pagination={pagination}
          onLoadMore={handleLoadMore}
          onSelect={handleSelect}
          onContinue={handleContinue}
          onCancelReservation={handleCancelReservation}
          onDelete={handleDelete}
          data={orders}
          loading={!!loading}
        />
      </OverviewBodyWrapper>
      {reserveCancelModalStatus && (
        <CancelReserveOrderModal
          modalStatus={reserveCancelModalStatus}
          closeModalClick={() => setReserveCancelModalStatus(false)}
          confirmReserveCancelClick={confirmReserveCancelClick}
        />
      )}
      <CancelOrderModal show={showModal} isOrder={false} onConfirm={confirmDeleteOrder} />
      {loadingForCancelReserve && <LoadingSpinner isModal={true} />}
    </>
  );
};

export default MyOverview;
