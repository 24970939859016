import { ICartImageData, ICartLocationProps, ICartProps, ICartSummaryData, IEquipmentDetailsProps } from '@next-components/cart';
import {
  difference,
  differenceBy,
  includes,
  intersection,
  isEmpty,
  join,
  map,
  pick,
  remove,
  sum,
  uniq,
  cloneDeep,
  isNil,
  get,
  isEqual,
  split,
  trim,
  defaultTo,
} from 'lodash';
import {
  AccessoryType,
  getEnumKey,
  getLatestMargin,
  getMakeNameByCode,
  getPimCodeComponents,
  IInterior,
  IOrder,
  IPimSnapshot,
  IPackageEquipmentItems,
  IPackageEquipments,
  IServiceContract,
  IVarnish,
  IVehicleAccessory,
  IVehicleConfiguration,
  IVehicleConfigurationPricing,
  IVehicleConfigurationQuery,
  IVehicleEquipment,
  IVehicleImage,
  MAKE,
  VehicleEquimentTypeEnum,
  VehicleEquipmentCategoryEnum,
  Image,
  IEquipmentTypes,
  mapAccessoryVatCode,
  ILead,
  IConfiguration,
  INextArticle,
  HashMap,
  calculatePercentageDecrease,
  getIFSModelSeriesCodeFromPimCode,
  IPimVehicleModelPricing,
  IPimVehicleModel,
  IUniqueModelCodeAndSkus,
  getStockEquipmentLevelsFromEquipments,
} from 'next-common';
import { ArticleType, VatCode, formatNumber } from 'sales-common';
import { getInteriorPrice, getVarnishPrice, isValidStockSaleOrder, prepareArticles } from '..';
import {
  brandsToAllowOrderWithoutSerialNo,
  DELIMETER,
  disableFactoryOrder,
  equipmentCategoryForKeyCreation,
  getTTCodeStatusName,
  IInteriorData,
  IVarnishData,
  IVehicleStock,
} from '../../common';
import { IReservedWarningProps } from '../../components/notifications';
import { ICustomAccessoryProps } from '../../components/sales/sales-vehicle-accessories-page/AccessoryTool';
import { IModelPackages, IVehicleModel } from '../../components/sales/sales-vehicle-picker-page';
import { ICart } from '../../components/sales/sales-wizard';
import { priceFormatter } from '../../utils';
import placeholderImage from '../../assets/images/placeholder.png';
import { isVehicleDisableAtCurrentLocation, isVehicleReserved, isVehicleSoftLocked, isVehicleSold } from './vehicleStatus';

export enum LocationIds {
  OWN = 'own',
  PORT = 'port',
  OTHER = 'other',
}

export enum LocationNames {
  OWN = 'Egen forhandler', // own dealership
  PORT = 'Importør', // importer
  OTHER = 'Andre forhandlere', // other retailers
}
export interface ISkuVehicleCount {
  [key: string]: number;
}

export interface IUnavailableVehicleWarning extends IReservedWarningProps {
  isTaken?: boolean;
}

interface ICounts {
  withOptions: number;
  withoutOptions: number;
}
interface IDescriptionDialogImage {
  alt: string;
  url: string;
  relatedEquipmentId?: string;
}

interface IDescriptionDialogEquipment {
  key: number;
  title: string;
  description: string;
  images?: Image[];
  isHighlight?: boolean;
  id?: string;
}
export interface IDescriptionDialogContent {
  includedTitle: string;
  includedSub: string;
  includedDesc: string;
  images: IDescriptionDialogImage[];
  included: IDescriptionDialogEquipment[];
  highlights: IDescriptionDialogEquipment[];
  site?: string;
}

export interface ISelectableDescriptionDialogContent {
  title: string;
  description: string;
  image?: IDescriptionDialogImage;
  site?: string;
}

interface IBaseModelCode {
  modelCodeCount?: number;
  options?: string;
}

export const enum VehicleConfig {
  MODEL = 'model',
  VARIANT = 'variant',
  COLOR = 'color',
  INTERIOR = 'interior',
  SELECTABLE_EQUIPMENT = 'selectable_equipment',
  TYRE = 'tyre',
  ACCESSORY = 'accessory',
  SERVICE_CONTRACT = 'serviceContract',
  RIM = 'rim',
  DISCOUNT = 'discount',
  DELIVERY_CHARGE = 'delivery_charge',
  TAREWEIGHT = 'oneoff-tax',
  SCRAPDEPOSIT = 'wreckage',
  CUSTOMERADVANTAGE = 'customer-advantage',
  CO2TAX = 'co2tax',
  NOXTAX = 'notax',
  IMPORTCONTRIBUTION = "IMPORTCONTRIBUTION",
}

export const prepareVehicleConfig = (
  key: string,
  id: string,
  name: string,
  price: number,
  vatCode: string,
  isRecommended = false,
  retailPrice?: number,
): ICartSummaryData => ({
  key,
  id,
  name,
  price,
  vatCode,
  isRecommended,
  retailPrice
});

export const prepareImageObject = (images: IVehicleImage): ICartImageData => {
  let imageObj: ICartImageData = undefined;
  if (images) {
    imageObj = {
      altText: images?.altText,
      url: images?.url,
    };
    images?.variants?.forEach((variant) => {
      if (variant?.width === 500) {
        const splitImageUrl = variant?.url?.split('.');
        const imgType = splitImageUrl?.[(splitImageUrl?.length || 0) - 1];
        if (imgType === 'webp') imageObj.defaultUrl = variant?.url;
      }
    });
  }
  return imageObj;
};

export const getOneOffTaxWeight = <Type extends { oneOffTaxWeight?: number; extraWreckage?: number }>(
  pricing: Type,
): number => {
  return (pricing?.oneOffTaxWeight || 0) + (pricing?.extraWreckage || 0);
};

export const prepareSalesPackageObjFromVehicleConfig = (vehicle: IVehicleConfiguration) => {
  return {
    isAgent: vehicle?.model?.isAgent ?? false,
    image: prepareImageObject(vehicle?.images?.[0]),
    colors: prepareSelectableDescriptionDialogContent(vehicle?.varnishes),
    interiors: prepareSelectableDescriptionDialogContent(vehicle?.interiors),
    selectableEquipment: prepareSelectableDescriptionDialogContent(vehicle?.model?.selectableEquipment, true),
    rims: prepareSelectableDescriptionDialogContent(vehicle?.model?.rims, true),
    price: defaultTo(vehicle?.pricing?.packagePriceExclVat, 0),
    mainVehicleId: vehicle?.id,
    sku: vehicle?.sku,
    modelCode: vehicle?.model?.modelCode,
    modelSeries: vehicle?.model?.series,
    mappedEquipment: vehicle?.mappedEquipment ?? [],
    vatCode: vehicle?.pricing?.vatCode,
    oneOffTaxWeight: getOneOffTaxWeight<IVehicleConfigurationPricing>(vehicle?.pricing),
    deliveryCharge: defaultTo(vehicle?.pricing?.extraDeliveryCharge, 0),
    customerAdvantage: defaultTo(vehicle?.pricing?.customerAdvantage, 0),
    customerAdvantageExcludingVat: defaultTo(vehicle?.pricing?.customerAdvantageExcludingVat, 0),
    packageDescriptionContent: prepareDescriptionDialogContent(vehicle),
    pricing: vehicle?.pricing,
    manufacturerComponentIdModel: vehicle?.model?.manufacturerComponentIdModel,
    model: {
      vehicleType: vehicle?.model?.vehicleType
    },
  }
}

export const getStructuredVehicleData = (data: IVehicleConfiguration | IVehicleConfiguration[]): IVehicleModel[] => {
  const modelData = [];
  const processVehicle = (vehicle: IVehicleConfiguration) => {
    const modelAvailableIndex = modelData?.findIndex((model) => model?.id === vehicle?.model?.id);
    if (modelAvailableIndex >= 0) {
      modelData[modelAvailableIndex] = {
        ...modelData[modelAvailableIndex],
        packageSkus: [...(modelData[modelAvailableIndex]?.packageSkus || []), vehicle?.sku],
        basePrice: defaultTo((modelData[modelAvailableIndex]?.basePrice > vehicle?.pricing?.carPriceExclVatAndFees
          ? vehicle?.pricing?.carPriceExclVatAndFees
          : modelData[modelAvailableIndex]?.basePrice), 0),
        vatCode: vehicle?.pricing?.vatCode,
        baseCostPriceExclVat: vehicle?.pricing?.baseCostPriceExclVat,
        salesPackages: [
          ...new Set([
            ...modelData[modelAvailableIndex].salesPackages,
            {
              ...vehicle?.salesPackages[0],
              ...prepareSalesPackageObjFromVehicleConfig(vehicle),
            },
          ]),
        ],
      };
    } else {
      modelData.push({
        id: vehicle?.model?.id,
        name: vehicle?.model?.name,
        code: vehicle?.model?.modelCode,
        packageSkus: [vehicle?.sku],
        basePrice: defaultTo(vehicle?.pricing?.carPriceExclVatAndFees, 0),
        vatCode: vehicle?.pricing?.vatCode,
        baseCostPriceExclVat: vehicle?.pricing?.baseCostPriceExclVat,
        salesPackages: [
          {
            ...vehicle?.salesPackages?.[0],
            ...prepareSalesPackageObjFromVehicleConfig(vehicle),
          },
        ],
      });
    }
  };

  if (Array.isArray(data)) {
    data?.forEach(processVehicle);
  } else if (data) {
    processVehicle(data);
  }

  return modelData;
};

export const getStructuredVehicleModelData = (data: IPimVehicleModel[]): IVehicleModel[] => {
  const modelData = [];

  data?.forEach((vehicle: IPimVehicleModel) => {
    const modelAvailableIndex = modelData?.findIndex((model) => model?.id === vehicle?.model?.id);
    if (modelAvailableIndex >= 0) {
      modelData[modelAvailableIndex] = {
        ...modelData[modelAvailableIndex],
        packageSkus: [...(modelData[modelAvailableIndex]?.packageSkus || []), vehicle?.sku],
        basePrice: defaultTo((modelData[modelAvailableIndex]?.basePrice > vehicle?.prices?.carPriceExclVatAndFees
          ? vehicle?.prices?.carPriceExclVatAndFees
          : modelData[modelAvailableIndex]?.basePrice), 0),
        vatCode: vehicle?.prices?.vatCode,
        baseCostPriceExclVat: vehicle?.prices?.baseCostPriceExclVat,
        vehicleType: vehicle?.model?.vehicleType,
        salesPackages: [
          ...new Set([
            ...modelData[modelAvailableIndex].salesPackages,
            {
              name: vehicle?.salesPackage,
              sku: vehicle?.sku,
              price: defaultTo(vehicle?.prices?.packagePriceExclVat, 0),
              id: vehicle?.id,
              modelCode: vehicle?.model?.modelCode,
              vatCode: vehicle?.prices?.vatCode,
              customerAdvantageExcludingVat: defaultTo(vehicle?.prices?.customerAdvantageExcludingVat, 0),
              deliveryCharge: defaultTo(vehicle?.prices?.extraDeliveryCharge, 0),
              oneOffTaxWeight: getOneOffTaxWeight<IPimVehicleModelPricing>(vehicle?.prices),
              pricing: vehicle?.prices,
              image: prepareImageObject(vehicle?.image),
              path: vehicle?.path,
              // packageDescriptionContent: prepareDescriptionDialogContent(vehicle),
            },
          ]),
        ],
      };
    } else {
      modelData.push({
        id: vehicle?.model?.id,
        name: vehicle?.model?.name,
        code: vehicle?.model?.modelCode,
        packageSkus: [vehicle?.sku],
        basePrice: defaultTo(vehicle?.prices?.carPriceExclVatAndFees, 0),
        vatCode: vehicle?.prices?.vatCode,
        baseCostPriceExclVat: vehicle?.prices?.baseCostPriceExclVat,
        vehicleType: vehicle?.model?.vehicleType,
        salesPackages: [
          {
            name: vehicle?.salesPackage,
            sku: vehicle?.sku,
            price: defaultTo(vehicle?.prices?.packagePriceExclVat, 0),
            id: vehicle?.id,
            modelCode: vehicle?.model?.modelCode,
            vatCode: vehicle?.prices?.vatCode,
            customerAdvantageExcludingVat: defaultTo(vehicle?.prices?.customerAdvantageExcludingVat, 0),
            deliveryCharge: defaultTo(vehicle?.prices?.extraDeliveryCharge, 0),
            oneOffTaxWeight: getOneOffTaxWeight<IPimVehicleModelPricing>(vehicle?.prices),
            pricing: vehicle?.prices,
            image: prepareImageObject(vehicle?.image),
            path: vehicle?.path,
            // packageDescriptionContent: prepareDescriptionDialogContent(vehicle),
          },
        ],
      });
    }
  });

  return modelData;
};

export const prepareSelectableDescriptionDialogContent = (
  selectableData: IEquipmentTypes[] | IVarnish[] | IInterior[],
  isRimOrSelectable = false,
) => {
  const preparedEquipments = [];
  if (selectableData?.length) {
    selectableData?.forEach((selectable) => {
      const selectableCopy = { ...(selectable || {}) };
      if (isRimOrSelectable && get(selectableCopy, 'equipmentDescription')) {
        selectableCopy.description = selectableCopy?.equipmentDescription;
      }
      if (get(selectableCopy, 'name') && get(selectableCopy, 'equipmentImage.length') > 0) {
        selectableCopy.popupImage = { alt: selectableCopy?.name, src: selectableCopy?.equipmentImage?.[0]?.url };
      } else if (
        isRimOrSelectable &&
        get(selectableCopy, 'name') &&
        get(selectableCopy, 'equipmentShapeImage.length') > 0
      ) {
        selectableCopy.popupImage = { alt: selectableCopy?.name, src: selectableCopy?.equipmentShapeImage?.[0]?.url };
      } else {
        selectableCopy.popupImage = { alt: 'Bilde mangler', src: placeholderImage };
      }
      preparedEquipments.push(selectableCopy);
    });
  }
  return preparedEquipments;
};

export const prepareDescriptionDialogContent = (vehicle: IVehicleConfiguration): IDescriptionDialogContent => {
  let descriptionContent: IDescriptionDialogContent = undefined;
  if (get(vehicle, 'salespackageDescription') && get(vehicle, 'packageEquipments.length')) {
    const equipments = prepareHighlightsAndIncludedEquipments(vehicle?.packageEquipments);
    const highlightsEquipment = equipments?.filter((item) => item?.isHighlight);
    descriptionContent = {
      includedTitle: `${vehicle?.model?.name || ''} utstyrspakke`,
      includedSub: `${vehicle?.pricing?.packagePriceExclVat >= 0 ? '+' : '-'} ${priceFormatter(vehicle?.pricing?.packagePriceExclVat, vehicle) || ''
        }`,
      includedDesc: vehicle?.salespackageDescription ?? '',
      images: prepareDialogImageObject(highlightsEquipment),
      included: equipments,
      highlights: highlightsEquipment?.length > 5 ? highlightsEquipment?.slice(0, 5) : highlightsEquipment,
    };
  }
  return descriptionContent;
};

export const prepareHighlightsAndIncludedEquipments = (
  packageEquipments: IPackageEquipments[],
): IDescriptionDialogEquipment[] => {
  let equipments = undefined;
  const preparedEquipments: IDescriptionDialogEquipment[] = [];
  let keyCount = 0;
  if (packageEquipments?.[1] && get(packageEquipments[1], 'items.length') > 0) {
    equipments = packageEquipments[1].items;
  } else if (packageEquipments?.[0] && get(packageEquipments[0], 'items.length') > 0) {
    equipments = packageEquipments[0].items;
  }
  if (equipments) {
    equipments?.forEach((equipment: IPackageEquipmentItems) => {
      if (equipment?.description && equipment?.name) {
        preparedEquipments.push({
          key: keyCount,
          title: equipment.name,
          description: equipment.description,
          images: equipment?.images ?? [],
          isHighlight: equipment?.isHighlight ?? false,
          id: equipment?.sku ?? '',
        });
        keyCount++;
      }
    });
  }
  return preparedEquipments;
};

export const prepareDialogImageObject = (equipments: IDescriptionDialogEquipment[]): IDescriptionDialogImage[] => {
  let highlightsImages: IDescriptionDialogImage[] = [];
  if (equipments?.length) {
    equipments?.forEach((equipment) => {
      if (get(equipment, 'images[0]')) {
        highlightsImages.push({
          alt: equipment?.title ?? '',
          url: equipment?.images?.[0]?.url ?? '',
          relatedEquipmentId: equipment?.id ?? '',
        });
      }
    });
    if (highlightsImages.length === 0) {
      highlightsImages.push({
        alt: 'Bilde mangler',
        url: placeholderImage,
        relatedEquipmentId: equipments?.[0]?.id ?? '',
      });
    }
  } else {
    highlightsImages.push({
      alt: 'Bilde mangler',
      url: placeholderImage,
      relatedEquipmentId: '',
    });
  }

  return highlightsImages;
};

export const findSelectedColor = (
  vehicleConfig: IVehicleConfiguration,
  selectedColorId: string,
  isStockSaleOrder: boolean,
  order: IOrder = null,
) => {
  const vehicleOption = order?.options;
  const selectedColor = vehicleConfig?.varnishes?.filter((varnish) => varnish?.varnishCode === selectedColorId);

  if (selectedColor?.[0] || isStockSaleOrder) {
    const colorData = prepareVehicleConfig(
      VehicleConfig.COLOR,
      vehicleOption?.[0]?.colorId,
      vehicleOption?.[0]?.colorName,
      vehicleOption?.[0]?.colorPriceExcludingVat,
      vehicleOption?.[0]?.colorVatCode,
      selectedColor?.[0]?.isRecommended,
      vehicleOption?.[0]?.colorRetailPrice,
    );
    return colorData;
  }
  return null;
};

export const findSelectedInterior = (
  vehicleConfig: IVehicleConfiguration,
  selectedInteriorId: string,
  isStockSaleOrder: boolean,
  order: IOrder = null,
) => {
  const vehicleOption = order?.options;
  const selectedInterior = vehicleConfig?.interiors?.filter(
    (interior) => interior?.interiorCode === selectedInteriorId,
  );

  if (selectedInterior?.[0] || isStockSaleOrder) {
    const interiorData = prepareVehicleConfig(
      VehicleConfig.INTERIOR,
      vehicleOption?.[0]?.interiorId,
      vehicleOption?.[0]?.interiorName,
      vehicleOption?.[0]?.interiorPriceExcludingVat,
      vehicleOption?.[0]?.interiorVatCode,
      selectedInterior?.[0]?.isRecommended,
      vehicleOption?.[0]?.interiorRetailPrice,
    );

    return interiorData;
  }
  return null;
};

export const findSelectedServiceContract = (serviceContract: IServiceContract) => {
  const duration = (serviceContract?.duration || 0) / 12;
  const mileage = serviceContract?.drivingDistance ?? 0;
  const name = `Serviceavtale ${duration} år, ${mileage} km, inkl. mva.`;
  const price = defaultTo(serviceContract?.totalPrice, 0);
  const mva = price * 20 / 100;
  return {
    name,
    price,
    mva,
  }
};

export const getPrice = (data: ICartSummaryData): number => {
  if (!data || data?.isRecommended) return 0;
  return data?.price;
};

export const removeDuplicateObjectFromArray = <Type>(array: Type[], key: string): Type[] => {
  const check = new Set();
  return array?.filter((obj) => !check.has(obj?.[key]) && check.add(obj?.[key]));
};

export const calculateTotalPrice = (
  salesTotalExcludingVatAndTax: number,
  oneOffTaxAndWreckage: number,
  totalVat: number,
) => {
  const totalPrice = salesTotalExcludingVatAndTax + oneOffTaxAndWreckage + totalVat;
  return Math.round(totalPrice);
};

export const checkAndUpdateCart = (
  key: string,
  cartData: ICart,
  setCartData: (data: ICart) => void,
  data: ICartSummaryData = null,
  isRemove = false,
  recommendedTirePrice = 0,
  isReturn = false,
  isResetCart = false,
  isVehicleRemoved = false,
) => {
  const isKeyIndex = cartData?.data?.findIndex((cartDataIndex) => cartDataIndex?.key === key);
  let updatedCartData: ICartProps = null;
  const existingCartData = cloneDeep(cartData);
  // Empty cart when vehicle is removed
  if (isVehicleRemoved) {
    updatedCartData = {
      ...existingCartData,
      data: [],
    };
  } else if (isKeyIndex >= 0) {
    if (isRemove) {
      const priceTobeRemoved = existingCartData?.data?.[isKeyIndex]?.price ?? 0;
      if (key === VehicleConfig?.VARIANT || key === VehicleConfig?.MODEL) {
        existingCartData?.data?.splice(isKeyIndex);
      } else {
        existingCartData?.data?.splice(isKeyIndex, 1);
      }
      const salesTotalExcludingVatAndTax =
        (existingCartData?.prices?.salesTotalExcludingVatAndTax || 0) - priceTobeRemoved;
      updatedCartData = {
        ...existingCartData,
        prices: {
          ...existingCartData?.prices,
          salesTotalExcludingVatAndTax,
        },
      };
    } else if (isResetCart) {
      updatedCartData = {
        ...existingCartData,
        data: [{ ...data }],
      };
    } else {
      if (data) {
        let price = 0;
        if (key === VehicleConfig.VARIANT) {
          // Reset cart
          let modelPrice = 0;
          remove(existingCartData?.data, (existData) => {
            if (existData?.key === VehicleConfig.MODEL) modelPrice = existData?.price;
            return existData?.key !== VehicleConfig.MODEL && existData?.key !== VehicleConfig.VARIANT;
          });
          price = modelPrice + getPrice(data);
        } else {
          const existingData = existingCartData?.data?.[isKeyIndex];
          price = existingData?.isRecommended
            ? (existingCartData?.prices?.salesTotalExcludingVatAndTax || 0) + getPrice(data)
            : (existingCartData?.prices?.salesTotalExcludingVatAndTax || 0) -
              (existingData?.price || 0) +
              getPrice(data);

          if (key === VehicleConfig.TYRE && data?.isRecommended) {
            price = (existingCartData?.prices?.salesTotalExcludingVatAndTax || 0) - (existingData?.price || 0);
          }
        }
        existingCartData.data[isKeyIndex] = data;
        updatedCartData = {
          ...existingCartData,
          prices: { ...existingCartData?.prices, salesTotalExcludingVatAndTax: price },
        };
      } else {
        const priceTobeRemoved = existingCartData?.data?.[isKeyIndex]?.price ?? 0;
        existingCartData?.data?.splice(isKeyIndex, 1);
        updatedCartData = {
          ...existingCartData,
          prices: {
            ...existingCartData?.prices,
            salesTotalExcludingVatAndTax:
              (existingCartData?.prices?.salesTotalExcludingVatAndTax || 0) - priceTobeRemoved,
          },
        };
      }
    }
  } else {
    if (isRemove) return;
    const findIndexKey = cartData?.data?.findIndex((currentData) => currentData?.id === key);
    if (findIndexKey >= 0) {
      let totalPrice = 0;
      const priceTobeRemoved = cartData?.data?.[findIndexKey]?.price ?? 0;
      totalPrice = (cartData?.prices?.salesTotalExcludingVatAndTax || 0) - (priceTobeRemoved || 0);

      cartData?.data?.splice(findIndexKey, 1);
      updatedCartData = { ...cartData, prices: { ...cartData?.prices, salesTotalExcludingVatAndTax: totalPrice } };
    } else {
      let totalPrice = 0;
      totalPrice = (cartData?.prices?.salesTotalExcludingVatAndTax || 0) + getPrice(data);
      updatedCartData = {
        ...cartData,
        data: removeDuplicateObjectFromArray<ICartSummaryData>([...(cartData?.data || []), data], 'key'),
        prices: {
          ...cartData?.prices,
          salesTotalExcludingVatAndTax: totalPrice,
        },
      };
    }
  }

  updatedCartData.data = removeNullOrUndefindValuesFromArray(updatedCartData?.data);
  if (isReturn) return updatedCartData;
  setCartData(updatedCartData);
};

export const calculateInitialCartPrice = (cartData: ICartSummaryData[]): number => {
  const prices: number[] = cartData?.map((data) => (data?.isRecommended ? 0 : data?.price));
  return sum(prices);
};

export const getModelName = (vehicle: IVehicleConfiguration, querymake: string, order?: IOrder) => {
  let make = querymake
    ? getMakeNameByCode(querymake as MAKE, true)
    : getMakeNameByCode(vehicle?.model?.make , true);
  if (order) {
    make = getMakeNameByCode(order?.lead?.make as MAKE, true);
  }
  return make;
}; 

export const getModelSeries = (
  vehicle: IVehicleConfiguration,
  queryModelSeries: string,
  order: IOrder = null,
): string => queryModelSeries?.toUpperCase() ?? vehicle?.model?.series ?? order?.lead?.modelSeries?.toUpperCase();

export const getMake = (vehicle: IVehicleConfiguration, queryMake, order: IOrder = null): MAKE =>
  queryMake ?? vehicle?.model?.make ?? order?.lead?.make;

export const prepareString = (str: string = null, len = 20) => {
  if (str?.length > len) {
    return str?.slice(0, 20)?.concat('...');
  }
  return str;
};

export const fetchVehicleLocationIdList = (vehicleList: IVehicleStock[]): string[] =>
  vehicleList?.length ? map(vehicleList, (vehicle: IVehicleStock) => pick(vehicle, ['locationId'])?.locationId) : [];

export const fetchVehicleTraceCodeList = (vehicleList: IVehicleStock[]): number[] =>
  vehicleList?.length
    ? uniq(map(vehicleList, (vehicle: IVehicleStock) => pick(vehicle, ['trackTraceCode'])?.trackTraceCode))
    : [];

export const fetchVehicleStatusNamesList = (ttCodeList: number[], brand: MAKE): string[] => {
  if (ttCodeList?.length) {
    const selectedStatusName: string[] = [];
    ttCodeList.forEach((code) => {
      selectedStatusName.push(getTTCodeStatusName(brand, code))
    })
    
    return selectedStatusName;
  }
  return [];
};

export const convertToString = (list = []): string => join(uniq(list), ', ');

export const checkVehicleSoldOrReserved = (vehicle: IVehicleStock, orderId: string): IUnavailableVehicleWarning => {
  let vehicleAvailability: IUnavailableVehicleWarning = { isTaken: null, message: '', serialNo: '' };
  const serialNo = vehicle?.serialNumber?.toString();
  const isReserve = isVehicleReserved(vehicle);
  const isSold = isVehicleSold(vehicle);
  const isSoftLock = isVehicleSoftLocked(vehicle, orderId);
  
  if (isReserve) {
    vehicleAvailability = { isTaken: isReserve, message: 'AlreadyReserved', serialNo };
  } else if (isSold) {
    vehicleAvailability = { isTaken: isSold, message: 'VehicleSold', serialNo };
  } else if (isSoftLock) {
    vehicleAvailability = { isTaken: isSoftLock, message: '', serialNo };
  }
  return vehicleAvailability;
};

export const moveRecommendedOnTop = <T extends { isRecommended: boolean }>(data: T[]): T[] => {
  if (data?.length) {
    return [
      ...data.filter((item) => item?.isRecommended === true),
      ...data.filter((item) => item?.isRecommended === false),
    ];
  }
  return data;
};

export const getGroupName = (make = '', series = '') => {
  const brand = make?.toUpperCase() ?? '';
  const modelSeries = series?.replace(/\s/g, '')?.toUpperCase() ?? '';
  const regexPatternForHash = /#/g;
  if (regexPatternForHash?.test(modelSeries)) {
    return `${brand}-${modelSeries?.replace(regexPatternForHash, '-')}`;
  }
  return `${brand}-${modelSeries}`;
};

export const updateGrpNameWithModelSeries = (groupname: string = '', make: MAKE = null) => {
  const modelSeriesName = groupname?.replace(`${make}-`, '');
  const IFSModelSeriesCode = getIFSModelSeriesCodeFromPimCode(modelSeriesName);
  return `${make?.toUpperCase()}-${IFSModelSeriesCode?.toUpperCase()}`;
}

export const updateOrderLead = (lead: ILead): ILead => {
  let updatedLead = {...(lead || {})};
  const regexPatternForHash = /#/g;
  if (regexPatternForHash?.test(updatedLead?.modelSeries)) {
    updatedLead={...updatedLead,modelSeries:(updatedLead?.modelSeries?.replace(regexPatternForHash, '-')|| '')}
  }
  return updatedLead;
};

export const formatKey = (str = '') => str?.replace(/\s/g, '')?.toUpperCase() ?? '';

export const formatString = (str = '') => {
  if (str !== '') return str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
  return str;
};

export const isColorAvailableForFactory = (vehicle: IVehicleConfiguration, colorId: string): boolean => {
  const isValidColor = vehicle?.varnishes?.filter(
    (color) => color?.varnishCode === colorId && !color?.unAvailableForFactoryOrder,
  );
  return isValidColor?.length > 0;
};

export const isInteriorAvailableForFactory = (vehicle: IVehicleConfiguration, interiorId: string): boolean => {
  const isValidInterior = vehicle?.interiors?.filter(
    (interior) => interior?.interiorCode === interiorId && !interior?.unAvailableForFactoryOrder,
  );
  return isValidInterior?.length > 0;
};

export const isSelectableAvailableForFactory = (
  vehicle: IVehicleConfiguration,
  equipments: IEquipmentTypes[],
): boolean => {
  let isAvailable = true;
  const allSelectables = [...(vehicle?.model?.rims || []), ...(vehicle?.model?.selectableEquipment || [])];
  equipments?.forEach((equipment) => {
    const match = allSelectables?.filter(
      (selectable) => selectable?.sku === equipment?.sku && selectable?.unavailableForFactoryOrder,
    );
    if (match?.length) {
      isAvailable = false;
    }
  });
  return isAvailable;
};

export const isVehicleDisabled = (
  vehicle: IVehicleStock,
  validLocationIds: string[],
  vehicleReservedStatus: boolean,
): boolean => {
  return isVehicleDisableAtCurrentLocation(vehicle, validLocationIds) || vehicleReservedStatus;
}

export const isValidProductionDateFormat = (date: string): boolean => {
  // valid Format: 10-JUL-22
  const dateReg = /^(0[1-9]|1\d|2\d|3[01])$/;
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const yearReg = /^\d{2}$/;
  const newDate = date?.trim()?.split('-');
  return dateReg?.test(newDate?.[0]) && months?.includes(newDate?.[1]) && yearReg?.test(newDate?.[2]);
};

export const getVarnishData = (vehicleConfig: IVehicleConfiguration, selectedColorId: string): IVarnishData => {
  const varnish = vehicleConfig?.varnishes?.find((varnish) => varnish?.varnishCode === selectedColorId);
  const colorName = varnish?.name ? varnish?.name : 'Utilgjengelig fra PIM';
  const color = `${colorName} / ${varnish?.varnishCode}`;
  const colorPrice = getVarnishPrice(varnish);
  const colorValidFrom: string = varnish?.validFrom || '';
  const colorValidTo: string = varnish?.validTo || '';
  const colorId: string = varnish?.varnishCode || '';
  return { colorId, color, colorPrice, colorValidFrom, colorValidTo, colorName };
};

export const getInteriorData = (vehicleConfig: IVehicleConfiguration, selectedInteriorId: string): IInteriorData => {
  const interior = vehicleConfig?.interiors?.find(
    (currentInterior) => currentInterior?.interiorCode === selectedInteriorId,
  );
  const interiorName = interior?.name ? interior?.name : 'Utilgjengelig fra PIM';

  const interiorCode = `${interiorName} / ${selectedInteriorId}`;
  const interiorPrice = getInteriorPrice(interior);

  const interiorValidFrom: string = interior?.validFrom || '';
  const interiorValidTo: string = interior?.validTo || '';
  const interiorId: string = interior?.interiorCode || '';
  return { interiorId, interiorCode, interiorPrice, interiorValidFrom, interiorValidTo, interiorName };
};

export const isValidSelectableEquipmentVehicleForSkus = (
  skus: string[],
  vehicle: IVehicleStock,
  make: MAKE,
): boolean => {
  if ((make === MAKE.Peugeot && !vehicle?.priceOptions) || !vehicle?.optionCode) {
    return false;
  }

  const availableOptions = make === MAKE.Peugeot ? vehicle?.priceOptions?.split(DELIMETER) : vehicle?.optionCode?.split(DELIMETER);
  return intersection(skus, availableOptions)?.length === skus?.length;
};

export const isValidInteriorCombinationVehicleForSkus = (
  skus: string[],
  vehicle: IVehicleStock,
  make: MAKE,
): boolean => {
  if (make !== MAKE.Mercedes) return false;
  // At present interior combination is supported by MER only
  if (make === MAKE.Mercedes && !vehicle?.priceOptions) {
    return false;
  }

  const availableOptions = getAvailablePriceOptions(vehicle, make);
  return intersection(skus, availableOptions)?.length === skus?.length;
};

export const isColorFound = (data: IVehicleStock, colorId: string) => (colorId ? data?.colorId === colorId : true);

export const isInteriorFound = (data: IVehicleStock, interiorId: string, make: MAKE) =>
  interiorId
    ? // interior are checked by two places with id or price-options
    data?.interiorCode === interiorId || isValidInteriorCombinationVehicleForSkus([interiorId], data, make)
    : true;

export const isEquipmentFound = (data: IVehicleStock, skus: string[], make: MAKE) =>
  skus?.length ? isValidSelectableEquipmentVehicleForSkus(skus, data, make) : true;

const stringToArray = (str: string) => {
  return split(trim(str, '[]'), ',');
};

export const isValidFactoryOrder = (
  vehicle: IVehicleConfiguration,
  colorId: string,
  interiorId: string,
  queryMake: string,
  modelCode: string,
  equipments: IEquipmentTypes[],
): boolean => {
  const make: MAKE = getMake(vehicle, queryMake);
  const validateMotorVariant = stringToArray(disableFactoryOrder);
  if (includes(brandsToAllowOrderWithoutSerialNo, make) && !validateMotorVariant?.includes(modelCode)) {
    const isValidColor = colorId ? isColorAvailableForFactory(vehicle, colorId) : true;
    const isValidInterior = interiorId ? isInteriorAvailableForFactory(vehicle, interiorId) : true;
    const isValidSelectable = equipments?.length ? isSelectableAvailableForFactory(vehicle, equipments) : true;
    return isValidColor && isValidInterior && isValidSelectable;
  }
  return false;
};

export const getVehicleCountOnSkus = (
  sku: string,
  make: MAKE,
  vehicleList: IVehicleStock[],
  isBaseModelAvailable: boolean,
): ICounts => {
  const countOptionsObject = { withOptions: 0, withoutOptions: 0 };
  if (make) {
    vehicleList?.forEach((vehicle) => {
      if (isValidVehicleForSku(sku, vehicle, make, isBaseModelAvailable)) {
        countOptionsObject.withOptions += 1;
      } else {
        countOptionsObject.withoutOptions += 1;
      }
    });
    return countOptionsObject;
  }
  return countOptionsObject;
};

const isValidVehicleForSku = (sku: string, vehicle: IVehicleStock, make: MAKE, isBaseModel: boolean): boolean => {
  let { options } = getPimCodeComponents(sku);

  if (options) {
    options = options.split('-');
    const availableOptions = getAvailableOptions(vehicle, make);

    //Perform exact match from price options
    if (make === MAKE.Peugeot) {
      //Ignore price option which having length of 2 character
      const IgnoreCharacterLength = 2;
      const availableOptionsAfterFilter = availableOptions?.filter((option) => option?.length > IgnoreCharacterLength)?.sort((a: string, b: string) => {
        return (a).localeCompare(b);
      });
      return isEqual(options?.sort((a: string, b: string) => {
        return (a).localeCompare(b);
      }), availableOptionsAfterFilter);
    }
    return difference(options, availableOptions)?.length === 0;
  } else if (isBaseModel) return false;
  //if No Price Option is Available with SKU then incomming vehicle is valid.
  else return true;
};

const getAvailableOptions = (vehicle: IVehicleStock, make: MAKE) => {
  if (make === MAKE.Peugeot || make === MAKE.Mercedes) return vehicle?.priceOptions?.split(DELIMETER);
  else return vehicle?.optionCode?.split(DELIMETER);
};

const getAvailablePriceOptions = (vehicle: IVehicleStock, make: MAKE) => {
  return vehicle?.priceOptions?.split(DELIMETER);
};

export const getVehicleListWithSKUFilter = (
  selectedVariantSku: string,
  make: MAKE,
  vehicleList: IVehicleStock[],
  isBaseModel: boolean,
): IVehicleStock[] => {
  if (selectedVariantSku && make) {
    const newVehicleList = vehicleList?.filter((vehicle) => {
      return isValidVehicleForSku(selectedVariantSku, vehicle, make, isBaseModel);
    });
    return newVehicleList;
  }
  return [];
};

export const mapIsDisableVehicles = (
  filteredList: IVehicleStock[],
  validLocationIds: string[],
  vehicleReservedStatus: boolean,
) => {
  if (filteredList?.length) {
    const vehiclesList: IVehicleStock[] = [];
    filteredList?.forEach((vehicle) => {
      vehiclesList?.push({
        ...vehicle,
        isDisable: isVehicleDisabled(vehicle, validLocationIds, vehicleReservedStatus),
      });
    });
    return vehiclesList;
  }
  return [];
};

export const getListAfterInitialFilter = (
  sku: string,
  offerMake: MAKE,
  vehicleList: IVehicleStock[],
  vehicleReservedStatus: boolean,
  validLocationIds: string[],
  isBaseModel: boolean,
) => {
  const filteredList: IVehicleStock[] = getVehicleListWithSKUFilter(sku, offerMake, vehicleList, isBaseModel);
  return mapIsDisableVehicles(filteredList, validLocationIds, vehicleReservedStatus);
};

export const addInitialVehicleTyre = (
  tireSetData: Partial<ICustomAccessoryProps>[],
): { initialTyre: ICartSummaryData; isNoTyreSelected: boolean } => {
  let isNoTyreSelected = false;
  let selectedTire: Partial<ICustomAccessoryProps> = null;
  let recommndedTyre: Partial<ICustomAccessoryProps> = null;
  tireSetData?.forEach((tire) => {
    if (tire?.isSelected) selectedTire = tire;
    if (tire?.isRecommended) recommndedTyre = tire;
  });
  if (!selectedTire) {
    isNoTyreSelected = true;
    selectedTire = {
      ...recommndedTyre,
      isRecommended: false,
      price: -(recommndedTyre?.priceExcludingVat || 0),
    };
  }
  const initialTyre = prepareVehicleConfig(
    `${VehicleConfig.TYRE}`,
    selectedTire?.id,
    selectedTire?.name,
    selectedTire?.priceExcludingVat,
    selectedTire?.vatCode,
    false,
  );

  return { initialTyre, isNoTyreSelected };
};

const accessoryCartSortingBasedOnType = (accessories: IVehicleAccessory[]): IVehicleAccessory[] => {
  const cloneAccessories = [...(accessories ?? [])];

  const accessoryOrder = [
    AccessoryType[AccessoryType.TYRE],
    AccessoryType[AccessoryType.ACCESSORY],
    AccessoryType[AccessoryType.CUSTOM],
  ];
  cloneAccessories?.sort((aAccessory, bAccessory) => {
    const aAccInx = accessoryOrder.indexOf(aAccessory?.accessoryType?.toString());
    const bAccInx = accessoryOrder.indexOf(bAccessory?.accessoryType?.toString());

    return aAccInx - bAccInx;
  });

  return cloneAccessories;
};

export const addInitialAccessories = (order: IOrder, accessories: IVehicleAccessory[]) => {
  const initialCartAccessory: ICartSummaryData[] = [];
  const initialArticleAccessory: INextArticle[] = [];

  const sortedAccessories = accessoryCartSortingBasedOnType(accessories);

  sortedAccessories?.forEach((accessory) => {
    if (accessory?.accessoryType.toString() === AccessoryType[AccessoryType.TYRE]) {
      initialCartAccessory.push(
        prepareVehicleConfig(
          `${VehicleConfig.TYRE}`,
          accessory?.customId,
          accessory?.name,
          accessory?.priceExcludingVat,
          accessory?.vatCode,
          false,
        ),
      );
      initialArticleAccessory.push(
        ...prepareArticles(
          `${VehicleConfig.TYRE}`,
          accessory?.priceExcludingVat,
          accessory?.vatCode,
          ArticleType.WINTERTIRES,
          accessory?.retailPriceExcludingVat ?? 0,
          false,
        ),
      );
    } else {
      const accessoryKey = `${VehicleConfig.ACCESSORY}_${accessory?.customId || ''}`;
      initialCartAccessory.push(
        prepareVehicleConfig(
          accessoryKey,
          accessory?.customId,
          accessory?.name,
          accessory?.priceExcludingVat,
          accessory?.vatCode,
        ),
      );
      initialArticleAccessory.push(
        ...prepareArticles(
          accessoryKey,
          accessory?.priceExcludingVat,
          accessory?.vatCode,
          ArticleType.ACCESSORY,
          accessory?.retailPriceExcludingVat ?? 0,
          accessory?.isRecommended,
        ),
      );
    }
  });

  return { initialCartAccessory, initialArticleAccessory };
};

export const addInitialEquipment = (order: IOrder, equipmentData: Partial<IVehicleEquipment>[], configuration: IConfiguration = null) => {
  const isStockSaleOrder = isValidStockSaleOrder(order, configuration)

  const initialEquipments: ICartSummaryData[] = [];
  equipmentData?.forEach((equip) => {
    let equipKey = '';
    if (
      equip?.type === VehicleEquimentTypeEnum.Selectable &&
      !equipmentCategoryForKeyCreation?.includes(equip?.category as VehicleEquipmentCategoryEnum)
    )
      equipKey = `${VehicleConfig.SELECTABLE_EQUIPMENT}_${equip?.pimId || ''}`;
    else if (
      equip?.type === VehicleEquimentTypeEnum.Selectable &&
      equip?.category === VehicleEquipmentCategoryEnum.Listverk
    )
      equipKey = VehicleEquipmentCategoryEnum.Listverk;
    else if (
      equip?.type === VehicleEquimentTypeEnum.Selectable &&
      equip?.category === VehicleEquipmentCategoryEnum.Taklakk
    )
      equipKey = VehicleEquipmentCategoryEnum.Taklakk;
    else equipKey = VehicleConfig.RIM;

    initialEquipments.push(
      prepareVehicleConfig(
        isStockSaleOrder ? `${VehicleConfig.SELECTABLE_EQUIPMENT}_${equip?.sku || ''}` : equipKey,
        equip?.sku,
        equip?.name,
        equip?.priceExcludingVat,
        equip?.vatCode,
        false,
        equip?.retailPrice
      ),
    );
  });

  return initialEquipments;
};

export const locationList: ICartLocationProps[] = [
  {
    id: LocationIds.OWN,
    name: LocationNames.OWN,
    isSelected: false,
  },
  {
    id: LocationIds.OTHER,
    name: LocationNames.OTHER,
    isSelected: false,
  },
  {
    id: LocationIds.PORT,
    name: LocationNames.PORT,
    isSelected: false,
  },
];

export const stockLocation: ICartLocationProps[] = [
  {
    id: LocationIds.PORT,
    name: LocationNames.PORT,
    isSelected: false,
  },
];

export const fetchColorInterior = (
  modelId: string = null,
  variantId: string = null,
  data: IVehicleModel[] = [],
): [IVarnish[], IInterior[]] => {
  if (modelId && variantId && data) {
    const currentModelData: IVehicleModel[] = data?.filter((model) => model?.id === modelId);

    if (currentModelData?.length) {
      const currentVariantData: IModelPackages[] = currentModelData?.[0]?.salesPackages?.filter(
        (salesPack) => salesPack?.id === variantId,
      );
      const reOrderColors: IVarnish[] = moveRecommendedOnTop<IVarnish>(currentVariantData?.[0]?.colors);
      const reOrderInterior: IInterior[] = moveRecommendedOnTop<IInterior>(currentVariantData?.[0]?.interiors);
      return [reOrderColors, reOrderInterior];
    }
    return [[], []];
  }
  return [[], []];
};

export const fetchVehicles = (
  packageSkus: string[],
  make: MAKE,
  vehicleList: IVehicleStock[],
  isBaseModel: boolean,
) => {
  let vehicleWithOptions: IVehicleStock[] = [];
  packageSkus?.forEach((currentSku) => {
    const validVehicles: IVehicleStock[] = getVehicleListWithSKUFilter(currentSku, make, vehicleList, isBaseModel);
    vehicleWithOptions = [...vehicleWithOptions, ...validVehicles];
  });
  return vehicleWithOptions;
};

export const getPEUBaseModelFilteredList = (vehicles: IVehicleStock[], make: MAKE) => {
  return vehicles;
};

export const getVehiclesForBaseModel = (
  packageSkus: string[],
  offerMake: MAKE,
  vehicleList: IVehicleStock[],
  validLocationIds: string[],
  vehicleReservedStatus: boolean,
  isBaseModelAvailable: boolean,
) => {
  const vehicleWithOptions: IVehicleStock[] = fetchVehicles(packageSkus, offerMake, vehicleList, isBaseModelAvailable);
  const validVehicles: IVehicleStock[] = differenceBy(vehicleList, vehicleWithOptions, 'serialNumber');
  const baseModelFilterList = getPEUBaseModelFilteredList(validVehicles, offerMake);
  if (baseModelFilterList?.length > 0) {
    return mapIsDisableVehicles(baseModelFilterList, validLocationIds, vehicleReservedStatus);
  }
  return [];
};

export const getModelCode = (sku: string, modelOptions: HashMap) => {
  let { articleNumber: modelCode, options } = getPimCodeComponents(sku);

  if (!modelOptions?.[options?.toString() || '']) {
    modelCode = sku;
  }
 
  return modelCode;
}

export const getAvailableBaseModel = (packageSkus: string[], modelOptions: HashMap) => {
  const baseModel: IBaseModelCode = {};
  let isBaseModelAvailable = false;
  let isBaseModel = false;
  packageSkus?.forEach((sku) => {
    let { articleNumber, options } = getPimCodeComponents(sku);

    if (!getEnumKey(options, modelOptions)) {
      articleNumber = sku;
      options = '';
      isBaseModel = true;
    }
    baseModel[articleNumber] = {
      modelCodeCount: (baseModel?.hasOwnProperty(articleNumber) ? baseModel?.[articleNumber]?.modelCodeCount : 0) + 1,
      options,
      isBaseModel,
    };
  });
  if (Object.keys(baseModel)?.length > 0) {
    const baseModelList = Object.keys(baseModel)?.filter(
      (key) => baseModel?.[key]?.modelCodeCount > 0 && baseModel?.[key]?.isBaseModel,
    );
    isBaseModelAvailable = !isEmpty(baseModelList);
  }
  return { isBaseModelAvailable, baseModel };
};

export const isBaseModel = (baseModel: IBaseModelCode, selectedSku: string) => {
  if (!Object.keys(baseModel)?.length) {
    return false;
  }
  return Object.keys(baseModel)?.filter((sku) => sku === selectedSku)?.length > 0;
};

export const getPackageSkus = (vehicleModelData: IVehicleModel[], vehicleModelId: string) => {
  const currentVehicleModel = vehicleModelData?.filter((vehicle) => vehicle?.id === vehicleModelId);
  return currentVehicleModel?.[0]?.packageSkus || [];
};

export const removeNullOrUndefindValuesFromArray = (initialCartData: ICartSummaryData[]): ICartSummaryData[] => {
  const updatedInitalCartData = initialCartData?.filter((value) => !isNil(value));
  return updatedInitalCartData;
};

export const createStockKey = (group: string, vehicleModelCode: string) =>
  `${group && vehicleModelCode ? `${group}-${formatKey(vehicleModelCode)}` : ''}`;

export const prepareVehicleModel = (
  vehicleConfig: IVehicleConfiguration,
  pimSnapshot: IPimSnapshot,
  order: IOrder,
  isStockSaleOrder: boolean,
) => {
  const model_id = isStockSaleOrder ? VehicleConfig.MODEL : vehicleConfig?.model?.id;
  const model_name = isStockSaleOrder ? order?.vehicles?.[0]?.model?.name : vehicleConfig?.model?.name;

  return prepareVehicleConfig(
    VehicleConfig.MODEL,
    model_id,
    model_name,
    pimSnapshot?.carPriceExclVatAndFees,
    pimSnapshot?.vatCode,
  );
};

export const prepareVehicleVariant = (
  vehicleConfig: IVehicleConfiguration,
  pimSnapshot: IPimSnapshot,
  order: IOrder,
  isStockSaleOrder: boolean,
) => {
  const variant_id = isStockSaleOrder ? VehicleConfig.VARIANT : vehicleConfig?.id;
  const variant_name = isStockSaleOrder ? order?.vehicles?.[0]?.model?.type : vehicleConfig?.salesPackages?.[0]?.name;

  if (variant_name || pimSnapshot?.packagePriceExclVat) {
    return prepareVehicleConfig(
      VehicleConfig.VARIANT,
      variant_id,
      variant_name,
      pimSnapshot?.packagePriceExclVat,
      pimSnapshot?.vatCode,
      pimSnapshot?.packagePriceExclVat === 0,
    );
  }
};

export const prepareDeliveryChargeInput = (deliveryCharge: number, vatCode: string) => {
  const cartInput = prepareVehicleConfig(
    VehicleConfig.DELIVERY_CHARGE,
    '',
    'Leveringsomkostninger',
    deliveryCharge,
    vatCode,
  );

  const articleInput = prepareArticles(
    VehicleConfig.DELIVERY_CHARGE,
    deliveryCharge,
    vatCode,
    ArticleType.DELIVERY,
    0,
    false,
  );

  return [cartInput, articleInput];
};

export const prepareDiscountInput = (
  discount: number,
  pimCode: string,
  vehicleList: Partial<IVehicleConfiguration[]>,
  order?: IOrder,
) => {
  const selectedVariant = vehicleList?.find((vehicle) => vehicle?.sku === pimCode);
  let campaignDiscount = discount;
  // Consider exising dicount value from DB
  if (selectedVariant?.sku === get(order, 'orderReference.pimCode')) {
    const latestMargin = getLatestMargin(order?.margins);
    campaignDiscount = latestMargin?.campaignDiscount || 0;
  }
  return [
    ...prepareArticles(
      VehicleConfig.DISCOUNT,
      Math.abs(campaignDiscount) ?? 0,
      VatCode.NOT_APPLICABLE,
      ArticleType.DISCOUNT_AMOUNT,
    ),
    ...prepareArticles(
      VehicleConfig.CUSTOMERADVANTAGE,
      Math.abs(campaignDiscount) ?? 0,
      VatCode.NOT_APPLICABLE,
      ArticleType.CUSTOMERADVANTAGE,
    ),
  ];
};

export const prepareTyresDefaultInputDuringSelection = (
  pimCode: string,
  vehicleList: Partial<IVehicleConfiguration[]>,
  order?: IOrder,
) => {
  let tyreCartInput = null;
  let tyreArticleInput = null;
  const selectedVarient = vehicleList?.find((vehicle) => vehicle?.sku === pimCode);

  /* In case same sales-package is selected as order then tyres 
     should be fallback to the order-data if exists
   */
  if (selectedVarient?.sku === get(order, 'orderReference.pimCode')) {
    const selectedTyreFromOrder = order?.accessories?.find(
      (accessory) => accessory?.accessoryType?.toString() === AccessoryType[AccessoryType.TYRE],
    );
    if (!selectedTyreFromOrder) {
      return [tyreCartInput, tyreArticleInput];
    }

    tyreCartInput = prepareVehicleConfig(
      VehicleConfig.TYRE,
      // NOTE: Accessory specific pimId will be taken care in Accessory sku changes
      get(selectedTyreFromOrder, 'reference.pimId'),
      selectedTyreFromOrder?.name,
      selectedTyreFromOrder?.priceExcludingVat,
      selectedTyreFromOrder?.vatCode,
      false,
    );

    tyreArticleInput = prepareArticles(
      VehicleConfig.TYRE,
      selectedTyreFromOrder?.priceExcludingVat,
      selectedTyreFromOrder?.vatCode,
      ArticleType.WINTERTIRES,
      selectedTyreFromOrder?.retailPriceExcludingVat,
      false,
    );

    return [tyreCartInput, tyreArticleInput];
  }

  const recommendedTyre = selectedVarient?.tires?.find((tyre) => tyre?.isRecommended);

  if (recommendedTyre) {
    const vatCode = mapAccessoryVatCode(recommendedTyre);
    tyreCartInput = prepareVehicleConfig(
      VehicleConfig.TYRE,
      recommendedTyre?.id,
      recommendedTyre?.name,
      recommendedTyre?.additionalPriceExcludingVat,
      vatCode,
      false,
    );

    tyreArticleInput = prepareArticles(
      VehicleConfig.TYRE,
      recommendedTyre?.additionalPriceExcludingVat,
      vatCode,
      ArticleType.WINTERTIRES,
      recommendedTyre?.retailPriceExcludingVat,
      false,
    );
  }

  return [tyreCartInput, tyreArticleInput];
};

export const validateModelSeriesName = (modelSeries: string = ''): string => {
  const regexPatternForHash = /-/g;
  if (regexPatternForHash?.test(modelSeries)) return `${modelSeries?.replace(regexPatternForHash, '#')}`;
  return modelSeries;
};

export const calculateRetailPrice = (priceExcVat: number, marginPercentage: number) => {
  return calculatePercentageDecrease(priceExcVat, marginPercentage, false, 2);
}




/**
 * This function retrieves selected equipment details from a cart data array based on specific
 * conditions.
 * @param {ICartSummaryData[]} cartData - The `cartData` parameter is an array of objects that contain
 * information about items in the shopping cart. Each object in the array represents a specific item
 * and has properties like `key`, `name`, and `price`.
 * @returns The function `getSelectedEquipmentInCart` returns an array of objects with properties
 * `title` and `value` representing selected equipment in the cart.
 */
export const getSelectedEquipmentInCart = (cartData: ICartSummaryData[]): IEquipmentDetailsProps[] => {
  let selectedEquipmentList: IEquipmentDetailsProps[] = [];
  if (cartData?.length) {
    cartData?.forEach((cartDataObj) => {
      if (
        cartDataObj?.key === VehicleEquipmentCategoryEnum.Listverk ||
        cartDataObj?.key === VehicleConfig.RIM ||
        cartDataObj?.key?.includes(VehicleConfig?.SELECTABLE_EQUIPMENT)
      ) {
        selectedEquipmentList.push({
          title: cartDataObj?.name,
          value: `+ ${formatNumber(cartDataObj?.price)} kr`,
          vatCode: cartDataObj?.vatCode,
        });
      }
    });
  }
  return selectedEquipmentList;
};

/**
 * The function filters out selected equipment from a cart data array based on specific conditions.
 * @param {ICartSummaryData[]} cartData - The `cartData` parameter is an array of objects that
 * represent data related to items in the cart. Each object in the array likely contains information
 * such as the name, key, and other details of the item in the cart.
 * @param {IEquipmentDetailsProps[]} selectedEquipment - The `selectedEquipment` parameter is an array
 * of objects that represent details of equipment selected by a user. Each object in the array contains
 * properties like `title`, `key`, and possibly other details related to the equipment.
 * @returns The function `filterSelectedEquipmentInCart` returns an array of `ICartSummaryData` objects
 * after filtering out any items that match the conditions specified in the function.
 */
export const filterSelectedEquipmentInCart = (
  cartData: ICartSummaryData[],
  selectedEquipment: IEquipmentDetailsProps[],
): ICartSummaryData[] => {
  if (Array.isArray(cartData) && Array.isArray(selectedEquipment)) {
    if (cartData?.length < 1) {
      return [];
    }
    if (selectedEquipment?.length < 1) {
      return cartData;
    }
    return cartData?.filter((cartObj) => {
      return !selectedEquipment?.some((selectedEquipmentObj) => {
        return (
          cartObj?.name === selectedEquipmentObj?.title ||
          cartObj?.key === VehicleEquipmentCategoryEnum.Listverk ||
          cartObj?.key === VehicleConfig.RIM
        );
      });
    });
  }
  return [];
};

//This function will update articles when place factory order from step 1
export const updateArticlesWithOrderAccessoryArticles = (orderArticles: INextArticle[] = [], existingArticles: INextArticle[] = []): INextArticle[] => {
  let accessoriesArticles: INextArticle[] = orderArticles?.filter((article) => article.articleType === ArticleType.ACCESSORY);
  if(accessoriesArticles) {
    //This change is for prevent Accessory article duplication
    let articlesWitoutAccessories = existingArticles?.filter(article => article.articleType !== ArticleType.ACCESSORY)
    return [...articlesWitoutAccessories, ...accessoriesArticles];
  } else {
    return existingArticles;
  }
}

const filterStockByModelCodes = (modelCodes: string[], group: string, vehicleStock): IVehicleStock[] => {
  let selectedVehicleStock: IVehicleStock[] = [];

  //fetch stock of multiple modelIds
  modelCodes?.forEach((currentModelCode) => {
    const key = createStockKey(group, formatKey(currentModelCode)); 
    const stockByKey = vehicleStock?.getStock(key) || [];
    selectedVehicleStock = [...selectedVehicleStock, ...stockByKey];  
  });
  return selectedVehicleStock;
}

const filterStockBySkus = (vehicleStock: IVehicleStock[], skus: string[]): IVehicleStock[] => {
  let validStock: IVehicleStock[] = [];

  //Filter selected stock on SKUs 
  if(vehicleStock?.length) {
    validStock = vehicleStock?.filter((vehicle) => {
      const availableOptions = vehicle?.optionCode?.split(DELIMETER);
      return intersection(skus, availableOptions)?.length;
    })
  }
  return validStock;
}


export const filterVehicleStockForKia = (data: IUniqueModelCodeAndSkus, group: string, vehicleStock): IVehicleStock[] => {
  let vehicleStockBasedOnModelCodes: IVehicleStock[] = filterStockByModelCodes(data?.modelCodes, group, vehicleStock);
  let validStock: IVehicleStock[] = filterStockBySkus(vehicleStockBasedOnModelCodes, data?.skus);
  return validStock;
}

export const getVehicleStockForKiaSku = (vehicleList: IVehicleConfiguration[], group: string, vehicleStock): IVehicleStock[] => {
  const data = getStockEquipmentLevelsFromEquipments([], vehicleList?.[0]);
  return filterVehicleStockForKia(data, group, vehicleStock)
}