import React from 'react';
import header from './imgs/full-header-size.jpg';
import { View, Image } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { SubHeader } from './SubHeader';
import { IOrder, MAKE } from 'next-common';
import { bosBrand, white } from '@next-components/common-styles';
import headerWithoutLogo from './imgs/full-header-without-logo.jpg';
import smartHeader from './imgs/smart-full-header-with-logo.png';
import smartHeaderWithoutLogo from './imgs/smart-full-header-without-logo.png';

const MainHeaderStyle: Style = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: bosBrand,
  color: white,
  height: 70,
  padding: 10,
};

interface IHeaderProps {
  order: IOrder;
  disablePdfLogo: boolean;
}

const MainHeader = (order: IOrder) => <Image source={order?.lead?.make === MAKE.Smart ? smartHeader : header} />;

const MainHeaderWithoutLogo = (order: IOrder) => (
  <Image source={order?.lead?.make === MAKE.Smart ? smartHeaderWithoutLogo : headerWithoutLogo} />
);

export const Header = (props: IHeaderProps) => {
  return (
    <View fixed>
      {props?.disablePdfLogo ? <MainHeader {...props?.order} /> : <MainHeaderWithoutLogo {...props?.order} />}
      <SubHeader {...props?.order} />
    </View>
  );
};
