import { gql } from '@apollo/client';
import { vehicleStockQuery } from 'next-common';

export const vehicleStocksQuery = gql`
  query vehicleStocks($group: String!) {
    vehicleStocks(group: $group) {
      brand
      modelSeries
      modelId
      modelDesc
      colorId
      colorDesc
      serialNumber
      productionDate
      location
      locationId
      locationName
      trackTraceCode
      interiorCode
      noCode
      optionCode
      priceOptions
      status
      vinNumber
      transferRequest
      transferFrom
      transferTo
      availabilityCode
      vehicleStatus {
        sku
        serialNo
        totalCampaignPrice
        vehicleStatus {
          id
          timestamp
          serialNo
          customerNo
          serialNo
          importerOrderNo
          oemOrderNo
          vin
          registrationNo
          customerPoNo
          importerLocation
          acquisitionReason
          partNo
          partDescription
          modelVersionId
          oemPartNumber
          applicationCode
          trackTraceCode
          trackTraceDescription
          changeableTrackTrace
          availabilityCode
          exceptionTransferDisplay
          productionDate
          configurationId
          modelPriceRetailCustomer
          brand
          brandText
          modelSeries
          bodyStyle
          engineType
          importerDiscountAmount
          xmlTimeStamp
          xmlCreatedBy
          reservedDealerNo
          reservedByUser
          noteText
          quotationNo
          actualProductionDate
          site
          orderStatus
          orderDate
          vehicleType
          nextSerialNo
          color
          modelDiscountPercentage
          nstNedCode
          optionString
          modelDiscountAmount
          isGeminiVehicle
          isTemporal
          option {
            characteristicId
            characteristicDescription
            optionId
            optionType
            optionDescription
            optionPriceRetailCustomer
            optionPriceDealer
            isStandard
          }
          accessory {
            characteristicId
            characteristicDescription
            optionId
            optionType
            optionDescription
            optionPriceRetailCustomer
            optionPriceDealer
            discountPercentage
            discountAmount
            taxPercentage
          }
          charge {
            chargeType
            description
            priceBase
            priceIncTaxBase
            basisValue
          }
          prices {
            modelPriceVat
            modelPrice
            totalOptionsVat
            totalOptions
            totalAccessoriesVat
            totalAccessories
            totalChargesVat
            totalCharges
            totalDiscountVat
            totalDiscount
            totalVehicleVat
            totalVehicle
          },
          engineConfiguration{
            code,
            value
          }
        }
      },
      # vehicleImages {
      #   serialNo,
      #   images {
      #     EXT000 {
      #       fileName,
      #       url
      #     },
      #     EXT050  {
      #       fileName,
      #       url
      #     },
      #     EXT180{
      #       fileName,
      #       url
      #     },
      #     INT1{
      #       fileName,
      #       url
      #     },
      #     INT2{
      #       fileName,
      #       url
      #     }
      #   }
      # }
    }
  }
`;

export const vehicleStockBySerialNoQuery = gql`
  ${vehicleStockQuery}
`;

