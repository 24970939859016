import React, { useEffect } from 'react';
import {
  CustomerType,
  ICalculation,
  ICustomer,
  IOrder,
  IVehicleConfiguration,
  MBApiCalculationFlag,
} from 'next-common';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { Button } from '@next-components/cta';
import { SigningPageStyled } from './SigningPage.styled';
import {
  IFinancingInfoProps,
  INotificationPageProps,
  IVehiclePageProps,
  Routes,
  IVehicleStock,
  statusChecked,
  ICentralisedProperties,
  isStockOrder,
  useOrder,
} from '../../../common';
import { SigningContract } from './sales-signing-contract';
import { SigningAgreement } from './sales-signing-agreement';
import { filterSelectedEquipmentInCart, getOriginalNominalInterest, getSelectedEquipmentInCart, isOrderEditable, checkStatus } from '../../../utils';
import {
  InterestWarning,
  ReservedWarning,
  CustomerValidationWarning,
  ServiceContractWarning,
  ErrorNotification,
  ValidationErrorCode,
} from '../../notifications';
import { MessagebarSeverityLevel } from '@next-components/messagebar';
import { ICartProps, Cart } from '@next-components/cart';
import { useNavigate } from 'react-router-dom';
import { IGetServiceContract, VehicleStockStatus } from 'sales-common';
import { get } from 'lodash';
import { getNominalInterest, getRequestedNominalInterest } from '../sales-common/FinancingDialog';

export interface ISigningPageProps
  extends IVehiclePageProps,
    INotificationPageProps,
    IFinancingInfoProps,
    ICentralisedProperties {
  nonExactMatchCount?: number;
  exactMatchCount?: number;
  onCustomerType(customerType: CustomerType): void;
  order?: IOrder;
  vehicle?: IVehicleConfiguration;
  showCustomerPopup?: boolean;
  setShowCustomerPopup?(value: boolean): void;
  availableVehicles?: IVehicleStock[];
  isUnavailablePimData?: boolean;
  softLockOrderId?: string;
  vehicleUnavailable?: boolean;
  cartData?: ICartProps;
  serviceContract?: IGetServiceContract;
  serviceContractLoading?: boolean;
  selectedSerialNo?: number;
  availableVehicleList?: any[];
  isElectricCar?:boolean;
  selectedVehicleStockStatus?: string;
  selectedVehicleStock?: IVehicleStock;
}

const checkingChangedInterest = (oldInterest: number, newInterest: number) => {
  const differ = oldInterest - newInterest;
  const differInterest = parseFloat(differ?.toFixed(2));
  return Math.abs(differInterest) > 0.6;
};

const hasChangedInterest = (offer: ICalculation) => {
  const originalInterest = getOriginalNominalInterest(offer);
  const updatedInterest =  getRequestedNominalInterest(offer?.financing) || getNominalInterest(offer?.financing);
  return checkingChangedInterest(originalInterest, updatedInterest);
};

export const SigningPage = (props: ISigningPageProps) => {
  const vehicle: IVehicleConfiguration = props?.vehicle;
  const order: IOrder = useOrder(props);
  const [warnings, setWarnings] = React.useState<string[]>([]);
  const [missingCustomerFields, setMissingCustomerFields] = React.useState<string[]>([]);
  const [validCustomer, setValidCustomer] = React.useState(true);
  const navigate = useNavigate();
  const getIsReserved = (serialNo: string) => {
    if (!serialNo) return;
    const isVehicleAvailable = props.availableVehicles?.filter(
      (vehicle) => vehicle?.serialNumber?.toString() === serialNo,
    );
    if (!isOrderEditable(order, props?.configuration) && !isVehicleAvailable?.length) {
      if (props?.selectedVehicleStockStatus !== '' && props?.selectedVehicleStockStatus === VehicleStockStatus.FREE) {
        props.clearNotifications('reservedWarning');
        removeWarning('reservedWarning');
      } else {
        props.raiseNotification(
          <ReservedWarning
            key="reservedWarning"
            message=""
            serialNo={serialNo}
            onClose={() => props?.clearNotifications('reservedWarning')}
            severityLevel={MessagebarSeverityLevel.ERROR}
          />,
        );
        setWarnings([...warnings, 'vehicleReserved']);
      }
    }
  };

  const runOrderValidations = () => {
    if (order?.vehicleOrderStatus?.orderNumber) return;
    props.clearNotifications('financeWarning');
    removeWarning('applicationValidation');
  };

  const runVehicleAvailabilityValidation = () => {
    if (!props.isVehicleValidForReserveOrSign && !order?.reserveDetails?.quotationNumber) {
      props.raiseNotification(
        <ReservedWarning
          key="reservedWarning"
          message=""
          serialNo={order?.vehicles?.[0]?.serialNo}
          onClose={() => props?.clearNotifications('reservedWarning')}
          severityLevel={MessagebarSeverityLevel.ERROR}
        />,
      );
      setWarnings([...warnings, 'locationIdMismatch']);
    } else {
      props.clearNotifications('reservedWarning');
      removeWarning('locationIdMismatch');
    }
    if (order?.vehicles?.length > 0 && !order?.reserveDetails?.quotationNumber && props.softLockOrderId !== order?.id && order?.vehicleOrderStatus === null) {
      removeWarning('vehicleReserved');
      getIsReserved(order?.vehicles?.[0]?.serialNo);
    }
  };

  const runOfferValidations = (chosenOffer: ICalculation) => {
    removeWarning('interestChanged');
    if (chosenOffer && hasChangedInterest(chosenOffer) && chosenOffer?.comments?.length <= 0) {
      props.raiseNotification(
        <InterestWarning
          key="interestWarning"
          offer={chosenOffer}
          onClose={() => props?.clearNotifications('interestWarning')}
        />,
      );
      setWarnings([...warnings, 'interestChanged']);
    }
  };

  const runServiceContractValidations = (order: IOrder) => {
    removeWarning('serviceContract');
    if (!order?.serviceContractBosId && !order?.salesContractPdfId) {
      props.raiseNotification(
        <ServiceContractWarning
          key="serviceContractWarning"
          validationErrors="Feil under opprettelse av servicekontrakt PDF."
          severityLevel={MessagebarSeverityLevel?.ERROR}
          onClose={() => props?.clearNotifications('serviceContractWarning')}
        />,
      );
      setWarnings([...warnings, 'serviceContract']);
    }
  };

  const setMissingCustomerParams = (fieldName: string) => {
    setMissingCustomerFields((missingFields) => [...missingFields, fieldName]);
    return false;
  };

  const validateIfCustomerInfoExist = (customerField: any, missingCustomerParam: string): boolean => {
    return customerField ? true : setMissingCustomerParams(missingCustomerParam);
  };

  const runCustomerValidations = (customer: ICustomer) => {
    props.clearNotifications('customerWarning');
    removeWarning('customerWarning');
    setMissingCustomerFields([]);
    const bosCustomer = get(order, 'customer.bosCustomer');
    let userValidation = true;
    const isBusiness = get(bosCustomer, 'customerType') === CustomerType[CustomerType.BUSINESS];
    if (isBusiness) {
      userValidation =
        validateIfCustomerInfoExist(bosCustomer?.organizationName, 'Organisasjonsnavn') &&
        validateIfCustomerInfoExist(bosCustomer?.organizationNo, 'Org.nr');
    } else {
      userValidation =
        validateIfCustomerInfoExist(bosCustomer?.firstName, 'Fornavn') &&
        validateIfCustomerInfoExist(bosCustomer?.lastName, 'Etternavn');
    }
    const cityValidation = validateIfCustomerInfoExist(bosCustomer?.city, 'Poststed');
    const zipCodeValidation = validateIfCustomerInfoExist(bosCustomer?.zipCode, 'Postnummer');
    const emailValidation = validateIfCustomerInfoExist(bosCustomer?.email, 'E-post');
    const phoneValication = validateIfCustomerInfoExist(bosCustomer?.phoneMobile, 'Telefonnummer');
    const validation =
      userValidation && cityValidation && zipCodeValidation && emailValidation && phoneValication;
    setValidCustomer(validation);
    if (!validation && !props.showCustomerPopup) {
      props.raiseNotification(
        <CustomerValidationWarning
          key="customerWarning"
          fields={missingCustomerFields}
          onClose={() => props?.clearNotifications('customerWarning')}
          severityLevel={MessagebarSeverityLevel.ERROR}
        />,
      );
    }
  };

  const removeWarning = (key: string) => {
    const index = warnings?.findIndex((warning) => warning === key);
    if (index >= 0) {
      const updateWarnings = warnings?.filter((warning) => warning !== key);
      setWarnings(updateWarnings);
    }
  };

  const runVehicleValidations = () => {
    props.raiseNotification(
      <ErrorNotification
        errorCode={ValidationErrorCode.SIGNING_VEHICLE_VALIDATION}
        key="vehicleMissingWarning"
        severityLevel={MessagebarSeverityLevel.ERROR}
        onClose={() => props?.clearNotifications('vehicleMissingWarning')}
      />,
    );
    setWarnings([...warnings, 'vehicleMissing']);
  };

  useEffect(() => {
    if (order?.chosenOffer && order?.customer) {
      runOrderValidations();
    }
    if (order?.chosenOffer) runOfferValidations(order?.chosenOffer);
    if (order?.chosenOffer && !order?.vehicles?.length) runVehicleValidations();
    if (order?.customer) runCustomerValidations(order?.customer);
    if (order?.chosenOffer?.serviceContract) runServiceContractValidations(order);
  }, [order?.vehicleOrderStatus, order?.chosenOffer, order?.customer]);

  useEffect(() => {
    if (order?.vehicles && !props.isLoading) runVehicleAvailabilityValidation();
  }, [order?.vehicles, props.isLoading, props.isVehicleValidForReserveOrSign, props.availableVehicles]);

  useEffect(() => {
    if (props.isUnavailablePimData) {
      setWarnings([...warnings, 'pimDataIsUnavailable']);
    }
  }, [props.isUnavailablePimData]);

  useEffect(() => {
    if (statusChecked()?.isNoFactoryCode) {
      setWarnings([...warnings, 'factoryCodeUnavailable']);
    }
  }, [statusChecked()?.isNoFactoryCode]);

  if (!order ||
    (isStockOrder(order?.lead?.orderType) && !order?.chosenOffer) ||
    props.serviceContractLoading ||
    props.isLoading
  ) {
    return <LoadingSpinner isModal={true} />;
  }

  return (
    <SigningPageStyled className="mainContainer">
      <div className="leftContainer signingPageStyle">
        {order?.chosenOffer && order?.customer && (
          <>
            <SigningAgreement
              order={order}
              vehicle={vehicle}
              serviceContract={props.serviceContract}
              cartData={props?.cartData}
              finalPrice={order?.vatCalculation}
              configuration={props?.configuration}
              isUnavailablePimData={props.isUnavailablePimData}
            />
            <SigningContract
              orderId={order.id}
              order={order}
              hasWarnings={warnings.some((warning) => warning)}
              raiseNotification={props.raiseNotification}
              validCustomer={validCustomer}
              vehicleUnavailable={props?.vehicleUnavailable ?? false}
              clearNotifications={props?.clearNotifications}
              configuration={props.configuration}
              isVehicleValidForReserveOrSign={props.isVehicleValidForReserveOrSign}
            />
          </>
        )}
        {!order?.customer && (
          <div className="missing-info">
            <h3>Kunde er ikke valgt</h3>
            <p>Du må ha valgt kunde for å kunne gå videre.</p>
            <p>Vennligst gå tilbake til Kunde-steget for å gjøre dette.</p>
            <Button
              disabled={props?.isUnavailablePimData}
              onClick={() => props.setShowCustomerPopup(!props?.showCustomerPopup)}>
              Til kunde
            </Button>
          </div>
        )}
        {!isStockOrder(order?.lead?.orderType) && !order?.chosenOffer && (
          <div className="missing-info">
            <h3>Finansieringstype er ikke valgt</h3>
            <p>Du må ha valgt ett finansieringsalternativ for å kunne gå videre.</p>
            <p>Vennligst gå tilbake til Finans-steget for å gjøre dette.</p>
            <Button
              disabled={props?.isUnavailablePimData}
              onClick={() => navigate(Routes.getFinancePage(props.orderId))}>
              Til finansiering
            </Button>
          </div>
        )}
      </div>
      <div>
        <Cart
          exactMatchCount={props.exactMatchCount}
          nonExactMatchCount={props.nonExactMatchCount}
          make={props.cartData?.make}
          modelSeries={props.cartData?.modelSeries}
          prices={props.finalPrice}
          image={props.cartData?.image}
          data={filterSelectedEquipmentInCart(props.cartData?.data, getSelectedEquipmentInCart(props.cartData?.data))}
          equipmentDetails={getSelectedEquipmentInCart(props.cartData?.data)}
          isVehicleSelected={props.isVehicleSelected}
          serialNo={props?.selectedSerialNo}
          selectedLocation={props.vehicleLocation}
          selectedStatus={props.vehicleStatus}
          isFactoryOrder={props.isFactoryOrder}
          expectedProductionDate={props.expectedProductionDate}
          vehicleCount={props.availableVehicleList?.length}
          isElectricCar={props.isElectricCar}
          showMBApiWarning={order?.externalTaxCalculation === MBApiCalculationFlag.FETCH_FAILED}
        />
      </div>
    </SigningPageStyled>
  );
};
