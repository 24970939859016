import React from 'react';
import { OrderAccordion } from '../../../sales-common/OrderAccordion';
import { IVehicleColor, VehicleColor } from './VehicleColor';
import { VehicleRim, IVehicleRims } from './VehicleRims';
import { IEquipmentTypes, MAKE, VehicleEquipmentCategoryEnum } from 'next-common';
import { VehicleSelectableEquipment } from './VehicleSelectableEquipment';
import { IListverkChangeProps } from '../ResultGrid';
import { VehicleTaklakk } from './VehicleTaklakk';

export interface ISectionExterior extends IVehicleColor, IVehicleRims {
  isOpen: boolean;
  isDisabled: boolean;
  selectedEquipmentIds?: IEquipmentTypes[];
  onSelectEquipmentChange?: (data: IEquipmentTypes[], removeId?: string, isOptimisticUpdate?: boolean) => void;
  vehicleBrand?: MAKE;
  selectedTaklakk?: IEquipmentTypes;
  onTaklakkChange?: (data: IListverkChangeProps) => void;
}

export const SectionExterior = (props: ISectionExterior) => {
  const {
    data,
    selectedColorId,
    isOpen,
    isDisabled,
    customerType,
    disabled,
    selectedRims,
    onColorChange,
    onRimChange,
    onSelectEquipmentChange,
    selectedEquipmentIds,
    updatePrices,
    onTaklakkChange,
    vehicleBrand,
    selectedTaklakk
  } = props;

  const commonProps = {
    data,
    disabled,
    updatePrices,
  };

  return (
    <OrderAccordion label="Eksteriør" isOpen={isOpen} isDisabled={isDisabled}>
      <VehicleColor {...commonProps} selectedColorId={selectedColorId} onColorChange={onColorChange} />
      <VehicleSelectableEquipment
        {...commonProps}
        selectedEquipmentIds={selectedEquipmentIds}
        onSelectEquipmentChange={onSelectEquipmentChange}
        customerType={customerType}
        equipmentCategory={VehicleEquipmentCategoryEnum.Exterior}
      />
      <VehicleRim {...commonProps} selectedRims={selectedRims} onRimChange={onRimChange} customerType={customerType} />
      {vehicleBrand === MAKE.Smart && (
        <VehicleTaklakk {...commonProps} selectedTaklakk={selectedTaklakk} onTaklakkChange={onTaklakkChange} />
      )}
    </OrderAccordion>
  );
};
