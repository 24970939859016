import { get } from 'lodash';
import { IEquipmentTypes } from 'next-common';
import React, { useEffect, useState } from 'react';
import { useDisableSkus, useEnableSkus, useSkusPriceUpdate, useUnselectSkus } from '../../../../../common';
import { calculateRetailPrice, createSkuListFromValue, eventEmitter, Events, prepareImageObject, priceFormatter, VehicleConfig } from '../../../../../utils';
import { IModelPackages } from '../../VehiclePickerPage';
import { IRimChangeProps } from '../ResultGrid';
import { SwatchGrid } from '../ResultGrid.styled';
import { VehicleSelectableDescription } from './VehicleSelectableDescription';
import { v4 as uuidv4 } from 'uuid';
import { Card } from '@next-components/card';
import { CardType } from '@next-components/common-types';
import { MessagebarSeverityLevel } from '@next-components/messagebar';

export interface IVehicleRims {
  data: IModelPackages;
  disabled: boolean;
  customerType?: string;
  selectedRims: IEquipmentTypes;
  onRimChange: (data: IRimChangeProps) => void;
  updatePrices: () => void;
}

export const VehicleRim = (props: IVehicleRims) => {
  const { data, disabled, selectedRims, onRimChange } = props;
  const { isDisableSKUonChange, isDisableSKUonLoad, disableSku, onloadDisableSku } = useDisableSkus();
  const { isEnableSKUonChange } = useEnableSkus();
  const { getUnselectSkuFlag } = useUnselectSkus();
  const priceUpdate = useSkusPriceUpdate();
  const [isPrepared, setIsPrepared] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [activeDesc, setActiveDesc] = useState<IEquipmentTypes>(null);

  useEffect(() => {
    eventEmitter.subscribe(Events.SKU_RULES_PREPARED, setIsPrepared);
    return () => {
      eventEmitter.unsubscribe(Events.SKU_RULES_PREPARED);
    };
  }, []);

  const getPrice = (rim: IEquipmentTypes) => {
    return priceUpdate?.has(rim?.sku) ? Number(priceUpdate?.get(rim?.sku)) : rim?.priceExcludingVat;
  };

  useEffect(() => {
    //Update prices in cart & VAT Calculation
    if (selectedRims?.id) {
      props.updatePrices();
    }
  }, [selectedRims, priceUpdate]);

  const onChangeRim = (rim: IEquipmentTypes) =>
    onRimChange({
      rim: selectedRims?.id === rim?.id ? null : rim,
      price: getPrice(rim),
      vatCode: rim?.vatCode,
      isOptimisticUpdate: false,
      configType: VehicleConfig.RIM,
      dealerPriceExclVat: calculateRetailPrice(getPrice(rim), rim?.marginPercentage),
    });

  const isDisabled = (sku: string) => {
    const isDisable = isDisableSKUonChange(sku) ? true : isEnableSKUonChange(sku) ? false : isDisableSKUonLoad(sku);
    return isDisable;
  };

  const isDisableRuleApply = (rim: IEquipmentTypes) => {
    const isDisable = isDisabled(rim?.sku);
    const isUnselectedSku = getUnselectSkuFlag(rim?.sku);
    if ((isDisable || isUnselectedSku) && isPrepared && selectedRims?.id === rim?.id) {
      onRimChange({
        rim: null,
        price: getPrice(rim),
        vatCode: rim?.vatCode,
        isOptimisticUpdate: true,
        configType: VehicleConfig.RIM,
        dealerPriceExclVat: calculateRetailPrice(getPrice(rim), rim?.marginPercentage),
      });
    }
    return isDisable;
  };

  useEffect(() => {
    const isDisable = isDisabled(selectedRims?.sku);
    if (!isDisable && isPrepared && selectedRims?.id) {
      const rim = data?.rims?.filter((rim) => selectedRims?.id === rim?.id)?.[0];
      if (rim) {
        onRimChange({
          rim: selectedRims,
          price: getPrice(rim),
          vatCode: rim?.vatCode,
          isOptimisticUpdate: true,
          configType: VehicleConfig.RIM,
          dealerPriceExclVat: calculateRetailPrice(getPrice(rim), rim?.marginPercentage),
        });
      }
    }
  }, [selectedRims, isDisabled(selectedRims?.sku), data?.rims]);

  const isDataAvailable = (rim: IEquipmentTypes) => {
    return get(rim, 'description');
  };

  const setCurrentData = (rim: IEquipmentTypes) => {
    setActiveDesc(rim);
    setActive(!active);
  };

  const getSkuNote = (sku: string) => {
    return createSkuListFromValue(disableSku, onloadDisableSku, sku);
  }

  return (
    <>
      <span className="label">Felger</span>
      {data?.rims?.length <= 0 || !data ? (
        <p> Ingen Felger tilgjengelig </p>
      ) : (
        <SwatchGrid className="vehicleRim">
          {data?.rims?.map((rim) => {
            const isSelected = selectedRims?.id === rim?.id;
            const customPriceTag = `${getPrice(rim) > 0 ? '+' : ''}  ${priceFormatter(getPrice(rim), rim)}`;

            return (
              <Card
                key={uuidv4()}
                id={rim?.id}
                title={rim?.name}
                customPriceTag={customPriceTag}
                bosSwatch={prepareImageObject(rim?.image?.[0])}
                type={CardType.BosCard}
                selected={isSelected}
                isDisabled={disabled || isDisableRuleApply(rim)}
                displayTag
                tagValue={rim?.sku}
                updateSelected={() => onChangeRim(rim)}
                disableTag={!isDataAvailable(rim)}
                onTagClick={() => setCurrentData(rim)}
                alertMessage={{
                  message: isDisabled(rim?.sku) ? getSkuNote(rim?.sku) : '',
                  typeOfAlert: MessagebarSeverityLevel.WARNING
                }}
              />
            );
          })}
          <VehicleSelectableDescription data={activeDesc} active={active} onClose={() => setActive(!active)} />
        </SwatchGrid>
      )}
    </>
  );
};
