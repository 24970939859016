import React from 'react';
import SalesWizardPage from '../../pages/SalesWizardPage/SalesWizardPage';
import { IVehicleUpdate } from './SalesWizard';
import { IConfiguration } from 'next-common';

interface ISalesWizardMasterProps {
  configuration?: IConfiguration;
  manageSocket?(group?: string, vehicleUpdate?: (update: IVehicleUpdate) => void): void;
}

const SalesWizardMaster = (props: ISalesWizardMasterProps) => {

  return <SalesWizardPage manageSocket={props.manageSocket} configuration={props.configuration} />;
};

export default SalesWizardMaster;
