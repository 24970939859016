import { endOfMonth, format, isAfter, isEqual, isWithinInterval, startOfMonth } from 'date-fns';
import {
  ReferenceType,
  IBosApiVehicleMarginCalculationActuals,
  IMarginUniqueByReferenceNumber,
  IBosApiVehicleMarginCalculationDetails,
} from 'next-common';
import { IDropdownOption, InputType } from '@next-components/common-types';
import axios from 'axios';
import { isArray, orderBy, differenceBy, unionBy, findIndex, intersectionBy, has, get, forEach } from 'lodash';
import { getHeader } from '../axiosHeaderConfig';
import { handleRefreshToken } from '../../utils';
import { apimEndpoint } from '../../common';

export const getMarginUser = async (email: string) => {
  const fetchHeader = await getHeader();
  return (
    axios
      .get(`${apimEndpoint ?? window.location.origin}/api/margin/auth/${email}`, fetchHeader)
      .then((result) => {
        return result.data?.response;
      })
      // tslint:disable-next-line:no-console
      .catch((err) => {
        if (err?.response?.status === 401) {
          handleRefreshToken();
        }
        console.error(err);
      })
  );
};

export const calculateSum = (obj, field) => {
  if (obj) {
    return obj?.map((items) => items[field])?.reduce((prev, curr) => Math.round(+prev) + Math.round(+curr), 0);
  }
  return 0;
};

export const copyToClipboard = (content: string) => {
  const element = document.createElement('textarea');
  element.value = content;
  document.body.appendChild(element);
  element.select();
  document.execCommand('copy');
  document.body.removeChild(element);
};

export const getEndOfMonth = (dateValue: Date) => {
  const year = dateValue.getFullYear();
  const month = dateValue.getMonth();
  return new Date(year, month + 1, 0);
};

export const getStartOfMonth = (dateValue: Date) => {
  const year = dateValue?.getFullYear();
  const month = dateValue?.getMonth();
  return new Date(year, month, 1);
};

export interface IMarginPriceFields {
  salesPrice: number;
  purchasePrice: number;
  importerDiscount: number;
  otherCharges: number;
  marginPercentage: number;
  marginActual: number;
}

export const removeZeroedData = (
  data: IBosApiVehicleMarginCalculationDetails[] | undefined,
  query: IMarginPriceFields,
) => {
  return data?.filter((item) => {
    for (const key in query) {
      if (item[key] === undefined || +item[key] !== +query[key]) return true;
    }
    return false;
  });
};

export const isMargingWithSales = (margin: IBosApiVehicleMarginCalculationDetails): boolean => {
  if (has(margin, 'salesPrice')) {
    return Math.round(+margin?.netSalesPrice) !== 0;
  } else {
    return true;
  }
};

export const isMarginInFilteredMonths = (
  margin: IBosApiVehicleMarginCalculationDetails,
  monthsSelectedFilters: IDropdownOption<string>[],
): boolean => {
  for (let month of monthsSelectedFilters) {
    const monthStart = startOfMonth(new Date(get(month, 'value')));
    const monthEnd = endOfMonth(new Date(monthStart));
    if (
      isWithinInterval(new Date(get(margin, 'invoiceDate')), { start: monthStart, end: monthEnd }) ||
      isEqual(new Date(get(margin, 'invoiceDate')), monthStart) ||
      isEqual(new Date(get(margin, 'invoiceDate')), monthEnd)
    )
      return true;
  }
  return false;
};

export const isMarginForFilteredMakes = (
  margin: IBosApiVehicleMarginCalculationDetails,
  makesSelectedFilters: IDropdownOption<string>[],
): boolean => {
  if (findIndex(makesSelectedFilters, (filter) => get(filter, 'value') === get(margin, 'make')) === -1) return false;

  return true;
};

export const isMarginForFilteredModels = (
  margin: IBosApiVehicleMarginCalculationDetails,
  modelsSelectedFilters: IDropdownOption<string>[],
): boolean => {
  if (findIndex(modelsSelectedFilters, (filter) => get(filter, 'value') === get(margin, 'model')) === -1) return false;

  return true;
};

export const isMarginForFilteredSerialNumbers = (
  margin: IBosApiVehicleMarginCalculationDetails,
  serialNumbersSelectedFilters: IDropdownOption<number>[],
): boolean => {
  if (findIndex(serialNumbersSelectedFilters, (filter) => get(filter, 'value') === get(margin, 'serialNo')) === -1)
    return false;

  return true;
};

export const isMarginForFilteredDepartmentNumbers = (
  margin: IBosApiVehicleMarginCalculationDetails,
  departmentNumbersSelectedFilters: IDropdownOption<number>[],
): boolean => {
  if (
    findIndex(departmentNumbersSelectedFilters, (filter) => get(filter, 'value') === get(margin, 'departmentNo')) === -1
  )
    return false;

  return true;
};

export const isMarginForFilteredSalesPersons = (
  margin: IBosApiVehicleMarginCalculationDetails,
  salesPersonsSelectedFilters: IDropdownOption<string>[],
): boolean => {
  if (findIndex(salesPersonsSelectedFilters, (filter) => get(filter, 'value') === get(margin, 'salesPerson')) === -1)
    return false;

  return true;
};

export const syncFilterMarginsForSales = ({
  baseMargins,
  hideWithoutSales = false,
  monthsSelectedFilters = [],
  makesSelectedFilters = [],
  modelsSelectedFilters = [],
  serialNumbersSelectedFilters = [],
  departmentNumbersSelectedFilters = [],
  salesPersonsSelectedFilters = [],
}: {
  baseMargins: IBosApiVehicleMarginCalculationDetails[];
  hideWithoutSales?: boolean;
  monthsSelectedFilters?: IDropdownOption<string>[];
  makesSelectedFilters?: IDropdownOption<string>[];
  modelsSelectedFilters?: IDropdownOption<string>[];
  serialNumbersSelectedFilters?: IDropdownOption<number>[];
  departmentNumbersSelectedFilters?: IDropdownOption<number>[];
  salesPersonsSelectedFilters?: IDropdownOption<string>[];
}): IBosApiVehicleMarginCalculationDetails[] => {
  const query: IMarginPriceFields = {
    salesPrice: 0,
    purchasePrice: 0,
    importerDiscount: 0,
    otherCharges: 0,
    marginPercentage: 0,
    marginActual: 0,
  };

  let syncFilteredMargins: IBosApiVehicleMarginCalculationDetails[] = removeZeroedData(baseMargins, query) || [];

  return syncFilteredMargins?.filter((margin) => {
    // if selected filter is not included return false;
    if (hideWithoutSales && !isMargingWithSales(margin)) return false;
    if (monthsSelectedFilters.length && !isMarginInFilteredMonths(margin, monthsSelectedFilters)) return false;
    if (makesSelectedFilters.length && !isMarginForFilteredMakes(margin, makesSelectedFilters)) return false;
    if (modelsSelectedFilters.length && !isMarginForFilteredModels(margin, modelsSelectedFilters)) return false;
    if (serialNumbersSelectedFilters.length && !isMarginForFilteredSerialNumbers(margin, serialNumbersSelectedFilters))
      return false;
    if (
      departmentNumbersSelectedFilters.length &&
      !isMarginForFilteredDepartmentNumbers(margin, departmentNumbersSelectedFilters)
    )
      return false;
    if (salesPersonsSelectedFilters.length && !isMarginForFilteredSalesPersons(margin, salesPersonsSelectedFilters))
      return false;

    // else return true;
    return true;
  });
};

export const getAllOption = (key: string): IDropdownOption<string> => {
  return {
    value: key,
    label: 'Alle',
  };
};

export const getUniqueValues = <T>(
  margins: IBosApiVehicleMarginCalculationDetails[],
  key: string,
): IDropdownOption<T>[] => {
  const uniqueArray = [];
  const options: IDropdownOption<T>[] = [];
  forEach(margins, (margin) => {
    const i = uniqueArray.findIndex((x) => get(x, key) === get(margin, key));
    if (get(margin, key) && i <= -1) {
      uniqueArray.push({ [key]: get(margin, key) });
      options.push({ value: get(margin, key), label: get(margin, key)?.toString() });
    }
  });
  return options;
};

export const deriveNewSelectedOnUserFocused = <T>({
  oldSelected,
  oldVisible,
  newVisible,
  key,
}: {
  oldSelected: T[];
  oldVisible: T[];
  newVisible: T[];
  key: string;
}): T[] => {
  const invisibleSelected = differenceBy(oldSelected, oldVisible, key);

  return unionBy(newVisible, invisibleSelected, key);
};

export const reduceOtherSelectedField = <T>({ oldSelected, newVisible, key }): T[] => {
  return intersectionBy(oldSelected, newVisible, key);
};

export const isDifferent = <T>({ arr0, arr1, key }: { arr0: T[]; arr1: T[]; key: string }): boolean => {
  return !!differenceBy(arr0, arr1, key)?.length;
};

export const deriveVisibleSelected = <T>({
  selected,
  availableOptions,
  key,
}: {
  selected: T[];
  availableOptions: T[];
  key: string;
}): T[] => {
  return intersectionBy(selected, availableOptions, key);
};

export const fetchUniqueOptionsData = (uniqueArray, options, key, item) => {
  const index = uniqueArray.findIndex((x) => x[key] === item[key]);
  if (index <= -1) {
    uniqueArray.push({ [key]: item[key] });
    options.push({ value: item[key]?.toString(), label: item[key]?.toString() });
  }
  return { options, uniqueArray };
};

export const filterSalesPersonOptions = (data, filter, key, flag) => {
  let uniqueArray = [];
  let options = [];
  data?.forEach((item) => {
    if (flag) {
      if (item[filter?.label]?.toString() === filter?.value?.toString()) {
        options = fetchUniqueOptionsData(uniqueArray, options, key, item)?.options;
        uniqueArray = fetchUniqueOptionsData(uniqueArray, options, key, item)?.uniqueArray;
      }
    } else {
      options = fetchUniqueOptionsData(uniqueArray, options, key, item)?.options;
      uniqueArray = fetchUniqueOptionsData(uniqueArray, options, key, item)?.uniqueArray;
    }
  });
  return options;
};

export const uniqueBySameReferenceNumber = (data: IMarginUniqueByReferenceNumber[]) => {
  const uniqueValues = data.map((a) => {
    return {
      referenceNumber: a.referenceNumber,
      amount: a.amount,
      company: a.company,
      identity: a.identity,
      referenceType: a.referenceType,
      voucherDate: a.voucherDate,
    };
  });

  return uniqueValues
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .filter((el) => {
      return typeof el !== 'object' || Object.keys(el).length > 0;
    })
    .reduce((prev, cur) => {
      const index = prev.findIndex(
        (v) =>
          v.referenceNumber === cur.referenceNumber ||
          ((v.referenceNumber === '' || v.referenceNumber === null) &&
            (cur.referenceNumber === null || cur.referenceNumber === '')),
      );
      if (index === -1) {
        prev.push(cur);
      } else {
        prev[index].amount += cur.amount;
      }
      return prev;
    }, [])
    .filter((item) => Math.round(item.amount) !== 0);
};

export const uniqueByAccountStart = (
  arr: IBosApiVehicleMarginCalculationActuals[],
  stringStart: string,
  referenceTypeFilter: boolean,
) => {
  if (!arr) return;
  const uniqueByAccount = arr.filter((obj) => {
    if (referenceTypeFilter) {
      return (
        obj.account.startsWith(stringStart) &&
        (obj.referenceType === ReferenceType.CUSTOMER_INVOICE ||
          obj.referenceType === ReferenceType.CUSTOMER_CREDIT_INVOICE)
      );
    } else {
      return (
        obj.account.startsWith(stringStart) &&
        obj.referenceType !== ReferenceType.CUSTOMER_INVOICE &&
        obj.referenceType !== ReferenceType.CUSTOMER_CREDIT_INVOICE
      );
    }
  });
  return uniqueBySameReferenceNumber(uniqueByAccount);
};

export const uniqueByAccounts = (
  arr: IBosApiVehicleMarginCalculationActuals[],
  accountsFilterArray: string[],
  referenceTypeFilter: boolean,
  referenceType: string,
) => {
  if (!arr) return;
  const uniqueAccounts = arr.filter((obj) => {
    if (referenceTypeFilter) {
      return accountsFilterArray.includes(obj.account) && obj.referenceType === referenceType;
    } else if (!referenceTypeFilter && referenceType !== null) {
      return accountsFilterArray.includes(obj.account) && obj.referenceType !== referenceType;
    } else {
      return accountsFilterArray.includes(obj.account);
    }
  });
  return uniqueBySameReferenceNumber(uniqueAccounts);
};

export const uniqueByAccountsAndReferenceNumber = (
  arr: IBosApiVehicleMarginCalculationActuals[],
  accountsFilterArray: string[],
  referenceNumber: string,
) => {
  if (!arr) return;
  const uniqueAccounts = arr.filter((obj) => {
    if (referenceNumber === null) {
      return accountsFilterArray.includes(obj.account) && obj.referenceNumber === referenceNumber;
    } else {
      return accountsFilterArray.includes(obj.account) && obj.referenceNumber !== null;
    }
  });
  return uniqueBySameReferenceNumber(uniqueAccounts);
};

export const sum = (arr, key) => {
  if (!arr) return;
  return arr.reduce((a: number, b) => a + (b[key] || 0), 0);
};

export const copyWithIFSUrl = (content, url, e) => {
  copyToClipboard(url + content);
  e.stopPropagation();
  alert('Kopiert ' + content + '. Vennligst åpne IE og lim inn URL');
};

export const isArrayFilter = (array) => {
  return Array.isArray(array) && array.length > 0;
};

export const mergeArrays = (...arrays) => {
  let jointArray = [];

  arrays.forEach((array) => {
    jointArray = [...jointArray, ...array];
  });
  return jointArray.filter((item, index) => jointArray.indexOf(item) === index);
};

export const compareDates = (startDate: Date, endDate: Date) => {
  return isAfter(endDate, startDate);
};

export const getMonths = <T>(margins: IBosApiVehicleMarginCalculationDetails[]): IDropdownOption<T>[] => {
  const options = [];
  const uniqueArray = [];
  margins?.forEach((margin) => {
    const startOfMonthDate = startOfMonth(new Date(get(margin, 'invoiceDate')));
    const tempDate = format(startOfMonthDate, 'yyyy-MM-dd');
    if (!uniqueArray.includes(tempDate)) uniqueArray.unshift(tempDate);
  });
  uniqueArray?.forEach((item) => {
    options.push({
      value: format(new Date(item), 'yyyy-MM-dd'),
      label: format(new Date(item), 'MMM yyyy'),
    });
  });
  const sortedLabelsOptions = orderBy(
    options,
    (object) => {
      if (object.value === 'Alle') return object.value;
      return new Date(object.value);
    },
    ['desc'],
  );
  return sortedLabelsOptions;
};

export const getVoucherDate = (date: string) => {
  return !date ? '-' : format(new Date(date), 'dd.MM.yy');
};

export const getMarginAdminUser = async (email: string) => {
  const fetchHeader = await getHeader();
  return (
    axios
      .get(`${apimEndpoint ?? window.location.origin}/api/margin/auth/admin/${email}`, fetchHeader)
      .then((result) => {
        return result.data?.response;
      })
      // tslint:disable-next-line:no-console
      .catch((err) => console.error(err))
  );
};

export const removeWhiteSpace = (value: string) => {
  return value?.replace(/\s+/g, '').trim();
};

export const toFixed = (num: string, fixed: number): string => {
  if (num === '') return num;
  const regEx = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return regEx.exec(num)?.[0] || num;
};

export const getDecimalValue = (value: string, decimals: number): string => {
  if (isNaN(parseFloat(value))) return value;
  const num =
    typeof value === 'string'
      ? value?.replace(/\s/g, '')?.replace(String.fromCharCode(8722), '-')?.replace(',', '.')
      : '';
  const shorterValue: string = num ? toFixed(num, decimals) : num;
  return num?.indexOf('.') > -1 && num?.indexOf('.') < num?.length - 1 ? shorterValue : num;
};

export const formatInputValue = (input, inputType) => {
  return inputType === InputType.FLOAT ? getDecimalValue(String(input), 2) : input;
};

export const isOptionExist = (selectedOption) => {
  return isArray(selectedOption) && selectedOption.length === 0;
};

export const fetchSalesData = (selectedOption, options, data, key) => {
  selectedOption?.forEach((option) => {
    options.push(
      data.filter((item) => {
        return item[key]?.toString() === option.value?.toString();
      }),
    );
  });
  return options;
};
