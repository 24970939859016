import { addDays, differenceInDays, format, isValid } from 'date-fns';
import { IBosApiAttachmentData, IReference, IVehicleConfiguration, OrderSource, OrderStatus } from 'next-common';
import { maxExpiryLength } from '../../common';

export const getAppendingIdFromUrl = (appendingPath = '', pathname = window.location.pathname) => {
  const url = pathname?.replace(appendingPath, '')?.replace(/\/$/, '') || '';
  return url.substring(url.lastIndexOf('/') + 1);
};

export const getAppendingNumericIdFromUrl = (appendingPath = '', pathname = window.location.pathname) => {
  const id = getAppendingIdFromUrl(appendingPath, pathname);
  const expression = /^\d{17,18}/gm;
  return new RegExp(expression)?.test(id) ? id : null;
};

export const getPimCodeFromOrderOrVehicle = (order: IReference, vehicle: IVehicleConfiguration) =>
  (order?.pimCode) || (vehicle?.sku) || null;

const dateFormat = 'YYYY-MM-DD';

export const findExpiresAt = (expiresAt: string, createdAt: string, expiryLength: number = maxExpiryLength): Date => {
  let expriesMoment = new Date(expiresAt);
  if (isValid(expriesMoment)) return expriesMoment;
  expriesMoment = new Date(expiresAt);
  if (isValid(expriesMoment)) return expriesMoment;
  return addDays(new Date(createdAt), expiryLength);
};

export const daysUntil = (date: Date): number => {
  const currentDate = new Date(format(new Date(), 'yyyy-MM-dd'));
  const lastDate = new Date(format(new Date(date), 'yyyy-MM-dd'));
  const days = differenceInDays(lastDate, currentDate);
  if (days < 0) return 0;
  return days;
};

export const isEcomOrder = (source: OrderSource): boolean => OrderSource[source?.toString()] === OrderSource.ECOM;

export const isOldOfferExpire = (status: OrderStatus, vatField: number | null): boolean => {
  return OrderStatus?.EXPIRED === OrderStatus[status?.toString()] && vatField === null;
};

export const isNextOrder = (source: OrderSource): boolean => OrderSource[source?.toString()] === OrderSource.NEXT;

export const isCRMOrder = (source: OrderSource): boolean => OrderSource[source?.toString()] === OrderSource.CRM;

export enum ListType {
  FINANCE = 'finance',
  DETAIL = 'details',
}

export const checkStatus = (
  matchingStati: OrderStatus[],
  status: OrderStatus,
  includeCancelled = false,
  includeExpired = false,
) => {
  if (includeCancelled) matchingStati?.push(OrderStatus.CANCELLED);
  if (includeExpired) matchingStati?.push(OrderStatus.EXPIRED);

  // Sometimes `status` is sent as the string representation of the enum key.
  if (typeof status === 'string' && (status as string)?.length > 1) status = OrderStatus[status as unknown as string];

  return matchingStati?.includes(status);
};

export const filterContracts = (contracts: IBosApiAttachmentData[]): IBosApiAttachmentData[] => {
  const filteredContracts = contracts?.filter((contract) => {
    return contract?.isSigned;
  });
  return filteredContracts.sort((contractBefore, contractAfter) => {
    // @ts-ignore
    return new Date(contractAfter?.signingDate) - new Date(contractBefore?.signingDate);
  });
};
