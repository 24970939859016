import { bosColor } from '@next-components/common-styles';
import styled from 'styled-components';

export const ModelWrapperContainer = styled.div`
  & > .deleteVehicle {
    .inner-container .content-container {
      div > button:first-child {
        background-color: ${bosColor.poppy};
        color: ${bosColor.white};
      }
    }
  }
`;
