import React from 'react';
import { CustomerType, IOrder, MAKE } from 'next-common';
import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { findExpiresAt, getCustomerName } from '../../../../../utils';
import { backgroundCard, srtColor } from '@next-components/common-styles';
import { format } from 'date-fns';

const SubHeaderStyle: Style = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '10 20',
  backgroundColor: backgroundCard,
};

const SubHeaderElementStyle: Style = {
  flexDirection: 'row',
  fontSize: 7,
};

const SubHeaderElement = (props) => (
  <View style={SubHeaderElementStyle}>
    <View style={{ fontWeight: props.bold ? 'bold' : 'normal' }}>
      <Text style={{ paddingRight: 3 }}>{props.label}:</Text>
      <Text>{props.value}</Text>
    </View>
  </View>
);

export const SubHeader = (props: IOrder) => (
  <>
    <View style={{ ...SubHeaderStyle, backgroundColor: props?.lead?.make === MAKE.Smart ? srtColor.silver : backgroundCard }}>
      {props.customer?.customerType !== CustomerType.BUSINESS && (
        <>
          <SubHeaderElement bold={true} label="Kunde" value={getCustomerName(props.customer?.bosCustomer)} />
          <SubHeaderElement label="Telefon" value={props.customer?.bosCustomer?.phoneMobile} />
          <SubHeaderElement label="E-post" value={props.customer?.bosCustomer?.email} />
          <SubHeaderElement
            label="Adresse"
            value={
              props.customer &&
              `${props.customer?.bosCustomer?.address1}, ${props.customer?.bosCustomer?.zipCode} ${props.customer?.bosCustomer?.city}`
            }
          />
        </>
      )}
      {props.customer?.customerType === CustomerType.BUSINESS && (
        <>
          <SubHeaderElement bold={true} label="Kunde" value={getCustomerName(props.buyer?.bosCustomer)} />
          <SubHeaderElement label="Telefon" value={props.buyer?.bosCustomer?.phoneMobile} />
          <SubHeaderElement label="E-post" value={props.buyer?.bosCustomer?.email} />
          <SubHeaderElement
            label="Adresse"
            value={
              props.buyer &&
              `${props.buyer?.bosCustomer?.address1}, ${props.buyer?.bosCustomer?.zipCode} ${props.buyer?.bosCustomer?.city}`
            }
          />
        </>
      )}
      <SubHeaderElement
        label="Gyldig til"
        bold={true}
        value={format(findExpiresAt(props.expiresAt, props.createdAt), 'dd.MM.yyyy')}
      />
    </View>
  </>
);
