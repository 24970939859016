import React, { ReactElement, useState } from 'react';
import { OrderAccordion } from '../OrderAccordion/OrderAccordion';
import { DynamicList, IDynamicListProps, IDynamicListConfig } from '@next-components/dynamic-list';
import {
  FinancingType,
  ICalculation,
  INextArticle,
  SantanderProductType,
} from 'next-common';
import { FinancingDialog, IFinancingDialogProps } from '../FinancingDialog';
interface IFinancingItem {
  disabled: boolean;
  downPayment: number;
  durationMonths: number;
  id: string;
  picker: ReactElement;
  range: number;
  serviceContractDrivingDistance: number;
  serviceContractDuration: number;
  serviceContractMonthlyPrice: number;
  title: string;
  totalCost: number;
  totalMonthlyCost: number;
  totalPrice: number;
  monthlyCostInclVatAndFee: number;
}
interface FinanceAccordionProps extends IDynamicListProps {
  data: IFinancingItem[];
  sort?: any;
  selectedRows: any;
  label: string;
  config: IDynamicListConfig;
  stopSantandarService: string[];
  santanderProductType?: string;
  financingButton?: ReactElement;
  isNoTires?: boolean;
  className: string;
  financeDialogProps?: IFinancingDialogProps;
  addNew: boolean;
  isUnavailablePimData?: boolean;
  articles?: INextArticle[];
  isStockSaleOrder?: boolean;
}

export const FinanceAccordion = (props: FinanceAccordionProps) => {
  const children = props.financingButton;
  const getCalculation = (id: string): ICalculation =>
    props.financeDialogProps?.order?.offers.find((offer) => offer?.id === id);
  const getFinanceDialog = (rowId?: string) => {
    const currentCalculation = rowId && getCalculation(rowId);

    return (
      <div className={children?.props?.type === FinancingType.CASH ? 'newServiceContractForm' : ''}>
        <FinancingDialog
          articles={props.articles}
          isStockSaleOrder={props.isStockSaleOrder}
          loading={props.loading}
          offerId={rowId}
          calculation={currentCalculation}
          serviceContractDb={currentCalculation?.serviceContract || null}
          serviceContract={props.financeDialogProps?.serviceContract}
          customerType={props.financeDialogProps?.customerType}
          isModal={false}
          orderId={props.financeDialogProps?.order?.id}
          vehicleConfig={props.financeDialogProps?.vehicleConfig}
          onUpdateCalculation={props.financeDialogProps?.onUpdateCalculation}
          onCalculationUpdated={props.financeDialogProps?.onCalculationUpdated}
          onServiceContractUpdated={props.financeDialogProps?.onServiceContractUpdated}
          financingType={children?.props?.type ?? FinancingType.CASH}
          success={props.financeDialogProps?.success}
          order={props.financeDialogProps?.order}
          addNew={props.financeDialogProps?.addNew}
          setUpdatedOrder={props.financeDialogProps?.setUpdatedOrder}
          setAddNew={props.financeDialogProps?.setAddNew}
          onAddCalculation={props.financeDialogProps?.onAddCalculation}
          isSigningStep={props.financeDialogProps?.isSigningStep}
          isDeleted={props.financeDialogProps?.isDeleted}
          configuration={props.financeDialogProps?.configuration}
          onDeleteCalculation={props.financeDialogProps?.onDeleteCalculation}
          isUnavailablePimData={props.isUnavailablePimData}
        />
      </div>
    );
  };

  const [userClosedAccordion, setUserClosedAccordion] = useState<boolean>(false);

  return (
    <OrderAccordion isOpen={true} label={props.label}>
      <div className={`calculation-type ${props.className}`}>
        <DynamicList
          data={props.data?.map((element) => ({
            ...element,
            accordionChild: getFinanceDialog(element?.id),
            isAccordionOpen: !userClosedAccordion && props.selectedRows[0]?.id === element?.id,
            userClosedAccordion: (param) => {
              props.selectedRows[0]?.id === param.id && setUserClosedAccordion(true);
            },
          }))}
          sort={props.sort}
          isNewDynamicList={true}
          config={props.config}
          selectedRows={props.selectedRows}
          selectedRowsKey="id"
          isFinancePage={true}
          onElementClick={props.onElementClick}
        />
        {props.addNew ? getFinanceDialog() : null}
        {!props.stopSantandarService?.includes(props.santanderProductType) &&
          !props.addNew &&
          !props.loading &&
          children}
        {props.stopSantandarService?.includes(props.santanderProductType) && (
          <div className="error-santandar">{'(Foreløpig ikke tilgjengelig)'}</div>
        )}
        {props.santanderProductType === SantanderProductType.LEASING &&
          !props.stopSantandarService?.includes(props.santanderProductType) &&
          props.isNoTires && (
            <div className="error-santandar">
              {'Leasingavtale kan ikke kalkuleres dersom det ikke er lagt til vinterhjul i tilbudet.'}
            </div>
          )}
      </div>
    </OrderAccordion>
  );
};

FinanceAccordion.defaultProps = {
  label: '',
  stopSantandarService: [],
  SantanderProductType: '',
  className: '',
};
