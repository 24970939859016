import gql from "graphql-tag";

export const getConfigurationQuery = gql`
  query getConfiguration {
    getConfiguration {
        features {
            orderEdit
            orderCancel
            stockSaleOrder
            stockSaleBrands
            stoppedFinancialServices
            cronMail
            stockOrder
            demoOrder
            stoppedDemoBrands
            stoppedStockBrands
        }
    }
  }
`;