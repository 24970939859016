import React from 'react';
import { View, Text, Font } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { ITradeInVehicle } from 'next-common';
import { formatNumber } from '@next-components/common-types';
import {
  HorizontalWarpableListStyled,
  LegalStuffStyled,
  OptionDataStyled,
  OptionPriceStyled,
  SectionStyled,
} from './common-styles';
import { backgroundCard } from '@next-components/common-styles';
import { textToTitleCase } from '../salesOfferPdf.utils';

Font.registerHyphenationCallback((word) => [word]);

const OptionElementsStyled: Style = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
};

const OptionElementsTradeInStyled: Style = {
  padding: '14 6 6 6',
  backgroundColor: backgroundCard,
  width: '25%',
};

const OptionBrandStyled: Style = {
  padding: '14 6 6 16',
  backgroundColor: backgroundCard,
  width: '25%',
};

const OptionElement = (props) => (
  <View style={props.label === 'Merke' ? OptionBrandStyled : OptionElementsTradeInStyled}>
    <Text style={{ fontSize: 8 }}>{props.label}</Text>
    <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{props.value}</Text>
  </View>
);

const OptionPrice = (props) => (
  <View style={OptionPriceStyled}>
    <Text style={{ fontSize: 8 }}>{props.label}</Text>
    <Text style={{ fontSize: 20, fontWeight: 'extrabold' }}>{props.value}</Text>
  </View>
);

const LegalTradIn = () => (
  <View style={LegalStuffStyled}>
    <Text style={{ fontSize: 7 }}>Estimert innbyttepris forbeholdt taksering av innbyttebilen</Text>
  </View>
);

const TradeIn = (props: ITradeInVehicle) => (
  <View style={OptionDataStyled}>
    <View style={{ flexDirection: 'column', width: '75%', height: '100%' }}>
      <View style={OptionElementsStyled}>
        <OptionElement value={textToTitleCase(props.make)} label="Merke" />
        <OptionElement value={textToTitleCase(props.model)} label="Modell" />
        <OptionElement value={props.registrationNumber} label="Registreringsnr." />
        <OptionElement value={`${formatNumber(props.mileage)} km`} label="Km-stand" />
      </View>
      <LegalTradIn />
    </View>
    <OptionPrice value={`${formatNumber(props.price || 0)} kr`} label="Estimert innbyttepris" />
  </View>
);

interface ITradeInVehiclesProps {
  tradeIns: ITradeInVehicle[];
}

export const TradeInVehicles = (props: ITradeInVehiclesProps) => {
  if (!props.tradeIns || props.tradeIns?.length < 1) return null;
  return (
    <View style={SectionStyled} break={true}>
      <Text style={{ fontSize: 16, margin: '0 5 5' }}>Innbyttebil{props.tradeIns?.length !== 1 ? 'er' : ''}</Text>
      <View style={HorizontalWarpableListStyled}>
        {props.tradeIns.map((tI) => (
          <TradeIn key={tI?.registrationNumber} {...tI} />
        ))}
      </View>
    </View>
  );
};
