import { useQuery } from '@apollo/client';
import { IRestrictAccess } from 'next-common';
import { allRestrictAccessQuery } from '../../graphql/backend/queries/restrict-access';
import { ERROR_POLICY } from '../constants';

interface IRestrictAccessResult {
  allRestrictAccess: IRestrictAccess[];
  loading: boolean;
}

export const useRestrictAccess = (): IRestrictAccessResult => {
  const { data, loading } = useQuery(allRestrictAccessQuery, {
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'cache-and-network',
  });
  return { allRestrictAccess: data?.allRestrictAccess, loading };
};
