import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './AppHeader.module.scss';
import { Icon, IconType } from '@next-components/bos-icon';
import { Routes } from '../../../common';
import { Logo } from './NewLogo';
import { SalesPerson } from './SalesPerson';

const cx = classNames.bind(styles);

// tslint:disable-next-line:no-empty-interface
interface IAppHeaderProps {}

interface IMenuLinkProps {
  active: boolean;
  route: string;
  title: string;
}

const LogoLink = () => (
  <div className={styles.logo}>
    <Link to={Routes.getHomePage()} className={styles.AppLink}>
      <Logo />
    </Link>
  </div>
);

const MenuLink = (props: IMenuLinkProps) => (
  <span
    className={cx({
      menuItem: true,
      active: props.active,
    })}>
    <Icon icon={IconType.NewNextList} aria-hidden height={24} width={24} />
    <NavLink to={props.route}>{props.title}</NavLink>
  </span>
);

const Menu = () => {
  const isHomePage = () => window.location.pathname === Routes.getHomePage();

  return (
    <div className={styles.menu}>
      <MenuLink active={isHomePage()} route={Routes.getHomePage()} title="Min oversikt" />
      <SalesPerson />
    </div>
  );
};

const AppHeader = (props: IAppHeaderProps) => {
  return (
    <div className={styles.appHeader}>
      <LogoLink />
      <Menu />
    </div>
  );
};

export default AppHeader;
