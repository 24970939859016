import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { IBosApiDealerInfoData, IUser, MAKE } from 'next-common';
import { formatPhoneNumberInput } from '@next-components/common-types';
import { backgroundCard, srtColor } from '@next-components/common-styles';
import { daysUntil, findExpiresAt } from '../../../../../utils';
import { v4 as uuidv4 } from 'uuid';
import capitalize from 'lodash/capitalize';

export interface ISalesOfferFooterProps {
  dealer: IBosApiDealerInfoData;
  user: IUser;
  createdAt: string;
  expiresAt: string;
  prerequisiteComment?: string;
  make: string;
  infoAgent: string;
}

// Calculate total no of days for expiry
const calculateDateDifference = (expiresAt: string, createdAt: string) => {
  const expiry = findExpiresAt(expiresAt, createdAt);
  return daysUntil(expiry);
};

const FooterStyle: Style = {
  backgroundColor: backgroundCard,
  position: 'absolute',
  bottom: 0,
  padding: '5 15',
};

const FooterInfo: Style = {
  flexDirection: 'row',
};

const FooterBlockStyle: Style = {
  fontSize: 8,
  padding: '5',
  width: '33%',
};

const FooterBlock = (props) => {
  const info = props.info.filter((o) => o !== undefined);
  return (
  <View style={FooterBlockStyle}>
    <Text style={{ fontSize: 12, fontWeight: 600, paddingBottom: 5 }}>{props.header}</Text>
    {info.map((i) => (
      <Text style={{ paddingBottom: 1 }} key={uuidv4()}>
        {i}
      </Text>
    ))}
  </View>
)};

const fetchUserMobilePhone = (user: IUser): string => {
  let mobileNumber = '';
  if (user?.mobilePhone) {
    if (user.mobilePhone.startsWith('+47')) {
      mobileNumber = formatPhoneNumberInput(user.mobilePhone.toString(), true).formatted;
    } else {
      mobileNumber = formatPhoneNumberInput(`+47${user.mobilePhone.toString()}`, true).formatted;
    }
  }
  return mobileNumber;
};

export const Footer = (props: ISalesOfferFooterProps) => (
  <View
    fixed
    style={{ ...FooterStyle, backgroundColor: props?.make === MAKE.Smart ? srtColor.silver : backgroundCard }}>
    <View style={FooterInfo}>
      <FooterBlock
        header={`${props.dealer?.name || 'Forhandler'}`}
        info={[
          `${props.dealer?.visitingAddressStreet || ''}`,
          `${props.dealer?.visitingAddressZipCode || ''} ${capitalize(props.dealer?.visitingAddressCity) || ''} `,
        ]}
      />
      <FooterBlock
        header="Selger"
        info={[
          props.infoAgent,
          `${props.user?.firstName || ''} ${props.user?.lastName || ''}`,
          `Tlf: ${fetchUserMobilePhone(props?.user)}`,
          `E-post: ${props.user?.email || ''}`,
        ]}
      />
      <FooterBlock
        header="Info om forutsetninger"
        info={[
          `Gyldighet i ${calculateDateDifference(props.expiresAt, props.createdAt)} dager`,
          'Forbehold om renteendringer',
          'Forbehold om godkjent finansiering',
          `${props?.prerequisiteComment}`,
        ]}
      />
    </View>
  </View>
);
