import { gql } from '@apollo/client';
import { getVehicleOrderStatusQuery, findEventsOnOrderQuery } from 'next-common';
import { myOverviewOrderQuery } from '../../../components/dashboard/my-overview-page/orderQuery';

export const queryOrdersParsed = gql`
  ${myOverviewOrderQuery}
`;

export const vehicleOrderStatusQueryParsed = gql`
  ${getVehicleOrderStatusQuery}
`;

export const findEventsOnOrderParsed = gql`
  ${findEventsOnOrderQuery}
`;
