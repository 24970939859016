import React, { useEffect } from 'react';
import { gql, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
  getVehicleOrderAttachmentsQuery,
  IBosApiAttachmentData,
  IBosApiSendSmsData,
  IBosVehicleOrderStatus,
  IConfiguration,
  INextArticle,
  IOrder,
  isEditable,
  isStockSaleOrderToBeProcessed,
  IVehicleConfiguration,
  OrderStatus,
} from 'next-common';
import { Tooltip } from '@next-components/tooltip';
import { Button } from '@next-components/cta';
import { IServiceContractUpdateInput } from 'sales-common';
import { ICommonSalesOfferPdfProps, isOffer, isShowServiceContractLink, isShowSignedContractLink } from '../OrderPane';
import {
  cancelOrderMutationParsed,
  ERROR_POLICY,
  findRouteForOrder,
  isStockOrder,
  resumeOrderMutationParsed,
  Routes,
  selectedDealerId,
  sendSigningJobMutationParsed,
  updateOrderForEcomMutationParsed,
  useEmployee,
} from '../../../../../common';
import { SalesOfferPdf } from '../../../sales-common/SalesOfferPdf';
import { ContractPDF } from '../../../sales-common/ContractPDF';
import { CancelOrderModal } from '../../../sales-common/CancelOrderModal';
import { ActionButtonContainer, FileButtonContainer, FilesWrapper, OrderButtonsStyled } from './OrderButtons.styled';
import { isOrder } from '..';
import {
  filterContracts,
  hasReservationBlockingCancellation,
  isEcomOrder,
  isOrderEditable,
  isOrderInvoiced,
  mapEmployeeToUser,
} from '../../../../../utils';
import { SendSmsModal } from './SendSmsModal';
import { ServiceContractPDF } from '../../../sales-common/ServiceContractPDF';
import { updateStatusToDARS } from '../../../../../graphql';
import { ICartProps } from '@next-components/cart';
import { useMsal } from '@azure/msal-react';
import { IEquipmentIdAndSKU } from 'sales-crystallize-common';
import { useNavigate } from 'react-router-dom';

interface IOrderButtonsProps extends ICommonSalesOfferPdfProps {
  status: OrderStatus;
  order: IOrder;
  vehicleConfig: IVehicleConfiguration;
  vehicleOrderStatus: IBosVehicleOrderStatus;
  vehicleReserveStatus: boolean;
  isSigned: boolean;
  onStatusChange(status: OrderStatus): void;
  cartData?: ICartProps;
  mappedEquipment: IEquipmentIdAndSKU[];
  configuration?: IConfiguration;
  orderEditButtonDisabled?: boolean;
  pageLoading?: boolean;
  articles?: INextArticle[];
  carType?: string;
}

const getVehicleOrderAttachmentsParsed = gql`
  ${getVehicleOrderAttachmentsQuery}
`;

export const checkExpireOrder = (status) => {
  return OrderStatus?.EXPIRED === OrderStatus[status as string];
};

export const OrderButtons = (props: IOrderButtonsProps) => {
  const [contracts, setContracts] = React.useState<IBosApiAttachmentData[]>([]);
  const [reopenLoading, setReopenLoading] = React.useState<boolean>(false);
  const [sendSmsLoading, setSmsLoading] = React.useState<boolean>(false);
  const [showCancelModal, setShowCancel] = React.useState<boolean>(false);
  const [showSendSmsModal, setShowSendSms] = React.useState<boolean>(false);
  const [sendSmsResult, setSendSmsResult] = React.useState<string>(null);
  const [editable, setEditable] = React.useState<boolean>(isOrderEditable(props.order, props.configuration));
  const signingJob = props?.order?.signingJob;

  const [cancelOrderMutation] = useMutation(cancelOrderMutationParsed);
  const [sendSigningSmsMutation] = useMutation(sendSigningJobMutationParsed);
  const [resumeOrderMutation] = useMutation(resumeOrderMutationParsed);

  const { accounts } = useMsal();
  const employee = useEmployee(accounts?.[0]?.username);
  const navigate = useNavigate();
  const [filteredContracts, setFilteredContracts] = React.useState<IBosApiAttachmentData[]>([]);

  const [getContracts] = useLazyQuery(getVehicleOrderAttachmentsParsed, {
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
    variables: {
      vehicleOrderId: props?.order?.id,
    },
    onCompleted: (data) => {
      setContracts(data?.getVehicleOrderAttachments);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      setContracts(null);
    },
  });

  const [updateStatusDars] = useMutation(updateStatusToDARS, {
    errorPolicy: ERROR_POLICY,
    context: { clientName: 'sales-common' },
  });

  const [updateOrderForEcom] = useMutation(updateOrderForEcomMutationParsed, {
    errorPolicy: ERROR_POLICY,
  });

  React.useEffect(() => {
    if (!sendSmsResult) return;
    setTimeout(() => {
      setSendSmsResult(null);
    }, 5000);
  }, [sendSmsResult]);

  React.useEffect(() => {
    if (editable || signingJob === null) {
      getContracts()
        .then((resposne) => resposne)
        .catch((error) => console.error(error));
    }
  }, [props?.order?.status]);

  useEffect(() => {
    if (contracts?.length > 0) {
      setFilteredContracts(filterContracts(contracts));
    }
    if (signingJob === null && props?.order?.isServiceContractSelected && contracts) {
      if (contracts?.length > 0) {
        const statusUpdate: IServiceContractUpdateInput = {
          nextId: props?.order?.id,
          contractId: props?.order?.salesContractPdfId,
          signingStatus: 'completed',
          isManualSign: true,
          nextDocumentPath: contracts?.find((contract) => contract?.isSigned)?.id ?? '',
        };
        // eslint-disable-next-line no-console
        updateStatusDars({ variables: { input: statusUpdate } }).catch((error) => console.log(error));
      }
    }
  }, [contracts]);

  useEffect(() => {
    setEditable(isOrderEditable(props.order, props.configuration));
  }, [props?.status]);

  const selectedDealer = useReactiveVar(selectedDealerId);
  const getUpdateOrderVariables = (order: IOrder) => ({
    id: order?.id,
    input: {
      user: employee ? mapEmployeeToUser(employee, selectedDealer) : null,
    },
  });

  const updateOrder = async (order: IOrder) => {
    try {
      const variables = getUpdateOrderVariables(order);
      return await updateOrderForEcom({ variables });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onContinue = async (order: IOrder) => {
    const isStockSaleOrder = isStockSaleOrderToBeProcessed(props?.configuration, order?.lead?.source, null, '');
    if (isEcomOrder(order?.source) || isStockSaleOrder) {
      if (!order?.user || order?.user?.employeeId !== employee?.employeeId) {
        await updateOrder(order);
      }
    }
    if ((isStockSaleOrder || order?.vehicles?.[0]?.serialNo) && !isStockOrder(order?.lead?.orderType)) {
      const route = Routes.getVehicleAccessoriesPage(props?.order?.id);
      return navigate(route);
    }
    const route = findRouteForOrder(order);
    return navigate(route);
  };

  const onCancel = () => {
    navigate(Routes.getHomePage());
  };

  const resumeOrder = async () => {
    try {
      const { data } = await resumeOrderMutation({
        variables: { orderId: props?.order?.id },
      });
      const order: IOrder = data?.resumeOrder || props?.order;
      props.onStatusChange(order?.status);
      return order;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const reopen = async () => {
    setReopenLoading(true);
    const status = OrderStatus[props?.status?.toString()];
    if (status === OrderStatus.PAUSED || status === OrderStatus.CANCELLED) {
      const order = await resumeOrder();
      if (!order) return;
      // eslint-disable-next-line no-console
      onContinue(order).catch((error) => console.error(error));
    } else {
      // eslint-disable-next-line no-console
      onContinue(props?.order).catch((error) => console.error(error));
    }
  };

  const sendSigningSms = async () => {
    setSmsLoading(true);
    setSendSmsResult(null);
    try {
      const { data } = await sendSigningSmsMutation({
        variables: { id: props?.order?.id },
      });
      const result: IBosApiSendSmsData = data?.sendSigningJob;
      setSmsLoading(false);
      setSendSmsResult(result ? `Sendt${result?.statusCode ? result?.statusCode : ''}` : 'Klarte ikke sende');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setSmsLoading(false);
      setSendSmsResult('Sending feilet');
    }
  };

  const cancelOrder = async () => {
    try {
      const { data } = await cancelOrderMutation({
        variables: { orderId: props?.order?.id },
      });
      const order: IOrder = data?.cancelOrder || props?.order;
      props.onStatusChange(order?.status);
      return order;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onInitCancel = () => {
    setShowCancel(!showCancelModal);
  };

  const onInitSendSms = () => {
    setShowSendSms(!showSendSmsModal);
  };

  const onCancelConfirm = async (confirmed: boolean) => {
    setShowCancel(false);
    if (!confirmed) return;
    await cancelOrder();
    onCancel();
  };

  const onSendSmsConfirm = async (confirmed: boolean) => {
    setShowSendSms(false);
    if (!confirmed) return;
    await sendSigningSms();
  };

  const disableCancelButton = hasReservationBlockingCancellation(props?.order?.reserveDetails);

  const getAdditionalText = (filteredContracts: IBosApiAttachmentData[], index: number): string => {
    if (filteredContracts?.length > 1) {
      return `(versjon ${filteredContracts?.length - index})`;
    }
    return '';
  };

  const getContractsToBeDisplayed = (): JSX.Element | JSX.Element[] => {
    return isEditable(props?.order?.status)
      ? filteredContracts &&
          filteredContracts?.length > 0 &&
          filteredContracts?.map((contract, index) => {
            return (
              <div className="file-icon" key={`file_${contract?.id}`}>
                <ContractPDF
                  order={props?.order}
                  contract={contract}
                  additionalText={getAdditionalText(filteredContracts, index)}
                />
              </div>
            );
          })
      : (isShowServiceContractLink(props?.status, props?.order, signingJob) ||
          isShowSignedContractLink(props?.status, props?.order, signingJob)) && (
          <div className="file-icon">
            <ServiceContractPDF order={props?.order} signingJob={signingJob} />
          </div>
        );
  };

  return (
    <OrderButtonsStyled>
      <FileButtonContainer>
        {!editable &&
          (isShowServiceContractLink(props?.status, props?.order, signingJob) ||
            (contracts && contracts?.length > 0)) && (
            <FilesWrapper>
              {isShowServiceContractLink(props?.status, props?.order, signingJob) && (
                <div className="file-icon">
                  <ServiceContractPDF order={props?.order} signingJob={signingJob} />
                </div>
              )}
              {contracts && contracts?.length > 0 && (
                <div className="file-icon">
                  <ContractPDF order={props?.order} contract={contracts?.[0]} />
                </div>
              )}
              {isShowSignedContractLink(props?.status, props?.order, signingJob) && (
                <div className="file-icon">
                  <ServiceContractPDF order={props?.order} signingJob={signingJob} />
                </div>
              )}
            </FilesWrapper>
          )}
        {!props?.pageLoading && ((!isStockOrder(props?.order?.lead?.orderType) && editable) || isOffer(props?.status, true, true)) && (
          <FilesWrapper>
            {editable && getContractsToBeDisplayed()}
            {isOffer(props?.status, true, true) && (
              <div className="file-icon">
                <SalesOfferPdf
                  order={props?.order}
                  vehicleConfig={props?.vehicleConfig}
                  cartData={props?.cartData}
                  mappedEquipment={props?.mappedEquipment}
                  vatPrices={props.vatPrices}
                  configuration={props?.configuration}
                  className={'downloadOffers noWrapper'}
                  articles={props?.articles}
                  carType={props?.carType}
                />
              </div>
            )}
          </FilesWrapper>
        )}
      </FileButtonContainer>
      {props?.order && !checkExpireOrder(props?.status) && (
        <ActionButtonContainer className="flexWrapper">
          {!isStockOrder(props?.order?.lead?.orderType) && editable && !isOrderInvoiced(props.order) && (
            <div>
              <Button disabled={props.orderEditButtonDisabled} isLoading={reopenLoading} onClick={reopen}>
                {reopenLoading ? '' : 'Åpne og endre ordre'}
              </Button>
            </div>
          )}
          {isOffer(props?.status, true) && !editable && (
            <div>
              <Button
                disabled={props?.vehicleReserveStatus || props?.isSigned}
                isLoading={reopenLoading}
                onClick={reopen}>
                {reopenLoading ? '' : 'Gjenoppta'}
              </Button>
            </div>
          )}
          {isOffer(props?.status) && (
            <Tooltip
              text="For å slette tilbudet må du fjerne reservasjonen på bilen først."
              enabledCondition={disableCancelButton}>
              <Button
                className={'red'}
                variant={'secondary'}
                onClick={onInitCancel}
                disabled={disableCancelButton || props?.isSigned}>
                <span>Slett tilbud</span>
              </Button>
            </Tooltip>
          )}

          {isOffer(props?.status) && props?.order?.signingJobUrl && (
            <div>
              <Button
                variant={'secondary'}
                isLoading={sendSmsLoading}
                disabled={!!sendSmsResult}
                onClick={onInitSendSms}>
                {sendSmsLoading ? '' : 'Send signerings-SMS'}
              </Button>
              <div>{sendSmsResult}</div>
            </div>
          )}
        </ActionButtonContainer>
      )}
      <SendSmsModal show={showSendSmsModal} order={props?.order} onConfirm={onSendSmsConfirm} />
      <CancelOrderModal show={showCancelModal} isOrder={isOrder(props?.status)} onConfirm={onCancelConfirm} />
    </OrderButtonsStyled>
  );
};
