import styled, { css } from 'styled-components';
import { bosColor, typography } from '@next-components/common-styles';

export const StyledInlineDialog = styled.div<any>`
  &.loan-form-container {
    .formRowContainer {
      display: grid;
      grid-template-columns: auto;
    }
  }

  .title {
    margin-bottom: 0.5rem;
    ${(props) =>
      props.withBorder &&
      css`
        margin: 2rem 0.625rem;
      `}
  }

  ${(props) =>
    props.withBorder &&
    css`
      border: 1px solid ${bosColor.lightGrey};
      padding: 1rem;
      border-radius: 0 0 0.5rem 0.5rem;
      background-color: ${bosColor.lightGrey};
    `}
`;
export const LeasingLoanMainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const LeasingLoanAndServiceFormContainer = styled.div`
  border-radius: 0.5rem;
  background: ${bosColor.white};
  padding: 1.5rem;
  border: 1px solid ${bosColor.neutralGrey};
  gap: 1.5rem;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  label {
    font-size: 1.125rem;
    font-weight: ${typography.weight.extrabold};
    line-height: 175%;
  }
  button {
    text-align: center;
    min-width: 17.5rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    span {
      font-weight: ${typography.weight.extrabold};
      line-height: 150%;
    }
  }
`;

export const RightFormContainer = styled.div`
  border-radius: 0.5rem;
  background: ${bosColor.white};
  padding: 1.5rem;
  border: 1px solid ${bosColor.neutralGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &.cash {
    min-height: 15rem;

    .serviceavtalerModalLink {
      bottom: 3rem;
    }
  }

  .serviceavtalerModalLink {
    position: absolute;
    bottom: 6rem;
    a {
      cursor: pointer;
      color: ${bosColor.black};
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: ${typography.weight.semibold};
      line-height: 140%;
    }
  }

  .additional-button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    svg {
      padding-block: 3px;
    }
    .buttonLabel {
      font-size: 1.125rem;
      font-weight: ${typography.weight.extrabold};
      line-height: 175%;

      &.disabled {
        color: ${bosColor.neutralPlus};
      }
    }
  }
`;

export const ServiceFormContainer = styled.div`
  border-radius: 0.5rem;
  background: ${bosColor.white};
  padding: 1.5rem;
  border: 1px solid ${bosColor.neutralGrey};

  &.cashType {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid ${bosColor.neutralGrey};

    .title {
      margin: 0;
      font-size: 1.125rem;
      line-height: 175%;
      font-weight: ${typography.weight.extrabold};
    }

    .financeListItemContainer {
      .financeListItem {
        display: flex;
        gap: 1.5rem;
        width: 100%;

        span:not(.sigingPrice) {
          display: flex;
          flex: 1 0 0;
          font-size: 1rem;
          font-weight: ${typography.weight.semibold};
          line-height: 175%;
        }

        span.sigingPrice {
          font-size: 1.5rem;
          line-height: 165%;
          font-weight: ${typography.weight.extrabold};
        }
      }
    }
  }
`;
