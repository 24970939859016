export enum Events {
  DISABLE_RESERVE = 'disable_reserve',
  UPDATE_VEHICLE_LIST = 'update_vehicle_list',
  DISABLE_SKU = 'disable_sku',
  ENABLE_SKU = 'enable_sku',
  UNSELECT_SKU = 'unselect_sku',
  ONLOAD_DISABLE_SKU = 'onload_disable_sku',
  SKU_RULES_PREPARED = 'sku_rules_prepared',
  DISABLE_RESERVE_BTN = 'disable_reserve_btn',
  PRICE_UPDATE = 'price_update',
}

export const eventEmitter = {
  _events: {},
  latestData: {},
  dispatch(event: Events, data: any) {
    this.latestData[event] = data
    if (!this._events[event]) return;
    this._events?.[event]?.forEach((callback) => callback(data));
  },
  subscribe(event: Events, callback: (data: any) => any) {
    if (!this._events[event]) this._events[event] = [];
    this._events?.[event]?.push(callback);
    if(event === Events.DISABLE_RESERVE && this.latestData?.hasOwnProperty(event)) {
      callback(this.latestData?.[event])
    }
  },
  unsubscribe(event: Events) {
    if (!this._events[event]) return;
    delete this._events[event];
  },
};
