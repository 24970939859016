import { InputField } from '@next-components/input-field';
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { CustomerType, IBosCustomer, validate, VALIDATION_TYPE } from 'next-common';
import {
  fieldKeys,
  getFieldValidationType,
  getInputType,
  getUniqueList,
  ICustomerForm,
  isAllFieldsValid,
} from './CustomerForm-utils';
import { CustomerFormStyled } from '../CustomerPage.styled';

const DEBOUNCE_DELAY = 500;
const formFields = new Map();

export const CustomerForm = (props: ICustomerForm) => {
  const [validFields, setValidFields] = useState<string[]>([]);
  const [modifiedFields, setModifiedFields] = useState<string[]>([]);
  const [initialState, setInitialState] = useState(false);

  const setEmptyCustomerFields = () => {
    fieldKeys(props.customerType)
      .filter((key) => !props.customer[key])
      .forEach((key) => setValidFields(validFields.filter((fieldKey) => fieldKey !== key)));
  };

  const setValidCustomerFields = () => {
    if (!props.customer) {
      return;
    }
    const fields = [];
    fieldKeys(props.customerType)
      .filter((key) => !!props.customer[key])
      .forEach((key) => {
        const fieldValidationType = getFieldValidationType(props.customerType, key);
        if (validate({ value: props.customer[key], type: fieldValidationType })) {
          fields.push(key);
        }
      });
    setValidFields(Array.from(new Set(fields)));
  };

  useEffect(() => {
    if (props.customerType === CustomerType.PRIVATE) {
      formFields.delete('organizationName');
      formFields.delete('organizationNo');
    }
    if (props.customerType === CustomerType.BUSINESS) {
      formFields.delete('firstName');
      formFields.delete('lastName');
    }
  }, [props.customerType, props.customer]);

  useEffect(() => {
    if (props.customer) {
      setEmptyCustomerFields();
      setValidCustomerFields();
    }
    props.updateValidation(isAllFieldsValid(props.customerType, validFields));
  }, []);

  useEffect(() => {
    if (!props.customer) {
      return;
    }
    if (isAllFieldsValid(props.customerType, validFields)) {
      props.updatedFormData(getModifiedFields());
    }
    props.updateValidation(isAllFieldsValid(props.customerType, validFields));
  }, [validFields, modifiedFields]);

  useEffect(() => {
    setInitialState(true);
    setValidCustomerFields();
    if (!props.customer) setModifiedFields([]);
    setTimeout(() => {
      setInitialState(false);
      if (props?.customer?.type) setModifiedFields([]);
    }, DEBOUNCE_DELAY + 10);
  }, [props.customer]);

  const getModifiedFields = (): Partial<IBosCustomer> => {
    const modified: string[] = fieldKeys(props.customerType).filter(
      (key) => validFields.includes(key) && modifiedFields.includes(key),
    );
    return modified.reduce((changes, key) => {
      return {
        ...changes,
        [key]: formFields.get(key),
      };
    }, {});
  };

  const isValidated = (key: string): boolean => {
    return validFields.includes(key) && modifiedFields.includes(key);
  };

  const isInputError = (key: string): string => {
    return validFields.includes(key) ? null : 'Ugyldig verdi';
  };

  const onChange = (value, type, key) => {
    formFields.set(key, value);
    if (!initialState) {
      setModifiedFields(getUniqueList(modifiedFields, key));
    }
    const validChange = validate({ value, type });
    const fieldIndex = validFields.indexOf(key);
    // Always remove the field from the validFields array
    if (fieldIndex > -1) {
      const newValidFields = [...validFields];
      newValidFields.splice(fieldIndex, 1);
      setValidFields(newValidFields);
    }
    // Add the field to the validFields array if the change is valid
    if (validChange) {
      setValidFields(getUniqueList(validFields, key));
    }
    props.updateValidation(isAllFieldsValid(props.customerType, validFields));
  };

  const getField = (
    key: string,
    label: string,
    isDisabled: boolean = false,
    validationType: VALIDATION_TYPE = VALIDATION_TYPE.STRING,
    placeholder: string = null,
    initialValue = null,
  ) => {
    if (!initialValue) {
      initialValue = !!props.customer && props.customer?.hasOwnProperty(key) ? props.customer[key] : null;
    }
    return (
      <InputField
        onChange={debounce((e) => onChange(e, validationType, key), DEBOUNCE_DELAY)}
        placeholder={placeholder || label}
        label={label}
        withErrorMessage
        type={getInputType(validationType)}
        defaultValue={initialValue}
        isValid={isValidated(key)}
        errorMessage={isInputError(key)}
        startAdornment={validationType === VALIDATION_TYPE.PHONENUMBER ? '+47' : null}
        disabled={isDisabled}
        className="borderLight"
      />
    );
  };

  return (
    <CustomerFormStyled>
      <div className="inputGroup">
        {props.customerType === CustomerType.BUSINESS
          ? getField('organizationName', 'Firmanavn', true)
          : getField('firstName', 'Fornavn')}
        {props.customerType === CustomerType.BUSINESS
          ? getField('organizationNo', 'Org.nr', true, VALIDATION_TYPE.ORG_NR, null, props.customer?.organizationNo)
          : getField('lastName', 'Etternavn')}
      </div>

      <div className="inputGroup">
        {getField('phoneMobile', 'Telefonnummer', false, VALIDATION_TYPE.PHONENUMBER, props.customer?.phoneMobile)}
        {getField('email', 'E-post', false, VALIDATION_TYPE.EMAIL)}
      </div>

      <div className="inputGroup threeGrid">
        {getField('zipCode', 'Postnr.', false, VALIDATION_TYPE.ZIPCODE, '0123')}
        {getField('city', 'Poststed')}
        {getField('countryCode', 'Land')}
      </div>
    </CustomerFormStyled>
  );
};
