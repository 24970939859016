import { IEquipmentTypes, VehicleEquipmentCategoryEnum } from 'next-common';
import React, { useEffect, useState } from 'react';
import { useDisableSkus, useEnableSkus, useSkusPriceUpdate, useUnselectSkus } from '../../../../../common';
import { calculateRetailPrice, createSkuListFromValue, eventEmitter, Events, prepareImageObject, priceFormatter } from '../../../../../utils';
import { IModelPackages } from '../../VehiclePickerPage';
import { IListverkChangeProps } from '../ResultGrid';
import { SwatchGrid } from '../ResultGrid.styled';
import filter from 'lodash/filter';
import { get } from 'lodash';
import { VehicleSelectableDescription } from './VehicleSelectableDescription';
import { Card } from '@next-components/card';
import { CardType } from '@next-components/common-types';
import { MessagebarSeverityLevel } from '@next-components/messagebar';

export interface IVehicleTaklakk {
  data: IModelPackages;
  disabled: boolean;
  selectedTaklakk: IEquipmentTypes;
  onTaklakkChange: (data: IListverkChangeProps) => void;
  updatePrices: () => void;
}

export const VehicleTaklakk = (props: IVehicleTaklakk) => {
  const { data, disabled, selectedTaklakk, onTaklakkChange } = props;
  const { isDisableSKUonChange, isDisableSKUonLoad, disableSku, onloadDisableSku } = useDisableSkus();
  const { isEnableSKUonChange } = useEnableSkus();
  const { getUnselectSkuFlag } = useUnselectSkus();
  const priceUpdate = useSkusPriceUpdate();
  const [isPrepared, setIsPrepared] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [activeDesc, setActiveDesc] = useState(null);

  useEffect(() => {
    eventEmitter.subscribe(Events.SKU_RULES_PREPARED, setIsPrepared);
    return () => {
      eventEmitter.unsubscribe(Events.SKU_RULES_PREPARED);
    };
  }, []);

  const onChangeTaklakk = (equipment: IEquipmentTypes) =>
    onTaklakkChange({
      equipment: selectedTaklakk?.id === equipment?.id ? null : equipment,
      price: getPrice(equipment),
      vatCode: equipment?.vatCode,
      isOptimisticUpdate: false,
      configType: VehicleEquipmentCategoryEnum.Taklakk,
      dealerPriceExclVat: calculateRetailPrice(getPrice(equipment), equipment?.marginPercentage),
    });

  const isDisabled = (sku: string) => {
    const isDisable = isDisableSKUonChange(sku) ? true : isEnableSKUonChange(sku) ? false : isDisableSKUonLoad(sku);
    return isDisable;
  };

  const getPrice = (taklakk: IEquipmentTypes) => {
    return priceUpdate?.has(taklakk?.sku) ? Number(priceUpdate?.get(taklakk?.sku)) : taklakk?.priceExcludingVat;
  };

  useEffect(() => {
    //Update prices in cart && VAT Calculation
    if (selectedTaklakk?.id) {
      props.updatePrices();
    }
  }, [selectedTaklakk, priceUpdate]);

  const isDisableRuleApply = (equipment: IEquipmentTypes) => {
    const isDisable = isDisabled(equipment?.sku);
    const isUnselectedSku = getUnselectSkuFlag(equipment?.sku);
    if ((isDisable || isUnselectedSku) && isPrepared && selectedTaklakk?.id === equipment?.id) {
      onTaklakkChange({
        equipment: null,
        price: getPrice(equipment),
        vatCode: equipment?.vatCode,
        isOptimisticUpdate: true,
        configType: VehicleEquipmentCategoryEnum.Taklakk,
        dealerPriceExclVat: calculateRetailPrice(getPrice(equipment), equipment?.marginPercentage),
      });
    }
    return isDisable;
  };
  const equipmentFilter: IEquipmentTypes[] = filter(data?.selectableEquipment ?? [], [
    'category',
    VehicleEquipmentCategoryEnum.Taklakk,
  ]);
  useEffect(() => {
    const isDisable = isDisabled(selectedTaklakk?.sku);
    if (!isDisable && isPrepared && selectedTaklakk?.id) {
      if (selectedTaklakk) {
        onTaklakkChange({
          equipment: selectedTaklakk,
          price: getPrice(selectedTaklakk),
          vatCode: selectedTaklakk?.vatCode,
          isOptimisticUpdate: true,
          configType: VehicleEquipmentCategoryEnum.Taklakk,
          dealerPriceExclVat: calculateRetailPrice(getPrice(selectedTaklakk), selectedTaklakk?.marginPercentage),
        });
      }
    }
  }, [selectedTaklakk, isDisabled(selectedTaklakk?.sku)]);

  const isDataAvailable = (equipment: IEquipmentTypes) => {
    return get(equipment, 'description');
  };

  const setCurrentData = (equipment: IEquipmentTypes) => {
    setActiveDesc(equipment);
    setActive(!active);
  };

  const getSkuNote = (sku: string) => {
    return createSkuListFromValue(disableSku, onloadDisableSku, sku);
  }
  
  return (
    <>
      <span className="label">Taklakk</span>
      {equipmentFilter?.length === 0 ? (
        <p> Ingen Taklakk tilgjengelig </p>
      ) : (
        <SwatchGrid className="vehicleTaklakk">
          {equipmentFilter?.map((equipment) => {
            const isSelected = selectedTaklakk?.id === equipment?.id;
            const customPriceTag = `${getPrice(equipment) > 0 ? '+' : ''}  ${priceFormatter(
              getPrice(equipment),
              equipment,
            )}`;
            return (
              <Card
                key={equipment?.id}
                id={equipment?.id}
                title={equipment?.name}
                bosSwatch={prepareImageObject(equipment?.image?.[0])}
                customPriceTag={customPriceTag}
                type={CardType.BosCard}
                selected={Boolean(isSelected)}
                isDisabled={disabled || isDisableRuleApply(equipment)}
                alertMessage={{
                  message: isDisabled(equipment?.sku) ? getSkuNote(equipment?.sku) : '',
                  typeOfAlert: MessagebarSeverityLevel.WARNING
                }}
                displayTag
                updateSelected={() => onChangeTaklakk(equipment)}
                disableTag={!isDataAvailable(equipment)}
                tagValue={equipment?.sku}
                onTagClick={() => setCurrentData(equipment)}
              />
            );
          })}
          <VehicleSelectableDescription
            data={activeDesc}
            active={active}
            onClose={() => {
              setActive(!active);
            }}></VehicleSelectableDescription>
        </SwatchGrid>
      )}
    </>
  );
};
