import React from 'react';
import MyOverview from '../my-overview-page';
import { DashboardStyled } from './Dashboard.styled';
import { IRootUser } from '../../../common/interfaces/root-user';
import { IConfiguration } from 'next-common';

interface IDashboardProps extends IRootUser {
  configuration: IConfiguration
}

export const Dashboard = (props: IDashboardProps) => {
  return (
    <DashboardStyled>
      <MyOverview isAdmin={props.isAdmin} configuration={props.configuration} />
    </DashboardStyled>
  );
};
