import React from 'react';
import checkmark from './imgs/check.png';
import { View, Text, Image } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { IPackageEquipmentItems } from 'next-common';
import { HorizontalWarpableListStyled } from './common-styles';
import { v4 as uuidv4 } from 'uuid';

interface IEquipmentItem {
  noicon?: boolean;
  item?: string;
  isBreak?: boolean;
}

interface IEquipmentBundle {
  equipmentBundleList?: IPackageEquipmentItems[];
  name: string;
  breakPage: boolean;
}

interface IEquipmentBundleForStockSale {
  equipmentBundleList?: string[];
  breakPage: boolean;
}

const EquipmentItemStyled: Style = {
  width: '50%',
  flexDirection: 'row',
  marginVertical: 2,
  alignItems: 'center',
  padding: '2 5 2 2',
};

const RowCount = 48;

const EquipmentItem = (props: IEquipmentItem) => (
  <View style={EquipmentItemStyled} break={props?.isBreak}>
    {!props.noicon && <Image source={checkmark} style={{ height: 8, width: 8, marginRight: 8, padding: 1 }} />}
    <Text style={{ fontSize: 10, fontWeight: 600, paddingRight: 25 }}>{props.item}</Text>
  </View>
);

export const EquipmentBundleList = (props: IEquipmentBundle) => {
  let itemCount = 0;
  return (
    <View style={{ marginBottom: 10 }} break={props.breakPage}>
      <Text style={{ fontSize: 12, fontWeight: 800 }}>{props.name}</Text>
      <View style={HorizontalWarpableListStyled}>
        {props.equipmentBundleList?.map?.((item) => {
          if (itemCount === RowCount) {
            itemCount = 0;
          } else {
            itemCount++;
          }
          return (
            <>
              <EquipmentItem key={uuidv4()} item={item?.name} isBreak={itemCount === RowCount ? true : false} />
              {(!props.equipmentBundleList || props.equipmentBundleList?.length < 1) && (
                <EquipmentItem item="Tom liste" noicon={true} />
              )}
            </>
          );
        })}
        {(!props.equipmentBundleList || props.equipmentBundleList?.length < 1) && (
          <EquipmentItem item="Tom liste" noicon={true} />
        )}
      </View>
    </View>
  );
};

export const EquipmentBundleListForStockSale = (props: IEquipmentBundleForStockSale) => {
  let itemCount = 0;
  return (
    <View break={props.breakPage} wrap={true}>
      <Text style={{ fontSize: 12, fontWeight: 800 }}>{'Standardutstyr'}</Text>
      <View style={HorizontalWarpableListStyled}  wrap={true}>
        {props.equipmentBundleList?.map?.((item) => {
          if (itemCount === RowCount) {
            itemCount = 0;
          } else {
            itemCount++;
          }
          return (
            <>
              <EquipmentItem key={uuidv4()} item={item} isBreak={itemCount === RowCount ? true : false} />
            </>
          );
        })}
        {(!props.equipmentBundleList || props.equipmentBundleList?.length < 1) && (
          <EquipmentItem item="Tom liste" noicon={true} />
        )}
      </View>
    </View>
  );
};

