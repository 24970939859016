import {
  MAKE,
  VehicleEquipmentCategoryEnum
} from 'next-common';

export const priceOverrunLeasing = 2.5;

export const brandsToAllowOrderWithoutSerialNo = [MAKE.Kia, MAKE.Peugeot, MAKE.Mercedes, MAKE.Smart];

export const maxExpiryLength = 60;

export const defaultProductionDays = 30;

export const validBrandForSelectables = [MAKE.Mercedes, MAKE.Smart, MAKE.Peugeot, MAKE.Kia];
export const equipmentCategoryForKeyCreation = [VehicleEquipmentCategoryEnum.Listverk, VehicleEquipmentCategoryEnum.Taklakk];

export const DELIMETER = "^";