import styled from 'styled-components';

export const OrderAccordionWrapper = styled.div`
  width: 100%;

  .newNext,
  .newNext[open],
  .newNext[open]:hover {
    button.btnAccordion:not(.search-button) {
      border: none;
    }
  }
  .newNext[disabled] {
    cursor: not-allowed;
    button.btnAccordion:not(.search-button) {
      cursor: inherit;
    }
  }
`;
