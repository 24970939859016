import React from 'react';
import { OrderAccordion } from '../../../sales-common/OrderAccordion';
import { VehicleInterior, IVehicleInterior } from './VehicleInterior';
import { IEquipmentTypes, MAKE, VehicleEquipmentCategoryEnum } from 'next-common';
import { VehicleSelectableEquipment } from './VehicleSelectableEquipment';
import { VehicleListverk } from './VehicleListverk';
import { IListverkChangeProps } from '../ResultGrid';

export interface ISectionInterior extends IVehicleInterior {
  isOpen: boolean;
  isDisabled: boolean;
  customerType?: string;
  selectedEquipmentIds?: IEquipmentTypes[];
  onSelectEquipmentChange?: (data: IEquipmentTypes[], removeId?: string, isOptimisticUpdate?: boolean) => void;
  onListverkChange?: (data: IListverkChangeProps) => void;
  selectedListverk?: IEquipmentTypes;
  vehicleBrand?: MAKE;
}

export const SectionInterior = (props: ISectionInterior) => {
  const {
    data,
    selectedInteriorId,
    isOpen,
    isDisabled,
    onInteriorChange,
    disabled,
    customerType,
    onSelectEquipmentChange,
    selectedEquipmentIds,
    selectedListverk,
    onListverkChange,
    updatePrices,
    vehicleBrand,
  } = props;

  const commonProps = {
    data,
    disabled,
    updatePrices,
  };

  return (
    <OrderAccordion label="Interiør" isOpen={isOpen} isDisabled={isDisabled}>
      <VehicleInterior {...commonProps} selectedInteriorId={selectedInteriorId} onInteriorChange={onInteriorChange} />
      {vehicleBrand === MAKE.Mercedes && (
        <VehicleListverk {...commonProps} selectedListverk={selectedListverk} onListverkChange={onListverkChange} />
      )}
      <VehicleSelectableEquipment
        {...commonProps}
        selectedEquipmentIds={selectedEquipmentIds}
        onSelectEquipmentChange={onSelectEquipmentChange}
        customerType={customerType}
        equipmentCategory={VehicleEquipmentCategoryEnum.Interior}
      />
    </OrderAccordion>
  );
};
