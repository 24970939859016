import React, { useEffect, useState } from 'react';
import { ICartProps } from '@next-components/cart';
import classNames from 'classnames';
import { HashMap, IEquipmentTypes, MAKE, VehicleEquipmentCategoryEnum } from 'next-common';
import { CarConfigureContainerStyled, Results } from './ResultGrid.styled';
import { eventEmitter, Events } from '../../../../utils';
import {
  IPartialEnrichedEquipment,
  ISkuVehicleCount,
  VehicleConfig,
  isRulesPayloadCheckFulfilled,
  isValidBrandForSelectables,
  isValidPriceChange,
  prepareSuggestedSkuList,
  rulesArrPayloadToString,
  rulesPayloadMapper,
  rulesUnselectPayloadCreation,
} from '../../../../utils/sales';
import { IModelPackages, IVehicleModel } from '../../sales-vehicle-picker-page/VehiclePickerPage';
import { SectionExterior, SectionInterior, VehicleModel, VehicleVariant } from './OrderAccordian';
import { getCurrentModelData, getCurrentVariantData } from '../../../../utils/sales/vehicleCart';
import { usePrevious } from '../../../../common/hooks/usePrevious';
import {
  getUpdatedConjunctionPrices,
  IEquipmentIdAndSKU,
  getNewConjunctionValues,
  IConjunctionReturnValue,
} from 'sales-crystallize-common';
import { defaultTo, difference, get } from 'lodash';
import { PropertiesTableValue } from 'sales-common';
export interface ICartDataProps {
  id?: string;
  name: string;
  price: number;
  code?: string;
  isRecommended?: boolean;
  sku?: string;
  modelCode?: string;
  vatCode?: string;
  isOptimisticUpdate?: boolean;
  configType?: VehicleConfig;
  dealerPriceExclVat: number;
  path?: string
}
export interface IRimChangeProps {
  rim: IEquipmentTypes;
  price: number;
  vatCode: string;
  isOptimisticUpdate?: boolean;
  configType?: VehicleConfig;
  dealerPriceExclVat: number;
}
export interface IResultGridProps {
  selectedModelId: string;
  selectedVariantId: string;
  selectedColorId: string;
  selectedInteriorId: string;
  selectedModelCode:string;
  selectedModelName: string
  selectedVariantName:string;
  vehicleData: IVehicleModel[];
  vehicleModelData: IVehicleModel[];
  customerType?: string;
  cartData: ICartProps;
  disabled: boolean;
  selectedEquipmentIds: IEquipmentTypes[];
  selectedRims: IEquipmentTypes;
  allSkuVehicleCount?: ISkuVehicleCount;
  isChange: boolean;
  setCartData?(data: ICartProps): void;
  onModelChange?(data: ICartDataProps): void;
  onVariantChange?(data: ICartDataProps): void;
  onColorChange?(data: ICartDataProps): void;
  onRimChange?(data: IRimChangeProps): void;
  onInteriorChange?(data: ICartDataProps): void;
  onSelectEquipmentChange?(data: IEquipmentTypes[], removeId?: string, isOptimisticUpdate?: boolean): void;
  selectablesRulesSkuPayload?: string[];
  selectedListverk?: IEquipmentTypes;
  onListverkChange?(data: IListverkChangeProps): void;
  updatePrices(): void;
  vehicleBrand?: MAKE;
  selectedTaklakk?: IEquipmentTypes;
  onTaklakkChange?(data: IListverkChangeProps): void;
  modelOptions?: HashMap
}
export interface IListverkChangeProps {
  equipment: IEquipmentTypes;
  price: number;
  vatCode: string;
  isOptimisticUpdate?: boolean;
  configType?: VehicleConfig | VehicleEquipmentCategoryEnum;
  dealerPriceExclVat: number;
}

export const ResultGrid = (props: IResultGridProps) => {
  const [selectedModelData, setSelectedModelData] = useState<IVehicleModel[]>(null);
  const [selectedVariantData, setSelectedVariantData] = useState<IModelPackages[]>(null);
  const [mappedEquipment, setMappedEquipment] = useState<IEquipmentIdAndSKU[]>([]);
  const [enrichedEquipment, setEnrichedEquipment] = useState<IPartialEnrichedEquipment[]>([]);
  const prevSelectableRulesSkuPayload = usePrevious(props?.selectablesRulesSkuPayload);

  // Model data selector
  useEffect(() => {
    if (props.selectedModelId && props.vehicleModelData) {
      const currentModelData: IVehicleModel[] = getCurrentModelData(props.selectedModelId, props.vehicleModelData);
      setSelectedModelData(currentModelData);
    }
  }, [props.selectedModelId, props.vehicleModelData]);

  // All the skus processing for rules and passing to different events
  useEffect(() => {
    if (isRulesPayloadCheckFulfilled(selectedVariantData, mappedEquipment, props?.cartData?.make)) {
      const disableSkusList = new Map();
      const enableSkusList = new Map();
      const unselectSkusList = new Map();
      const skuPriceList = new Map();
      if (props?.selectablesRulesSkuPayload?.length > 0) {
        eventEmitter.dispatch(Events.SKU_RULES_PREPARED, false);
        const rulesPayload: string = rulesArrPayloadToString(props?.selectablesRulesSkuPayload);
        let disableSku: IConjunctionReturnValue[] = [];
        let enableSku: IConjunctionReturnValue[] = [];
        let unselectSku: string = null;
        let skuPrice: ((PropertiesTableValue & { sku: string }) | any)[] = [];
        const unSelectedSkus = difference(
          defaultTo(prevSelectableRulesSkuPayload, []),
          defaultTo(props?.selectablesRulesSkuPayload, []),
        );
        try {
          const { disable: disableEquipmentSkuList, enable: enableEquipmentSkuList } = getNewConjunctionValues(
            rulesPayload,
            // @ts-ignore (pim-sku change would fix the type error, future task)
            enrichedEquipment,
          );

          disableSku = defaultTo(disableEquipmentSkuList, []);
          enableSku = defaultTo(enableEquipmentSkuList, []);
          
          skuPrice = getUpdatedConjunctionPrices(rulesPayload, mappedEquipment);

          // At a time only one unselect sku would be added, which would recursivly call this useEffect untill all
          // of them are not resolved.
          unselectSku = rulesUnselectPayloadCreation(
            unSelectedSkus,
            props?.selectablesRulesSkuPayload,
            enrichedEquipment,
          );
        } catch (error) {
          console.warn(`Fetch selectable rule error - ${error}`);
        }

        disableSku?.map((conjunctionValue, index) =>
          disableSkusList.set(get(conjunctionValue, 'equipmentSku', ''), prepareSuggestedSkuList(conjunctionValue)),
        );
        enableSku?.map((conjunctionValue, index) =>
          enableSkusList.set(get(conjunctionValue, 'equipmentSku', ''), index),
        );

        if (unselectSku) {
          unselectSkusList.set(unselectSku, 0);
        }
        skuPrice?.forEach((priceUpdate) => {
          if (isValidPriceChange(priceUpdate)) {
            skuPriceList.set(priceUpdate?.sku, priceUpdate?.value);
          }
        });
        eventEmitter.dispatch(Events.SKU_RULES_PREPARED, true);
      }
      eventEmitter.dispatch(Events.DISABLE_SKU, disableSkusList);
      eventEmitter.dispatch(Events.ENABLE_SKU, enableSkusList);
      eventEmitter.dispatch(Events.UNSELECT_SKU, unselectSkusList);
      eventEmitter.dispatch(Events.PRICE_UPDATE, skuPriceList);
    }
  }, [selectedVariantData, props?.selectablesRulesSkuPayload]);

  // Variant data selector along with on-load disabled rules
  useEffect(() => {
    if (props.selectedVariantId && props.vehicleData) {
      const currentVariantData: IModelPackages[] = getCurrentVariantData(
        props.selectedVariantId,
        props.vehicleData?.[0],
      );
      const equipmentsData = currentVariantData?.[0]?.mappedEquipment;
      const conjunctionEquipmentData = rulesPayloadMapper(equipmentsData);
      loadDisableSkus(currentVariantData, conjunctionEquipmentData);
      setEnrichedEquipment(conjunctionEquipmentData);
      setMappedEquipment(equipmentsData);
      setSelectedVariantData(currentVariantData);
      props.setCartData({ ...props.cartData, image: currentVariantData?.[0]?.image });
    }
  }, [props.selectedVariantId, props.vehicleData]);

  const loadDisableSkus = (data: IModelPackages[], conjunctionEquipmentData: IPartialEnrichedEquipment[]) => {
    const disableSkusList = new Map();
    if (data && isValidBrandForSelectables(props?.cartData?.make ?? '')) {
      // @ts-ignore (pim-sku change would fix the type error, future task)
      const { disable } = getNewConjunctionValues('', conjunctionEquipmentData ?? []);
      const disableSku: IConjunctionReturnValue[] = disable || [];
      disableSku?.map((conjunctionValue, index) => disableSkusList.set(get(conjunctionValue, 'equipmentSku', ''), prepareSuggestedSkuList(conjunctionValue)));  
    }

    eventEmitter.dispatch(Events.ONLOAD_DISABLE_SKU, disableSkusList);
  };

  const onVariantChange = (data: ICartDataProps) => {
    props.onVariantChange(data);
  };

  return (
    <Results className={classNames({ results: true, leftContainer: true })}>
        <CarConfigureContainerStyled>
          <VehicleModel
            data={props.vehicleModelData}
            selectedModelId={props.selectedModelId}
            selectedModelCode = {props.selectedModelCode}
            selectedModelName = {props.selectedModelName}
            disabled={props.disabled}
            onModelChange={props.onModelChange}
            vehicleBrand={props.vehicleBrand}
          />
          <VehicleVariant
            data={selectedModelData?.[0]}
            selectedModelId={props.selectedModelId}
            selectedVariantId={props.selectedVariantId}
            disabled={props.disabled}
            allSkuVehicleCount={props.allSkuVehicleCount}
            onVariantChange={onVariantChange}
            vehicleBrand={props.vehicleBrand}
            modelOptions={props.modelOptions}
            selectedVariantName = {props.selectedVariantName}
          />
          <SectionExterior
            data={selectedVariantData?.[0]}
            isOpen={!!props.selectedModelId && !!props.selectedVariantId}
            isDisabled={!props.selectedModelId || !props.selectedVariantId}
            selectedColorId={props.selectedColorId}
            disabled={props.disabled}
            selectedRims={props.selectedRims}
            customerType={props.customerType}
            onRimChange={props.onRimChange}
            onColorChange={props.onColorChange}
            selectedEquipmentIds={props.selectedEquipmentIds}
            onSelectEquipmentChange={props.onSelectEquipmentChange}
            updatePrices={props.updatePrices}
            vehicleBrand={props.vehicleBrand}
            onTaklakkChange={props.onTaklakkChange}
            selectedTaklakk={props.selectedTaklakk}
          />

          <SectionInterior
            data={selectedVariantData?.[0]}
            isOpen={!!props.selectedModelId && !!props.selectedVariantId}
            isDisabled={!props.selectedModelId || !props.selectedVariantId}
            selectedInteriorId={props.selectedInteriorId}
            disabled={props.disabled}
            onInteriorChange={props.onInteriorChange}
            selectedEquipmentIds={props.selectedEquipmentIds}
            onSelectEquipmentChange={props.onSelectEquipmentChange}
            customerType={props.customerType}
            onListverkChange={props.onListverkChange}
            selectedListverk={props.selectedListverk}
            updatePrices={props.updatePrices}
            vehicleBrand={props.vehicleBrand}
          />
        </CarConfigureContainerStyled>
    </Results>
  );
};
