import * as React from 'react';
import { isEmpty } from 'lodash';
import { DynamicListStyled } from '../DynamicList.styled';
import { IDynamicDetailItemProps } from '../IDynamicList';
import {
  ITilleggSelectable,
  IVehicleListItems,
} from '../../../sales/sales-vehicle-picker-page/VehicleListDialog/VehicleListDialog';
import { displayDataForVehicleDetails } from '../../../sales/sales-vehicle-picker-page/VehicleListDialog/VehicleListFormatter';
import { Button } from '@next-components/cta';
import { VehicleDetailStyled } from './VehicleDetail.styled';
import { VehicleListDetailMerStyled } from './VehicleListMer.styled';
import { MAKE } from 'next-common';

// VehicleDetail expanded field content type
interface IVehicleDetailListItems extends IVehicleListItems {
  interiorName?: string;
  modelDesc?: string;
  totalPrice?: number;
  locationName?: string;
  brand?: string;
}

export const VehicleDetail = (props: IDynamicDetailItemProps) => {
  const vehicleDetailsData: IVehicleDetailListItems = props?.data;
  const [loading, setLoading] = React.useState<boolean>(props?.isLoading ?? false)

  React.useEffect(() => {
    setLoading(props?.isLoading)
  }, [props?.isLoading])

  /**
   * Take argument of skuItemlist and return the html
   * @param skuItemList Array
   * @returns ReactNode (Html Element)
   */
  const renderSkuField = (skuItemList: ITilleggSelectable[], selectableType?: string): React.ReactNode => {
    if (skuItemList?.length === 0) {
      return <span>-</span>;
    } else {
      return skuItemList?.map((item: ITilleggSelectable) => {
        return (
          <div className="gridListItem">
            <div className="itemValue">
              {displayDataForVehicleDetails({
                sku: item?.sku,
                name: item?.name,
                isSelected: setIsSelectedValue(item?.isSelected, item?.sku, selectableType),
                isUnavailable: item?.isUnavailable
              })}
            </div>
          </div>
        );
      });
    }
  };

  /**
   * return true/false value based on the isSelected params or selectableType with combination selectables
   * @param isSelected | boolean
   * @param sku | string
   * @param selectableType | string
   *
   * @returns boolean
   */
  const setIsSelectedValue = (isSelected: boolean, sku: string, selectableType: string): boolean => {
    if (!isEmpty(selectableType) && !isEmpty(vehicleDetailsData?.selectedRims) && selectableType === 'rims') {
      return vehicleDetailsData?.selectedRims.sku === sku;
    } else if (isSelected) {
      return isSelected;
    }

    return false;
  };

  const onSubmitClick = () => {
    if (!props?.isLoading) {
      props.onButtonClickDetailPage(props?.data)
    }
  }

  return (
    <VehicleDetailStyled>
      <DynamicListStyled className="vehicleDetailContainer">
        <VehicleListDetailMerStyled>
          <div className="gridListItemContainer">
            <div className="gridListItem">
              <h3>{vehicleDetailsData?.modelDesc || ''}</h3>
              <div className="imageContainer">
                <img src={vehicleDetailsData?.image?.url} alt="no-vehicle" width="240px" height="147px" />
              </div>
            </div>
            <div className="gridListItem">
              <label className="labelTitle">Chassisnummer</label>
              <div className="itemValue">{vehicleDetailsData?.vinNumber || '-'}</div>
            </div>
            <div className="gridListItem">
              <label className="labelTitle">Serienummer</label>
              <div className="itemValue"> {vehicleDetailsData?.serialNumber || '-'}</div>
            </div>
            <div className="gridListItem">
              <label className="labelTitle">Prod.dato</label>
              <div className="itemValue">{vehicleDetailsData?.prod || '-'}</div>
            </div>
          </div>
          <div className="gridListItemContainer utstyrspakkeContainer">
            <div className="gridListItem ">
              <label className="labelTitle">Utstyrspakke</label>
              <div className="itemValue">{renderSkuField(vehicleDetailsData?.salesPackageFormatter)}</div>
            </div>

            <div className="gridListItem ">
              <label className="labelTitle">Farge</label>
              <div className="itemValue">{renderSkuField(vehicleDetailsData?.fargeFormatter)}</div>
            </div>
            <div className="gridListItem ">
              <label className="labelTitle">Felger</label>
              <div className="itemValue">{renderSkuField(vehicleDetailsData?.felgerFormatter)}</div>
            </div>

            <div className="gridListItem">
              <label className="labelTitle">Interiør</label>
              <div className="itemValue">{renderSkuField(vehicleDetailsData?.interiorFormatter)}</div>
            </div>
            {vehicleDetailsData?.brand === MAKE.Smart ? (
              <div className="gridListItem">
                <label className="labelTitle">Taklakk</label>
                <div className="itemValue">{renderSkuField(vehicleDetailsData?.taklakkSelectable)}</div>
              </div>
            ) : (
              <div className="gridListItem">
                <label className="labelTitle">Listverk</label>
                <div className="itemValue">{renderSkuField(vehicleDetailsData?.listverkSelectableFormatter)}</div>
              </div>
            )}
          </div>
          <div className="gridListItemContainer utstyrspakkeContainer">
            <div className="gridListItem ">
              <label className="labelTitle">Utvendig ekstrautstyr</label>
            </div>
            {renderSkuField(vehicleDetailsData?.exteriorSelectableFormatter)}
            {vehicleDetailsData?.unAvailableSkus?.map(
              (sku) =>
                sku !== vehicleDetailsData?.interiorFormatter?.[0]?.sku &&
                sku !== vehicleDetailsData?.fargeFormatter?.[0]?.sku &&
                renderSkuField([{ isUnavailable: true, sku }]),
            )}
          </div>
          <div className="gridListItemContainer utstyrspakkeContainer">
            <div className="gridListItem ">
              <label className="labelTitle">Innvendig ekstrautstyr</label>
            </div>
            {renderSkuField(vehicleDetailsData?.interiorSelectableFormatter)}
          </div>
          <div className="gridListItemContainer utstyrspakkeContainer">
            <div className="gridListItem ">
              <label className="labelTitle">Status</label>
            </div>
            <div className="gridListItem">
              <div className="itemValue">
                {displayDataForVehicleDetails({
                  sku: vehicleDetailsData?.tt.toString(),
                  name: vehicleDetailsData?.statusName,
                  isSelected: true,
                })}
              </div>
            </div>
            <div className="gridListItem">
              <div className="itemValue">
                {displayDataForVehicleDetails({
                  sku: vehicleDetailsData?.locationId,
                  name: vehicleDetailsData?.locationName,
                  isSelected: true,
                })}
              </div>
            </div>
            {/* <div className="gridListItem price Container">
              <p>Veil pris inkl. mva og avgifter</p>
              <h3 className="priceValue"> {vehicleDetailsData?.totalPrice} kr</h3>
            </div> */}

            <div className="gridListItem">
              <Button
                className="vehicleDetailBtn"
                isLoading={loading}
                disabled={props?.data?.disabled}
                onClick={() => onSubmitClick()}>
                Velg denne bilen
              </Button>
            </div>
          </div>
        </VehicleListDetailMerStyled>
      </DynamicListStyled>
    </VehicleDetailStyled>
  );
};
