import { makeVar } from '@apollo/client';

export const ERROR_POLICY = 'all';
export const selectedDealerId = makeVar<string>('');
export const selectedGroup = makeVar<string>(null);
export const statusChecked = makeVar({
  isNoVehicle: false,
  isNoPimData: false,
  isNoFactoryCode: false,
});
export const currentVehicle = makeVar<{ key: string; modelId: string }>({ key: '', modelId: '' });
